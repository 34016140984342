import axios from "axios";
import * as cryptojs from "crypto-js";
import * as configService from "@/services/configService";
import * as magolinkData from "@/models/magolinkData.interface";
import { MagoLinkData } from "@/models/magolinkData.interface";
import { getMagoLinkAgent } from "@/services/browserVersionService";
import loggerService from "@/services/loggerService";
import { Room } from "@/models/room.interface";
import {
  HubConnectionBuilder,
  HttpTransportType,
  HubConnectionState,
  LogLevel,
  HubConnection
} from "@microsoft/signalr";
import logger from "@/services/loggerService";
import {
  MagoLinkMessage,
  deserialize,
  serialize,
  buildRequestMsg
} from "@/models/magoLinkMessage.interface";
import store from "@/store";
import { v4 as uuidv4 } from "uuid";

const deviceId = uuidv4();
let connection: HubConnection | undefined;

const encodeObject = (data: Record<string, unknown>) => {
  const stringifyHeader = cryptojs.enc.Utf8.parse(JSON.stringify(data));
  return cryptojs.enc.Base64url.stringify(stringifyHeader);
};

const geneateJwt = () => {
  const header = {
    alg: "HS256",
    typ: "JWT"
  };
  const payload = {
    sub: deviceId,
    jti: uuidv4(),
    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role":
      configService.magolinkRole,
    exp: Math.round(new Date().getTime() / 1000) + 60 * 10,
    iss: "AuthIssuer",
    aud: "AuthAudience"
  };
  const encodedHeader = encodeObject(header);
  const encodedPayload = encodeObject(payload);

  const signature = cryptojs.HmacSHA256(
    `${encodedHeader}.${encodedPayload}`,
    configService.magolinkSecret
  );
  const encodedSignature = cryptojs.enc.Base64url.stringify(signature);
  return `${encodedHeader}.${encodedPayload}.${encodedSignature}`;
};

async function connect(code: string): Promise<MagoLinkData> {
  const data = {
    deviceId: deviceId,
    nickName: getMagoLinkAgent(),
    roomPassCode: code.replace(/-/g, "").toUpperCase(),
    bleServiceId: configService.magolinkBleServiceId,
    appPlatform: "web",
    appVersion: process.env.VUE_APP_VERSION
  };
  const requestOptions = {
    headers: { authorization: `Bearer ${geneateJwt()}` }
  };
  const resp = await axios.post(
    configService.magolinkPasscodeUrl,
    data,
    requestOptions
  );
  return magolinkData.deserialize(resp.data);
}

export async function transferRoom(id: string, room: Room): Promise<void> {
  store.commit("room/setTransfering", true);
  try {
    const authData = await connect(id);
    await connectToSocket(authData.token);
    return await sendDeferredMessage(
      buildRequestMsg("ConnectToPlannedMeeting", {
        ServerUrl: configService.rmsBaseUrl(),
        ShortId: room.roomInfo?.shortId,
        ModeratorPin: room.roomInfo?.moderatorPin,
        ClientPin: room.roomInfo?.moderatorPin
      })
    );
  } catch (e) {
    store.commit("room/setTransfering", false);
    loggerService.error(e);
  }
}

const connectToSocket = async (token: string): Promise<void> => {
  const signalrConnection = new HubConnectionBuilder()
    .withUrl(configService.magolinkSignalrUrl, {
      transport: HttpTransportType.WebSockets,
      accessTokenFactory: () => {
        return token;
      }
    })
    .configureLogging(LogLevel.Information)
    .build();

  signalrConnection
    .start()
    .then(async () => {
      signalrConnection?.onreconnected(connectionId => {
        logger.log(
          `Signaling channel state changed .[${connectionId}] ${signalrConnection?.state}`
        );
      });
      signalrConnection?.onclose(error => {
        logger.log(
          `Signaling channel state changed. ${signalrConnection?.state} ${error}`
        );
        if (
          signalrConnection &&
          signalrConnection.state === HubConnectionState.Disconnected
        ) {
          logger.log(`Signaling disconnect.`);
        }
      });
      signalrConnection?.onreconnecting(error => {
        logger.warn(
          `Signaling channel state changed. ${signalrConnection?.state} ${error}`
        );
      });
      signalrConnection?.on("hubResponse", handleHubResponse);
      connection = signalrConnection;
      return signalrConnection;
    })
    .catch((err: Error) => {
      logger.error(`signalR connection error: ${err}`);
    });
};

export const handleHubResponse = async (rawMsg: unknown): Promise<void> => {
  const msg = deserialize(rawMsg, "HubResponse");
  switch (msg.key) {
    case "GetDeviceInfo":
    case "OpenUri":
    case "JoinVideoConference":
    case "GetSessionState":
    case "GetToggleMicrophone":
    case "GetToggleVolume":
    case "GetVolume":
      loggerService.log(msg.key + " message received");
      break;
    case "ConnectToPlannedMeeting":
      await sendMessage(buildRequestMsg("ForceDisconnect"));
      store.commit("room/setTransfering", false);
      break;
    default:
      logger.log(`Unhandled hub response: ${msg.key}`);
  }
};

const sendMessage = async (message: MagoLinkMessage): Promise<void> => {
  if (connection) {
    const msg = serialize(message);
    try {
      const response = await connection.invoke("TrySendRequest", msg);
      if (!response) {
        logger.warn(`SendMessageAsync has empty response`);
      }
    } catch (err) {
      logger.warn(`Send message fail, Error: ${err}`);
    }
  } else {
    logger.error(`SendSignalMessageAsync socket disconnected`);
  }
};

const sendDeferredMessage = async (message: MagoLinkMessage): Promise<void> => {
  await setTimeout(() => sendMessage(message), 2000, true);
};

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isManager
        ? _c("user-autocomplete", {
            staticClass: "mb-1",
            attrs: { icon: "va va-plus" },
            on: { userSelected: _vm.onAddUser }
          })
        : _vm._e(),
      _vm.isManager
        ? _c("va-text-field", {
            staticClass: "mb-6",
            attrs: {
              rules: [_vm.validMail],
              label: _vm.$t("rooms.inviteByMail"),
              "append-icon": "va va-plus",
              loading: _vm.isLoading
            },
            on: { "click:append": _vm.inviteByMail }
          })
        : _vm._e(),
      _vm.room && _vm.room.users
        ? _c("v-virtual-scroll", {
            attrs: {
              items: _vm.room.users,
              "item-height": "73",
              height: _vm.scrollerHeight
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "div",
                        {
                          key: item.userId,
                          staticClass: "py-4 border-b d-flex align-center "
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex-grow-1" },
                            [
                              _c("va-badge", {
                                attrs: {
                                  image: item.avatar,
                                  text: item.firstName + " " + item.lastName
                                }
                              })
                            ],
                            1
                          ),
                          !item.isAdmin && _vm.isManager
                            ? _c("va-button", {
                                attrs: {
                                  color: "error",
                                  type: "square",
                                  icon: "va va-close"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.onRemoveUser(item)
                                  }
                                }
                              })
                            : _vm._e(),
                          item.isAdmin
                            ? _c("va-button", {
                                staticClass: "cursor-default",
                                attrs: {
                                  color: "secondary",
                                  type: "outline",
                                  cta: _vm.$t("rooms.manager")
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              2410493171
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
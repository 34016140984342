import Vuetify from "@/plugins/vuetify";
import { MutationTree, ActionTree, GetterTree } from "vuex";
import { RootState } from "@/store";

export interface AppState {
  darkTheme: boolean;
  guestEnabled: boolean;
}

// type AppContext = ActionContext<AppState, RootState>;

export const namespaced = true;

export const state = (): AppState => ({
  darkTheme:
    (localStorage.getItem("darkTheme") &&
      localStorage.getItem("darkTheme") === "true") ||
    Vuetify.framework.theme.dark,
  guestEnabled: process.env.VUE_APP_GUEST_ENABLED === "true"
});

export const getters: GetterTree<AppState, RootState> = {
  darkTheme: state => {
    return state.darkTheme;
  },
  guestEnabled: state => {
    return state.guestEnabled;
  }
};

export const mutations: MutationTree<AppState> = {
  toggleDarkTheme(state: AppState) {
    state.darkTheme = !state.darkTheme;
    Vuetify.framework.theme.dark = state.darkTheme;
    localStorage.setItem("darkTheme", state.darkTheme.toString());
  }
};

export const actions: ActionTree<AppState, RootState> = {};

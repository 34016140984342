import * as signalr from "signalr-no-jquery";
import logger from "@/services/loggerService";
import * as configService from "@/services/configService";
import { Socket } from "@/models/socket.interface";
import {
  SocketStrokeDeserializer,
  SocketStroke
} from "@/models/wb/stroke.interface";
import store from "@/store";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import hmacsha256 from "crypto-js/hmac-sha256";
import Base64 from "crypto-js/enc-base64";
import {
  ConferenceAuthData,
  ConferenceAuthDataDeserializer
} from "@/models/conferenceAuthData.interface";
import { MeetingUserDeserializer } from "@/models/user.interface";
import { User } from "@/models/user.interface";
import { GuestRoom, GuestRoomDeserializer } from "@/models/room.interface";
import {
  ShortMeeting,
  ShortMeetingDeserializer
} from "@/models/wb/shortMeeting.interface";
import * as wbFileService from "@/services/wb/wbFileService";
import { SocketFileManipulation } from "@/models/wb/canvasFile.interface";
import { fileManipulationDeserializer } from "@/models/wb/canvasFile.interface";
import * as wbDrawService from "@/services/wb/wbDrawService";
import router from "@/router";
import {
  Page,
  PageDeserializer,
  SocketPageElement
} from "@/models/wb/page.interface";
import { buildRequestConfig } from "@/utils/ApiHelpers";
import i18n from "@/plugins/lang";
import { PollDeserializer } from "@/models/poll/poll.interface";
import {
  PollAnswerSerializer,
  Question
} from "@/models/poll/question.interface";

async function _authConference(
  meetingId: string,
  pin: string,
  name: string,
  userId?: string
): Promise<ConferenceAuthData> {
  const requestUri = configService.authConferenceUrl();
  const preSharedKey = configService.preSharedKey;
  const timeStamp = Math.round(+new Date() / 1000);
  const nonce = uuidv4()
    .toString()
    .replace(/-/g, "");

  let storedID = localStorage.getItem("browserUniqueId");
  if (!storedID) {
    storedID = uuidv4()
      .toString()
      .replace(/-/g, "");
    localStorage.setItem("browserUniqueId", storedID);
  }
  const uniqueId = storedID;

  const hashData = `${nonce}\n${timeStamp}\nPOST\n${requestUri}\n${uniqueId}`;
  const signatureBytes = Base64.stringify(hmacsha256(hashData, preSharedKey));

  const data = {
    MeetingId: meetingId,
    MeetingPin: pin,
    Nickname: name,
    UniqueId: uniqueId, //DeviceUniqueId
    AppPlatform: "Web",
    AppVersion: process.env.VUE_APP_VERSION,
    ApiVersion: "3.3",
    UserId: userId
  };
  const resp = await axios.post(requestUri, data, {
    headers: {
      Authorization: `RMS ${nonce}:${timeStamp}:${signatureBytes}`
    }
  });
  const result = ConferenceAuthDataDeserializer(resp.data);
  result.meetingId = meetingId;
  result.pin = pin;
  return result;
}

async function _init(authData: ConferenceAuthData): Promise<Socket> {
  const connection = signalr.hubConnection(authData.meetingServerUrl, {
    qs: "auth=" + authData.token
  });
  const proxy = connection.createHubProxy("MeetingHub");
  proxy.on("OnSetWebConferenceStatus", (rawMsg: unknown) => {
    store.commit("conference/activateGuestRoom", !!rawMsg);
    store.commit("notifications/displayConfirmDialog", {
      visible: true,
      title: i18n.t("conference.incomingTitle"),
      description: i18n.t("conference.incomingDescription"),
      callback: async () => {
        store.dispatch("wb/files/unfocusImage");
        store.commit("videoConference/setMinimized", false);
      }
    });
  });
  proxy.on("OnUserConnect", (rawMsg: unknown) => {
    const user = MeetingUserDeserializer(rawMsg);
    store.commit("conference/addUser", user);
  });
  proxy.on("OnUserConnect", (rawMsg: unknown) => {
    const user = MeetingUserDeserializer(rawMsg);
    store.commit("conference/addUser", user);
  });
  proxy.on("Disconnect", () => {
    store.dispatch("conference/stop");
    router.push(`/room`);
  });

  /*-----CANVAS EVENTS-----*/
  /* directly dispatched to canvas react component */
  proxy.on("OnShortPageDraw", (pageUuid: string, drawData: SocketStroke) => {
    let scale =
      Number(drawData.R.split(",")[0]) /
      store.getters["canvas/page"].size.width;

    if (
      drawData.T &&
      drawData.T !== "" &&
      store.getters["wb/files/focusOnfile"]
    ) {
      //traits over fullscreen image
      scale = 1;
    }
    let file;
    if (
      drawData.T &&
      drawData.T !== "" &&
      !store.getters["wb/files/focusOnfile"]
    ) {
      file = store.getters["wb/files/imageByUuid"](drawData.T);
      // traits over not fullscreen image
      scale = Number(drawData.R.split(",")[0]) / file.size.width;
    }
    const data = SocketStrokeDeserializer(drawData, scale);

    if (data.container && !store.getters["wb/files/focusOnfile"]) {
      // traits over not fullscreen image
      if (data.mode === "eraser") {
        store.dispatch("wb/files/updateFileTraits", { canvasFile: file });
      } else {
        store.dispatch("wb/files/addStrokeToUnfocusedFile", {
          stroke: data,
          file: file
        });
      }

      // if(!file.pagesStrokes){
      //   file.pagesStrokes = {};
      //   file.pagesStrokes[file.currentPage] = [];
      // }
      // file.pagesStrokes[file.currentPage].push(data);
      // // debugger
      // data.mode === "eraser"
      //   ? store.dispatch("wb/files/updateFileTraits", {canvasFile: file}):
      // store.dispatch("wb/files/addStrokeToUnfocusedFile", {stroke: data, file: file});

      // lines.push(line);
      // data.imagesLayer?.add(line);
    } else {
      //traits over whiteboard

      data.mode === "eraser"
        ? wbDrawService.fetchCurrentStrokes(pageUuid)
        : store.dispatch("canvas/addStroke", {
            stroke: data,
            addNewStroke: true,
            pageUuid: pageUuid
          });
    }
  });
  proxy.on("OnRunAction", () => {
    //redraw board on special actions like lasso
    const page = store.getters["canvas/page"];
    if (page) {
      wbDrawService.fetchCurrentStrokes(page.uuid);
    }
  });
  proxy.on("OnSelectPage", (pageKey: string) => {
    store.dispatch("canvas/changePage", { pageUuid: pageKey });
  });
  proxy.on("OnClonePage", (originalId: string, clonedId: string) => {
    store.dispatch("canvas/clonePage", {
      originalId: originalId,
      clonedId: clonedId
    });
  });
  proxy.on("OnDeletePage", (pageKey: string) => {
    //hack add delay for multiple pages delete
    setTimeout(() => {
      store.dispatch("canvas/deletePage", { pageUuid: pageKey });
    }, 200);
  });
  proxy.on(
    "OnAddElementToPage",
    async (pageKey: string, fileData: SocketFileManipulation) => {
      const file = await wbFileService.message2File(fileData);
      store.commit("wb/files/setRemoteFile2Load", file, { root: true });
    }
  );
  proxy.on("OnSetPageBackground", (pageKey: string, fileId: string) => {
    // fileID can be an hexadecimal color
    store.dispatch("canvas/updateBackgroundColor", { color: fileId });
  });
  proxy.on(
    "OnSetPageBackgroundFile",
    (pageKey: string, fileId: string | undefined) => {
      store.dispatch("canvas/updateBackgroundImage", { imageId: fileId });
    }
  );
  proxy.on(
    "OnSetPageElementFiles",
    (
      pageKey: string,
      elementKey: string,
      fileId: string,
      pdfFileId: string
    ) => {
      let file = store.getters["wb/files/imageByUuid"](elementKey);
      if (!file) {
        file = store.getters["wb/files/remoteFile2Load"];
      }
      if (file.type == "text" || file.type == "url") {
        store.dispatch("wb/files/updateTexFileContent", {
          canvasFile: file,
          fileId: fileId
        });
      } else {
        store.dispatch("wb/files/loadRemoteFile", {
          canvasFile: file,
          fileId: fileId,
          pdfFileId: pdfFileId
        });
      }
    }
  );
  proxy.on(
    "OnUpdatePageElement",
    async (pageKey: string, elementKey: string, state: unknown) => {
      const data = await fileManipulationDeserializer(elementKey, state);
      store.dispatch("wb/files/onReceiveUpdateFile", data);
    }
  );
  proxy.on("OnDeleteElementFromPage", (pageKey: string, elementKey: string) => {
    store.dispatch("wb/files/unfocusImage");
    store.commit(
      "wb/files/deleteFile",
      { fileId: elementKey, local: false },
      { root: true }
    );
  });
  proxy.on("OnAddRoleToUsers", (userIdList: unknown, roleName: unknown) => {
    if (!Array.isArray(userIdList)) {
      userIdList = [userIdList];
    }
    (userIdList as string[]).map(id => {
      store.dispatch("conference/userRoleAdded", {
        userIdList: id,
        roleName: roleName
      });
    });
  });
  proxy.on(
    "OnRemoveRoleFromUsers",
    (userIdList: unknown, roleName: unknown) => {
      if (!Array.isArray(userIdList)) {
        userIdList = [userIdList];
      }
      (userIdList as string[]).map(id => {
        store.dispatch("conference/userRoleRemoved", {
          userIdList: id,
          roleName: roleName
        });
      });
    }
  );
  /*-----END CANVAS EVENTS-----*/
  /*-----POLL EVENTS-----*/
  proxy.on("OnStartPoll", (rawMsg: unknown) => {
    const poll = PollDeserializer(rawMsg);
    store.commit("wb/polls/setActivePoll", poll);
  });
  proxy.on("OnStopPoll", () => {
    store.commit("wb/polls/setActivePoll", undefined);
  });
  /*-----END POLL EVENTS-----*/

  return { type: "conference", connection, proxy };
}

async function _connect(socket: Socket): Promise<void> {
  let currentRetry = 0; // Gestione retry max 3
  try {
    socket.connection.disconnected(() => {
      store.commit("conference/setConnectionReady", false);
      store.commit("conference/isDisconnected", true);
      logger.log("MEETING Hub SignalR state disconnected ");
    });

    socket.connection.reconnecting(() => {
      store.commit("conference/setConnectionReady", false);
      store.commit("conference/isDisconnected", false);
      logger.log("SignalR state reconnected ");
    });

    socket.connection
      .start({ transport: ["webSockets", "longPolling"] })
      .done(() => {
        store.commit("conference/setConnectionReady", true);
        store.commit("conference/isDisconnected", false);
        logger.log("MEETING Hub Connection Done!");
        askForPoll(socket);
      });
  } catch (err) {
    currentRetry++;
    if (currentRetry < configService.socketRetryAttempts) {
      logger.warn("SignalR conference connection error: retrying");
      logger.warn(err);
      setTimeout(function() {
        _connect(socket);
      }, configService.socketRetryTimeout);
    } else {
      logger.error(
        "Cannot connect to the meeting hub server at this time. Please try login again."
      );
      logger.error(err);
    }
  }
}

export async function initConferenceConnection(
  meetingId: string,
  pin: string,
  name: string,
  userId?: string
): Promise<{ socket: Socket; authdata: ConferenceAuthData }> {
  const authData = await _authConference(meetingId, pin, name, userId);
  const socket = await _init(authData);
  await _connect(socket);
  return { socket: socket, authdata: authData };
}

export async function stopConferenceConnection(socket: Socket): Promise<void> {
  socket.connection.stop();
}

export async function reconnect(socket: Socket): Promise<void> {
  await _connect(socket);
}

export async function askForPoll(socket: Socket): Promise<unknown> {
  const res = await socket.proxy.invoke("GetPoll");
  if (res.Item1.Poll) {
    const poll = PollDeserializer(res.Item1.Poll);
    store.commit("wb/polls/setActivePoll", poll);
  }
  return res;
}

export async function sendAnswer(
  socket: Socket,
  answeredQuestion: Question
): Promise<void> {
  socket.proxy.invoke(
    "SetPollItemResponse",
    PollAnswerSerializer(answeredQuestion)
  );
}

export async function getUsers(): Promise<User[]> {
  const authData = store.getters["conference/authData"];
  const requestUrl =
    authData.meetingServerUrl + configService.getUsersEndpoint();
  const resp = await axios.get(requestUrl, buildRequestConfig());
  if (resp.data.Item1) {
    return resp.data.Item1.map((user: unknown) => {
      return MeetingUserDeserializer(user);
    });
  }
  return [];
}

export async function getShortMeeting(): Promise<ShortMeeting | undefined> {
  const authData = store.getters["conference/authData"];
  const requestUrl =
    authData.meetingServerUrl + configService.getShortMeetingEndpoint();
  const resp = await axios.get(requestUrl, buildRequestConfig());
  if (resp?.data?.Item1) {
    return await ShortMeetingDeserializer(resp.data.Item1);
  }
}

export async function getShortMeetingPage(
  pageId: string
): Promise<Page | undefined> {
  const authData = store.getters["conference/authData"];
  const requestUrl =
    authData.meetingServerUrl +
    configService.getShortMeetingPageEndpoint() +
    "/" +
    pageId;
  const resp = await axios.get(requestUrl, buildRequestConfig());
  if (resp?.data?.Item1) {
    const result = await PageDeserializer(resp.data.Item1);
    return result;
  }
}

export async function getMeeting(): Promise<GuestRoom | undefined> {
  const authData = store.getters["conference/authData"];
  const requestUrl =
    authData.meetingServerUrl + configService.getMeetingEndpoint();
  const resp = await axios.get(requestUrl, buildRequestConfig());
  if (resp?.data?.Item1) {
    return GuestRoomDeserializer(resp.data.Item1);
  }
}

export async function setWebConferenceStatus(
  socket: Socket,
  status: string
): Promise<unknown> {
  return socket.proxy.invoke("SetWebConferenceStatus", status);
}

export async function sendStroke(
  socket: Socket,
  data: { pageId: string; stroke: SocketStroke }
): Promise<void> {
  socket.proxy.invoke("ShortPageDraw", data.pageId, data.stroke);
}

export async function sendUpdateImage(
  socket: Socket,
  pageId: string,
  elementId: string,
  msgdata: SocketFileManipulation
): Promise<void> {
  // const msgData = fileManipulationSerializer(element, page);
  socket.proxy.invoke("UpdatePageElement", pageId, elementId, msgdata);
}

export async function selectPage(
  socket: Socket,
  pageId: string
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
): Promise<any> {
  return socket.proxy.invoke("SelectPage", pageId);
}

export async function clonePage(
  socket: Socket,
  newPageId: string,
  oldPageId: string
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
): Promise<any> {
  return socket.proxy.invoke("ClonePage", oldPageId, newPageId);
}

export async function deletePage(
  socket: Socket,
  pageId: string
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
): Promise<any> {
  return socket.proxy.invoke("DeletePage", pageId);
}

export async function addElementToPage(
  socket: Socket,
  pageId: string,
  elementInfo: SocketFileManipulation
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
): Promise<any> {
  return socket.proxy.invoke("AddElementToPage", pageId, elementInfo);
}

export async function deleteElementFromPage(
  socket: Socket,
  data: { pageId: string; imageId: string }
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
): Promise<any> {
  return socket.proxy.invoke(
    "DeleteElementFromPage",
    data.pageId,
    data.imageId
  );
}

export async function setPageElementFiles(
  socket: Socket,
  elementInfo: SocketPageElement
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
): Promise<any> {
  return socket.proxy.invoke(
    "SetPageElementFiles",
    elementInfo.pageKey,
    elementInfo.elementKey,
    elementInfo.fileId,
    elementInfo.pdfFileId
  );
}

export async function setPageBackground(
  socket: Socket,
  elementInfo: { pageKey: string; fileId: { color: string } }
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
): Promise<any> {
  return socket.proxy.invoke(
    "SetPageBackground",
    elementInfo.pageKey,
    elementInfo.fileId.color
  );
}

export async function setPageBackgroundFile(
  socket: Socket,
  elementInfo: { pageKey: string; fileId: { image: string } }
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
): Promise<any> {
  return socket.proxy.invoke(
    "SetPageBackgroundFile",
    elementInfo.pageKey,
    elementInfo.fileId.image
  );
}

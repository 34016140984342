var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("va-button", {
        attrs: {
          icon: _vm.icon,
          color: _vm.iconColor,
          type: _vm.hideBorder ? "squareText" : "square"
        },
        on: { click: _vm.toggleLight }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-center" }, [
    _c(
      "div",
      { staticClass: "secondary-outline rounded pa-3 elevation-8 mb-4" },
      [
        _vm._v(" " + _vm._s(_vm.$t("conference.iframeDisclaimer"))),
        _c("br"),
        _c(
          "a",
          {
            on: {
              click: function($event) {
                return _vm.openWindow(_vm.url)
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("conference.iframeOpen")))]
        )
      ]
    ),
    _c("iframe", {
      staticClass: "secondary-outline rounded elevation-8",
      staticStyle: { border: "none", background: "white" },
      attrs: { src: _vm.cleanUrl, width: "820", height: "470" }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
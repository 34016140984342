

















































































import Vue from "vue";
import CenterContent from "@/components/templates/CenterContent.vue";
import { User } from "@/models/user.interface";
import VaButton from "@/components/atoms/VaButton.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import UserAutocomplete from "@/components/organisms/UserAutocomplete.vue";
import VaIcon from "@/components/atoms/VaIcon.vue";
import VaBadge from "@/components/molecules/VaBadge.vue";
import MetaHelper from "@/services/metaHelper";
import validationMixin from "@/mixins/validationMixin";
import roomBackgroundMixin from "@/mixins/roomBackgroundMixin";

export default Vue.extend({
  name: "NewRoom",
  components: {
    CenterContent,
    VaButton,
    VaTextField,
    UserAutocomplete,
    VaIcon,
    VaBadge
  },
  mixins: [validationMixin, roomBackgroundMixin],
  meta() {
    return MetaHelper.buildMeta("roomNew");
  },
  data: () => ({
    valid: true,
    name: "",
    users: [] as User[]
  }),
  computed: {
    user(): User {
      return this.$store.getters["users/user"];
    },
    scrollerHeight() {
      return this.users.length < 3 ? this.users.length * 75 : 220;
    },
    canCreateRoom(): boolean {
      return this.$store.getters["users/canCreateRoom"];
    }
  },
  watch: {
    user: function(newVal) {
      this.addManager(newVal);
    }
  },
  mounted() {
    this.addManager(this.user);
  },
  methods: {
    addManager(user: User): void {
      if (!this.users?.length && user?.userId) {
        this.users.push(user);
      }
    },
    createRoom(): void {
      if (
        (this.$refs.newRoomForm as Vue & { validate: () => boolean }).validate()
      ) {
        this.$store.dispatch("room/createRoom", {
          name: this.name,
          users: this.users
        });
      }
    },
    onRemoveUser(userToRemove: User) {
      this.users = this.users.filter(user => {
        return user.userId !== userToRemove.userId;
      });
    },
    onSelectUser(user: User) {
      this.users.push(user);
    }
  }
});



















































import Vue from "vue";
import VaButton from "@/components/atoms/VaButton.vue";
import { availablecolors } from "@/services/configService";
import * as configService from "@/services/configService";
import iconcolorMixin from "@/mixins/iconcolorMixin";

export default Vue.extend({
  components: {
    VaButton
  },
  data: () => ({
    menu: false,
    availableColors: availablecolors,
    maxToolSize: configService.maxToolSize || 20
  }),
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false
    },
    color: {
      type: String,
      required: false,
      default: "black"
    },
    colorSelector: {
      type: Boolean,
      required: false,
      default: true
    },
    name: {
      type: String,
      required: false,
      default: "tool-selector"
    },
    icon: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      required: false
    }
  },
  mixins: [iconcolorMixin],
  computed: {
    displaySubmenu(): boolean {
      return !!this.colorSelector || !!this.size;
    },
    toolSize: {
      get: function(): number {
        return this.size;
      },
      set: function(newValue: number) {
        this.$emit("changeSize", newValue);
      }
    }
  },
  watch: {
    // this way when active is set by external factor like store change it will show menu, not only at on click
    active(val: boolean): void {
      this.menu = val;
    }
  },
  methods: {
    onSelectTool() {
      this.$emit("selectTool");
      this.menu = !this.menu;
    },
    onSelectColor(selectedColor: string) {
      this.$emit("selectColor", selectedColor);
    }
  }
});

import store from "@/store";
// ------------------------ handle variables override ------------------------
let RMS_LEGACY_BASEURL = process.env.VUE_APP_RMS_LEGACY_BASEURL;
let V4_BASEURL = process.env.VUE_APP_V4_BASEURL;
let PDF_CONVERTER_URL = process.env.VUE_APP_PDF_CONVERTER_URL;
let DOWNLOAD_URL = process.env.VUE_APP_DOWNLOAD_URL;
let WEBAPP_URL = process.env.VUE_APP_WEBAPP_URL;
let WEBRTC_BASEURL = process.env.VUE_APP_WEBRTC_BASEURL;
let MAGOLINK_EXTERNAL_SERVER = process.env.VUE_APP_MAGOLINK_EXTERNAL_SERVER;

//in a on premise environment check the custom onprem config
if (window.RMS_LEGACY_BASEURL) RMS_LEGACY_BASEURL = window.RMS_LEGACY_BASEURL;
if (window.V4_BASEURL) V4_BASEURL = window.V4_BASEURL;
if (window.PDF_CONVERTER_URL) PDF_CONVERTER_URL = window.PDF_CONVERTER_URL;
if (window.DOWNLOAD_URL) DOWNLOAD_URL = window.DOWNLOAD_URL;
if (window.WEBAPP_URL) WEBAPP_URL = window.WEBAPP_URL;
if (window.WEBRTC_BASEURL) WEBRTC_BASEURL = window.WEBRTC_BASEURL;
if (window.MAGOLINK_EXTERNAL_SERVER)
  MAGOLINK_EXTERNAL_SERVER = window.MAGOLINK_EXTERNAL_SERVER;
// ------------------------ end variables override ------------------------
export const preSharedKey = process.env.VUE_APP_RMS_PRE_SHARED_KEY || "";

export const downloadAppUr = DOWNLOAD_URL;
export const webAppUrl = WEBAPP_URL;
export const webRtcBaseUrl = WEBRTC_BASEURL;

export const applicationName = "WebApp";

export const socketRetryAttempts = 3;
export const socketRetryTimeout = 2000;

export const magolinkSecret = process.env.VUE_APP_MAGOLINK_SECRET || "";
export const magolinkRole = process.env.VUE_APP_MAGOLINK_ROLE;
export const magolinkBleServiceId = process.env.VUE_APP_MAGOLINK_BLE_ID;
export const magolinkDefaultExternalServer = MAGOLINK_EXTERNAL_SERVER;
export const magolinkPasscodeUrl = `${magolinkDefaultExternalServer}/api/v1/auth/user/passcode`;
export const magolinkSignalrUrl = `${magolinkDefaultExternalServer}/v1/RoomsHub`;

export const availablecolors = ["black", "red", "green", "yellow", "blue"];
export const availableHexcolors = [
  "#000000",
  "#FF0000",
  "#008000",
  "#FFFF00",
  "#0000FF",
  "#FFFFFF"
];

export const emptyFileId = "00000000-0000-0000-0000-000000000000";

export const availableBackgrounds = [
  require(`@/assets/img/whiteboard/bg-blackboard-4K.jpg`),
  require(`@/assets/img/whiteboard/bg-brainstorming.png`),
  require(`@/assets/img/whiteboard/bg-four-square.png`),
  require(`@/assets/img/whiteboard/bg-scrum-2.png`),
  require(`@/assets/img/whiteboard/bg-value-proposition.png`),
  require(`@/assets/img/whiteboard/bg-x2.jpg`),
  require(`@/assets/img/whiteboard/bg-x4.jpg`),
  require(`@/assets/img/whiteboard/bg-blackboard-HD.jpg`),
  require(`@/assets/img/whiteboard/bg-dotted-page.png`),
  require(`@/assets/img/whiteboard/bg-scrum-1.png`),
  require(`@/assets/img/whiteboard/bg-scrum-3-aftersprint.png`),
  require(`@/assets/img/whiteboard/bg-x1.jpg`),
  require(`@/assets/img/whiteboard/bg-x3.jpg`),
  require(`@/assets/img/whiteboard/bg-x5.jpg`)
];

export const availableBgColors = [
  "#FFFFFF",
  "#F5F5F5",
  "#FFFFE0",
  "#FFEBCD",
  "#FFE4E1",
  "#AFEEEE",
  "#ADD8E6",
  "#90EE90",
  "#32CD32",
  "#A9A9A9",
  "#4682B4",
  "#000000",
  "#ff2121",
  "#f6f618"
];

export const wheelZoomAmount = 1.05;
export const zoomAmount = 1.3;
export const maxZoom = 9;
// export const minZoom = 1;
export const maxToolSize = 25;

export const pdfJsWorkerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.js";

export const rmsBaseUrl = (): string => {
  const authData = store.getters["session/authData"];
  return (authData?.legacyServerUrl || RMS_LEGACY_BASEURL).toLowerCase();
};

export const chatServerBaseUrl = (): string => {
  const authData = store.getters["session/authData"];
  return authData?.chatServerUrl || process.env.VUE_APP_CHAT_LEGACY_BASEURL;
};

export const authUrl = (): string => {
  return `${V4_BASEURL}/api/v1/auth/token`;
};

export const signUpUrl = (): string => {
  return `${V4_BASEURL}/api/v1/auth/fast-signup`;
};
export const inviteSignUpUrl = (): string => {
  return `${V4_BASEURL}/api/v1/auth/invite-signup`;
};

export const confirmMailUrl = (): string => {
  return `${V4_BASEURL}/api/v1/auth/email/confirmation`;
};
export const confirmResendUrl = (): string => {
  return `${V4_BASEURL}/api/v1/auth/email/confirm/request`;
};

export const forgotPasswordUrl = (): string => {
  return `${V4_BASEURL}/api/v1/auth/password/reset/request`;
};

export const resetPasswordUrl = (): string => {
  return `${V4_BASEURL}/api/v1/auth/password/reset`;
};

export const refreshAuthUrl = (): string => {
  return `${V4_BASEURL}/api/v1/auth/token-refresh`;
};

export const authConferenceUrl = (): string => {
  return `${rmsBaseUrl()}/api/auth`;
};

export const getShortMeetingEndpoint = (): string => {
  return `/api/Meeting/GetShortMeeting`;
};
export const getShortMeetingPageEndpoint = (): string => {
  return `/api/Meeting/GetShortMeetingPage`;
};

export const getMeetingEndpoint = (): string => {
  return `/api/Meeting/GetMeeting`;
};

export const getPdfStrokesEndpoint = (): string => {
  return `/api/Meeting/GetFilePageStrokes`;
};

export const getUsersEndpoint = (): string => {
  return `/api/Meeting/GetUsers`;
};

export const getFindUsersUrl = (): string => {
  return `${chatServerBaseUrl()}/api/GlobalChat/FindUsers`;
};

export const apiServerBaseUrl = (): string => {
  const authData = store.getters["conference/authData"];
  return authData?.meetingServerUrl || "";
};

export const pdfServerBaseUrl = (): string => {
  return PDF_CONVERTER_URL || "";
};

export const getUserProfileUrl = (): string => {
  return `${rmsBaseUrl()}/api/Service/GetCurrentUserProfile`;
};

export const getUserMeUrl = (): string => {
  return `${V4_BASEURL}/api/v1/users/me`;
};

export const setUserProfile = (): string => {
  return `${chatServerBaseUrl()}/api/GlobalChat/SetCurrentUserProfile`;
};

export const setUserAvatar = (): string => {
  return `${chatServerBaseUrl()}/api/GlobalChat/SetCurrentUserAvatar`;
};

export const getUserAvatarUrl = (): string => {
  return `${chatServerBaseUrl()}/api/Users/GetUserAvatar`;
};

export const getChatAvatarUrl = (isGroup: boolean): string => {
  if (isGroup) {
    return `${chatServerBaseUrl()}/api/Groups/GetGroupAvatar`;
  }
  return `${chatServerBaseUrl()}/api/Users/GetUserAvatar`;
};

export const getRoomDetailUrl = (): string => {
  return `${chatServerBaseUrl()}/api/Groups`;
};

export const setRoomAvatar = (): string => {
  return `${chatServerBaseUrl()}/api/GlobalChat/SetGroupAvatar`;
};

export const getRoomInfoUrl = (): string => {
  return `${chatServerBaseUrl()}/api/GlobalChat/GetChatMeeting`;
};

export const getRoomMessagesUrl = (): string => {
  return `${chatServerBaseUrl()}/api/Groups/GetMessages`;
};

export const getRoomUpdateUrl = (): string => {
  return `${chatServerBaseUrl()}/api/GlobalChat/UpdateGroup`;
};

export const getInviteByMailUrl = (): string => {
  return `${rmsBaseUrl()}/api/Service/InviteMemberEmail`;
};

export const getRoomRemoveUserUrl = (): string => {
  return `${chatServerBaseUrl()}/api/Groups/RemoveUser`;
};

export const getRoomListUrl = (): string => {
  return `${chatServerBaseUrl()}/api/GlobalChat/GetRooms`;
};

export const getLeaveRoomUrl = (): string => {
  return `${chatServerBaseUrl()}/api/Groups/Leave`;
};

export const getLeaveSingleRoomUrl = (): string => {
  return `${chatServerBaseUrl()}/api/GlobalChat/DeleteUserChat`;
};

/* -------- MFA SECTION -------- */

export const mfaProvidersUrl = (): string => {
  return `${V4_BASEURL}/api/v1/auth/mfa/providers`;
};
export const enabledProvidersUrl = (): string => {
  return `${V4_BASEURL}/api/v1/auth/me/mfa/providers`;
};
export const providersChallengeUrl = (): string => {
  return `${V4_BASEURL}/api/v1/auth/token/mfa/challenge`;
};
export const mfaEnableCodeUrl = (): string => {
  return `${V4_BASEURL}/api/v1/auth/mfa/send-code`;
};
export const mfaVerificationCodeUrl = (): string => {
  return `${V4_BASEURL}/api/v1/auth/mfa/verify-code`;
};
export const confirmMFAActivationUrl = (): string => {
  return `${V4_BASEURL}/api/v1/auth/mfa`;
};
export const mfaCheckCodeUrl = (): string => {
  return `${V4_BASEURL}/api/v1/auth/token/mfa/send-code`;
};
export const backupCodesUrl = (): string => {
  return `${V4_BASEURL}/api/v1/auth/mfa/backup-codes`;
};
export const resetBackupCodesUrl = (): string => {
  return `${V4_BASEURL}/api/v1/auth/mfa/refresh-backup-codes`;
};

//LEGACY URLS ------------------

export const confirmMailUrlLegacy = (): string => {
  return `${RMS_LEGACY_BASEURL}/api/Service/ConfirmEmail`;
};

export const confirmInvitationUrlLegacy = (): string => {
  return `${RMS_LEGACY_BASEURL}/api/Service/ConfirmInvitation`;
};

export const resetPasswordUrlLegacy = (): string => {
  return `${RMS_LEGACY_BASEURL}/api/Service/ResetPassword`;
};















import Vue from "vue";
import VaAvatar from "@/components/atoms/VaAvatar.vue";

export default Vue.extend({
  components: {
    VaAvatar
  },
  props: {
    text: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: "m",
      validator(value) {
        return ["l", "m", "s"].includes(value);
      }
    },
    image: {
      type: String,
      required: false
    },
    color: {
      type: String,
      required: false,
      default: "secondary"
    }
  }
});

import {
  CanvasFile,
  isValidFile,
  SocketFileManipulation,
  fileTypes,
  fileManipulationSerializer,
  FileManipulation,
  FileDetails,
  image2Manipulation,
  DetailsDeserializer
} from "@/models/wb/canvasFile.interface";
import { Page } from "@/models/wb/page.interface";
import store from "@/store";
import * as conferenceService from "@/services/conferenceSocketService";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import * as configService from "@/services/configService";
import logger from "@/services/loggerService";
import * as pdfjs from "pdfjs-dist";
import * as wbConversionService from "@/services/wb/wbConversionService";
import { Stroke, StrokeDeserializer } from "@/models/wb/stroke.interface";
import i18n from "@/plugins/lang";
import { emptyImage } from "@/services/wb/elements/placeholders";
import { BoardElement } from "@/models/wb/boardElement.interface";
import { buildRequestConfig } from "@/utils/ApiHelpers";
import {
  calcPreviewSize,
  cleanUrlText
} from "@/services/wb/elements/wbTexElemets.service";
import { Size } from "@/models/wb/size.interface";
import { emptyFileId } from "@/services/configService";

pdfjs.GlobalWorkerOptions.workerSrc = configService.pdfJsWorkerSrc;

export const getFileTypeByName = (filename: string): fileTypes => {
  const extension = filename.split(".").pop();
  switch (extension) {
    case "url":
      return "url";
    case "pdf":
      return "pdf";
    case "glb":
      return "glb";
    case "txt":
      return "text";
    case "marker":
      return "post-it";
    case "doc":
    case "docx":
    case "xlsx":
    case "xls":
    case "pptx":
    case "ppt":
      return "document";
    case "flv":
    case "mp4":
    case "m3u8":
    case "ts":
    case "3gp":
    case "mov":
    case "avi":
    case "wmv":
      return "video";
    case "jpeg":
    case "jpg":
    case "png":
    case "svg":
    case "gif":
      return "image";
    default:
      throw new Error("unhandled file type for file " + filename);
  }
};

export async function onTransformElement(): Promise<void> {
  if (store.getters["wb/tools/tool"] === "drag") {
    const currentImage = store.getters["wb/files/selectedImage"];
    if (!currentImage) return;
    const page = store.getters["canvas/page"];

    sendUpdatedFile(currentImage, page);

    currentImage.file.manipulation = image2Manipulation(currentImage.image);
    store.commit("canvas/updateCurrentPageFile", currentImage.file);
  }
}

export function sendFocusFile(
  element: BoardElement | undefined,
  page: Page,
  fullscreen: boolean
): void {
  if (element) {
    const msgData = fileManipulationSerializer(element, page);
    msgData.InFullscreen = fullscreen;
    conferenceService.sendUpdateImage(
      store.getters["conference/socket"],
      page.uuid,
      element.file.uuid,
      msgData
    );
  }
}

export function sendUpdatedFile(element: BoardElement, page: Page): void {
  if (!element) return;
  const msgData = fileManipulationSerializer(element, page);
  conferenceService.sendUpdateImage(
    store.getters["conference/socket"],
    page.uuid,
    element.file.uuid,
    msgData
  );
}

export function sendNewElementToSocket(element: BoardElement): void {
  const socket = store.getters["conference/socket"];
  const page = store.getters["canvas/page"];
  conferenceService.addElementToPage(
    socket,
    page.uuid,
    fileManipulationSerializer(element, page)
  );
  //Send message SetPageElementFiles - this is right sequence
  conferenceService.setPageElementFiles(socket, {
    pageKey: page.uuid,
    elementKey: element.file.uuid,
    fileId: element.file.fileId,
    pdfFileId: element.file.pdfFileId
  });
}

const getFileManipulation = (size: Size): FileManipulation => {
  const scale =
    size.width > store.getters["canvas/stage"].width() / 3.5
      ? Number((1 / 3.5).toFixed(4))
      : 0.9; //Scale should be different from 1 - Android bug
  const x =
    store.getters["canvas/stage"].width() /
      store.getters["canvas/stage"].scaleX() /
      2 -
    (size.width * scale) / 2;
  const y =
    store.getters["canvas/stage"].height() /
      store.getters["canvas/stage"].scaleY() /
      2 -
    (size.height * scale) / 2;
  return {
    size: `${size.width},${size.height}`,
    scaleX: scale,
    scaleY: scale,
    rotation: 0,
    x: Number(x.toFixed(2)),
    y: Number(y.toFixed(2))
  };
};

export async function loadRemoteFile(
  fileData: CanvasFile,
  fileId: string,
  pdfFileId?: string
): Promise<BoardElement> {
  fileData.fileId = fileId;
  fileData.pdfFileId = pdfFileId || emptyFileId;
  const konvaImage = await wbConversionService.File2KonvaNode(fileData, true);
  store.getters["wb/files/imagesLayer"].add(konvaImage);
  store.commit("wb/files/clearRemoteFile2Load", { root: true });
  return {
    image: konvaImage,
    file: fileData,
    lines: []
  };
}

function saveElementToStore(element: BoardElement): void {
  //TODO move "add" side effect to action
  store.getters["wb/files/imagesLayer"].add(element.image);
  store.commit("wb/files/addImage", element);
}

export async function createTextBox(): Promise<void> {
  store.commit("wb/files/setAssetsLoading", true);
  const newId = uuidv4();
  const name = `${newId}.txt`;
  const text = i18n.t("conference.addText").toString();
  const fileBlob = URL.createObjectURL(
    new Blob([text], { type: "text/plain" })
  );
  const uploadedFileId = await uploadFileRest(fileBlob, name);
  const element = await createBoardElement(
    calcPreviewSize(text),
    1,
    uploadedFileId,
    emptyFileId,
    newId,
    name,
    {
      foreground: "#000000",
      background: "#FFFFFF",
      text: text
    }
  );
  saveElementToStore(element);
  sendNewElementToSocket(element);
  store.commit("wb/files/setAssetsLoading", false);
}

export async function createUrlElement(url: string): Promise<void> {
  store.commit("wb/files/setAssetsLoading", true);
  const newId = uuidv4();
  const name = `${newId}.url`;
  const fileBlob = URL.createObjectURL(new Blob([url], { type: "text/plain" }));
  const uploadedFileId = await uploadFileRest(fileBlob, name);
  // const size = { width: 220, height: 123 };
  const element = await createBoardElement(
    { width: 1920, height: 1080 },
    1,
    uploadedFileId,
    emptyFileId,
    newId,
    name,
    {
      text: url
    }
  );
  saveElementToStore(element);
  sendNewElementToSocket(element);
  store.commit("wb/files/setAssetsLoading", false);
}

export async function createPostIt(): Promise<void> {
  store.commit("wb/files/setAssetsLoading", true);
  const newId = uuidv4();
  const name = `post-it-${newId}.marker`;
  const uploadedFileId = await uploadFileRest(emptyImage, name);
  // const size = { width: 600, height: 600 };
  const element = await createBoardElement(
    { width: 600, height: 600 },
    1,
    uploadedFileId,
    emptyFileId,
    newId,
    name,
    {
      background: "#f4eeb6",
      text: ""
    }
  );
  saveElementToStore(element);
  sendNewElementToSocket(element);
  store.commit("wb/files/setAssetsLoading", false);
}

export async function uploadFile(
  fileBlob: string,
  name: string
): Promise<void> {
  if (!isValidFile(name)) return;
  store.commit("wb/files/setAssetsLoading", true);
  const newId = uuidv4();
  const image = new Image();
  const isImage = getFileTypeByName(name) == "image";
  image.src = isImage ? fileBlob : require("@/assets/img/pdf-loader.svg");

  //image onload used to get proper dimension of image both on whiteboard and when opening the detail
  image.onload = async ev => {
    const img = ev.target as HTMLImageElement;
    const fileType = getFileTypeByName(name);
    let size = { width: img.width, height: img.height };
    let pages = 1;
    const uploadedFileId = await uploadFileRest(fileBlob, name);
    let pdfFileId = emptyFileId;
    if (fileType == "pdf") {
      [size, pages] = await parseLocalPdf(fileBlob);
    } else if (fileType == "document") {
      pdfFileId = await doc2pdf(uploadedFileId);
      fileBlob = await fetchFile(pdfFileId);
      [size, pages] = await parseLocalPdf(fileBlob);
      // name = name + ".pdf";
    } else if (fileType == "glb") {
      size = { width: 800, height: 450 };
    }
    const element = await createBoardElement(
      size,
      pages,
      uploadedFileId,
      pdfFileId,
      newId,
      name,
      {},
      fileBlob
    );
    saveElementToStore(element);
    sendNewElementToSocket(element);
    store.commit("wb/files/setAssetsLoading", false);
  };
}

export async function uploadBackgroundFile(fileReader: File): Promise<string> {
  const fileBlob = URL.createObjectURL(fileReader);
  return await uploadFileRest(fileBlob, fileReader.name);
}

async function parseLocalPdf(blobUrl: string): Promise<[Size, number]> {
  const loadingTask = pdfjs.getDocument(blobUrl);
  try {
    const documentProxy = await loadingTask.promise;
    const page = await documentProxy.getPage(1);
    return [
      {
        width: Math.floor(page.view[2]),
        height: Math.floor(page.view[3])
      },
      documentProxy.numPages
    ];
  } catch (err) {
    logger.error("Failed to get PDF data");
    logger.error(err);
    return [{ width: 0, height: 0 }, 0];
  }
}

export async function message2File(
  input: SocketFileManipulation
): Promise<CanvasFile> {
  return {
    uuid: input.Key || "",
    fileId: "",
    pdfFileId: "",
    name: input.Name,
    meetingId: "",
    size: {
      width: Number(input.Size.split(",")[0]),
      height: Number(input.Size.split(",")[1])
    },
    pagesStrokes: [],
    manipulation: {
      rotation: input.ManipulationData.Rotation,
      x: Number(input.ManipulationData.X),
      y: Number(input.ManipulationData.Y),
      scaleX: Number(input.ManipulationData.ScaleX),
      scaleY: Number(input.ManipulationData.ScaleY)
    },
    type: getFileTypeByName(input.Name),
    currentPage: 1,
    details: await DetailsDeserializer(input)
  };
}

export async function uploadFileRest(
  file: string,
  filename: string
): Promise<string> {
  const dataUrl2Blob = await fetch(file).then(r => {
    return r.blob();
  });
  const data = new FormData();
  data.append("file", dataUrl2Blob, filename);
  try {
    const response = await axios.post(
      `${configService.apiServerBaseUrl()}/api/Meeting/Files`,
      data,
      buildRequestConfig(`multipart/form-data; boundary=${data}._boundary`)
    );
    return response.data[0].FileId;
  } catch (e) {
    logger.error(e);
    return "";
  }
}

export async function fetchFile(
  fileId: string,
  isText = false
): Promise<string> {
  const resp = await axios.get(
    `${configService.apiServerBaseUrl()}/api/Meeting/Files/${fileId}`,
    buildRequestConfig("application/octet-stream", "blob")
  );
  const blob = new Blob([resp.data]);
  if (isText) {
    return cleanUrlText(await blob.text());
  }
  const url = URL.createObjectURL(blob);
  store.dispatch("wb/files/addCachedFile", { uuid: fileId, url });
  return url;
}

function createCanvasFile(
  size: Size,
  pages: number,
  fileId: string,
  pdfFileId: string,
  id: string,
  name: string,
  details: FileDetails
): CanvasFile {
  const type = getFileTypeByName(name);
  const manipulation = getFileManipulation(size);
  return {
    uuid: id,
    fileId: fileId,
    pdfFileId: pdfFileId,
    name: name || "file",
    meetingId: store.getters["canvas/shortMeeting"].uuid,
    size: size,
    pagesStrokes: [],
    manipulation: {
      rotation: 0,
      x: manipulation.x,
      y: manipulation.y,
      scaleX: manipulation.scaleX,
      scaleY: manipulation.scaleY
    },
    type: type,
    currentPage: 1,
    filePagesQuantity: pages,
    details: details
  };
}

async function createBoardElement(
  size: Size,
  pages: number,
  fileId: string,
  pdfFileId: string,
  id: string,
  name: string,
  details: FileDetails,
  preloadedUrl = undefined as string | undefined | Blob
): Promise<BoardElement> {
  const canvasFile = createCanvasFile(
    size,
    pages,
    fileId,
    pdfFileId,
    id,
    name,
    details
  );
  return {
    image: await wbConversionService.File2KonvaNode(
      canvasFile,
      true,
      getFileTypeByName(name) == "image" ? preloadedUrl : undefined
    ),
    file: canvasFile,
    lines: []
  } as BoardElement;
}

async function doc2pdf(fileId: string): Promise<string> {
  const resp = await axios.get(
    `${configService.pdfServerBaseUrl()}/api/Service/ConvertToPdf/${fileId}`,
    buildRequestConfig()
  );
  return resp.data;
}

export async function getFileInfo(fileId: string): Promise<number> {
  const resp = await axios.get(
    `${configService.pdfServerBaseUrl()}/api/Service/GetFileInfo/${fileId}`,
    buildRequestConfig()
  );
  return resp.data.TotalPages;
}

export async function getPdfPageStrokes(
  fileId: string,
  pageNum: number
): Promise<Stroke[]> {
  const resp = await axios.get(
    `${
      store.getters["conference/authData"].meetingServerUrl
    }${configService.getPdfStrokesEndpoint()}/${fileId}/${pageNum - 1}`,
    buildRequestConfig()
  );
  return resp.data.map(StrokeDeserializer);
}

export function updatemageStrokes(
  image: BoardElement,
  newFile: Partial<CanvasFile>
): BoardElement {
  image.lines = image.lines.map(line => {
    if (newFile.manipulation) {
      line.offsetX((newFile.manipulation.x / newFile.manipulation.scaleX) * -1);
      line.offsetY((newFile.manipulation.y / newFile.manipulation.scaleY) * -1);
      line.scaleX(newFile.manipulation.scaleX);
      line.scaleY(newFile.manipulation.scaleY);
      line.rotation(newFile.manipulation.rotation);
    }
    return line;
  });
  return image;
}

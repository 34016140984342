











































































import Vue from "vue";
import VaButton from "@/components/atoms/VaButton.vue";
import { User } from "@/models/user.interface";
import { Room } from "@/models/room.interface";
import store from "@/store";
import ShareRoom from "@/components/organisms/ShareRoom.vue";
import PopupChat from "@/components/organisms/PopupChat.vue";
import ConferenceUsers from "@/components/organisms/ConferenceUsers.vue";
import Videoconference from "@/components/organisms/Videoconference.vue";
import { ConferenceAuthData } from "@/models/conferenceAuthData.interface";
import Whiteboard from "@/components/organisms/Whiteboard.vue";
import MetaHelper from "@/services/metaHelper";
import VaAvatar from "@/components/atoms/VaAvatar.vue";
import ToolbarLeft from "@/components/organisms/ToolbarLeft.vue";
import ConfirmDialog from "@/components/organisms/ConfirmDialog.vue";
import * as conferenceService from "@/services/conferenceSocketService";
import LightSwitcher from "@/components/organisms/LightSwitcher.vue";
import tenantService from "@/services/tenantService";
import iconcolorMixin from "@/mixins/iconcolorMixin";
import CreateUrlBtn from "@/components/organisms/CreateUrlBtn.vue";
import CreatePostItBtn from "@/components/organisms/CreatePostItBtn.vue";
import BackgroundTool from "@/components/organisms/BackgroundTool.vue";
import CreateTextboxBtn from "@/components/organisms/CreateTextboxBtn.vue";
import FileUploadBtn from "@/components/organisms/FileUploadBtn.vue";
import WhiteboardPageSelector from "@/components/organisms/WhiteboardPageSelector.vue";
import ZoomController from "@/components/organisms/ZoomController.vue";
import InviteUsers from "@/components/organisms/InviteUsers.vue";
import PopupPoll from "@/components/organisms/PopupPoll.vue";

export default Vue.extend({
  name: "Conference",
  components: {
    PopupPoll,
    InviteUsers,
    ZoomController,
    VaAvatar,
    PopupChat,
    VaButton,
    ConferenceUsers,
    ShareRoom,
    Videoconference,
    Whiteboard,
    ToolbarLeft,
    ConfirmDialog,
    LightSwitcher,
    CreateUrlBtn,
    CreatePostItBtn,
    BackgroundTool,
    CreateTextboxBtn,
    FileUploadBtn,
    WhiteboardPageSelector
  },
  mixins: [iconcolorMixin],
  meta() {
    const res = MetaHelper.buildMeta("conference");
    const room = this.$store.getters["room/activeRoom"];
    if (room) {
      res.title = room.name;
    }
    return res;
  },
  data: () => ({
    dragSelector: ".drag-container-1, .drag-container-2",
    pageMenu: false,
    sizeMenu: false,
    colorMenu: false,
    init: false
  }),
  props: {
    single: {
      type: Boolean,
      required: false,
      default: false
    },
    guest: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  beforeDestroy() {
    store.commit("conference/isDisconnected", false);
  },
  computed: {
    logo() {
      return tenantService.requireAsset("logo-square.svg");
    },
    guestEnabled(): boolean {
      return this.$store.getters["app/guestEnabled"];
    },
    authData(): ConferenceAuthData {
      return this.$store.getters["conference/authData"];
    },
    user(): User {
      return this.$store.getters["users/user"];
    },
    room(): Room {
      return this.$store.getters["room/activeRoom"];
    },
    socketDisconnected(): boolean {
      return this.$store.getters["conference/isDisconnected"];
    },
    isWriter(): boolean {
      return store.getters["conference/isWriter"];
    },
    pinnedCamera(): boolean {
      return (
        store.getters["videoConference/isPinned"] &&
        !store.getters["videoConference/isMinimized"]
      );
    }
  },
  watch: {
    user: async function() {
      if (
        !this.$store.getters["conference/initInvoked"] &&
        this.user &&
        this.room
      ) {
        await this.joinAsMember();
      }
    },
    room: async function() {
      if (
        !this.$store.getters["conference/initInvoked"] &&
        this.user &&
        this.room
      ) {
        await this.joinAsMember();
      }
    },
    socketDisconnected: function(newVal) {
      if (!newVal) return;
      store.commit("notifications/displayConfirmDialog", {
        visible: true,
        title: "Connection failed",
        description: "Your connection is failed, try to reconnect",
        ok: "",
        ko: "",
        callback: async () => {
          await conferenceService.reconnect(store.getters["conference/socket"]);
        }
      });
    }
  },
  mounted() {
    if (!this.$store.getters["conference/initInvoked"]) {
      if (this.guest) {
        //try recover conference for guests
        if (this.authData) {
          this.joinAsGuest();
        } else {
          this.$router.push(`/join`);
        }
      } else {
        if (this.$store.getters["room/activeRoom"]) {
          this.joinAsMember();
        }
      }
    }
  },
  methods: {
    async joinAsMember() {
      store.dispatch("chat/init");

      await store.dispatch("conference/init", {
        meetingId: this.room.roomInfo?.shortId || "",
        pin:
          this.room.roomInfo?.moderatorPin.toString() ||
          this.room.roomInfo?.pin.toString() ||
          "",
        nickname: this.user?.userNickname || "webApp"
      });
    },
    async joinAsGuest() {
      store.dispatch("chat/init");
      await store.dispatch("conference/init", {
        meetingId: this.authData.meetingId,
        pin: this.authData.pin,
        nickname: this.authData.user.userNickname || "guest"
      });
    },
    async leaveConference() {
      // await store.dispatch("wb/files/unfocusImage");
      await store.dispatch("canvas/unMount");
      store.dispatch("conference/stop");
      if (this.room) {
        this.$router.push(`/room`);
      } else {
        this.$router.push(
          `/join?meetingId=${this.authData.meetingId}&pin=${this.authData.pin}`
        );
      }
    }
  }
});






































import Vue from "vue";
import VaButton from "@/components/atoms/VaButton.vue";
import Chat from "@/components/organisms/Chat.vue";
import Logs from "@/components/organisms/Logs.vue";
import iconcolorMixin from "@/mixins/iconcolorMixin";

export default Vue.extend({
  components: {
    VaButton,
    Chat,
    Logs
  },
  mixins: [iconcolorMixin],
  data: () => ({
    displayChat: false
  })
});

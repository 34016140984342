var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Va-Chip")]),
      _c("h3", [_vm._v("color")]),
      _c("v-divider", { staticClass: "mb-4" }),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between  my-4" },
        [
          _c("va-chip", { attrs: { text: "chip" } }),
          _c("va-chip", { attrs: { text: "chip", color: "primary" } }),
          _c("va-chip", { attrs: { text: "chip", color: "secondary" } }),
          _c("va-chip", { attrs: { text: "chip", color: "success" } }),
          _c("va-chip", { attrs: { text: "chip", color: "warning" } }),
          _c("va-chip", { attrs: { text: "chip", color: "error" } }),
          _c("va-chip", {
            attrs: { text: "chip", outline: "", color: "primary" }
          }),
          _c("va-chip", {
            attrs: { text: "chip", outline: "", color: "secondary" }
          }),
          _c("va-chip", {
            attrs: { text: "chip", outline: "", color: "success" }
          }),
          _c("va-chip", {
            attrs: { text: "chip", outline: "", color: "warning" }
          }),
          _c("va-chip", {
            attrs: { text: "chip", outline: "", color: "error" }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between  my-4" },
        [
          _c("va-chip", { attrs: { text: "chip", color: "primary" } }),
          _c("va-chip", {
            attrs: { text: "chip", color: "primary lighten-1" }
          }),
          _c("va-chip", {
            attrs: { text: "chip", color: "primary lighten-2" }
          }),
          _c("va-chip", {
            attrs: { text: "chip", color: "primary lighten-3" }
          }),
          _c("va-chip", {
            attrs: { text: "chip", color: "primary lighten-4" }
          }),
          _c("va-chip", { attrs: { text: "chip", color: "primary darken-1" } }),
          _c("va-chip", { attrs: { text: "chip", color: "primary darken-2" } }),
          _c("va-chip", { attrs: { text: "chip", color: "primary darken-3" } }),
          _c("va-chip", { attrs: { text: "chip", color: "primary darken-4" } })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between  my-4" },
        [
          _c("va-chip", { attrs: { text: "chip", color: "purple" } }),
          _c("va-chip", {
            attrs: { text: "chip", color: "purple", "text-color": "secondary" }
          }),
          _c("va-chip", {
            attrs: { text: "chip", color: "purple", "text-color": "green" }
          }),
          _c("va-chip", {
            attrs: { text: "chip", outline: "", color: "purple" }
          }),
          _c("va-chip", {
            attrs: {
              text: "chip",
              outline: "",
              color: "purple",
              "text-color": "secondary"
            }
          }),
          _c("va-chip", {
            attrs: {
              text: "chip",
              outline: "",
              color: "purple",
              "text-color": "green"
            }
          })
        ],
        1
      ),
      _c("h3", [_vm._v("size")]),
      _c("v-divider", { staticClass: "mb-4" }),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between  my-4" },
        [
          _c("va-chip", {
            attrs: { color: "primary", text: "qui", size: "xs" }
          }),
          _c("va-chip", {
            attrs: { color: "primary", text: "quo", size: "s" }
          }),
          _c("va-chip", {
            attrs: { color: "primary", text: "qua", size: "m" }
          }),
          _c("va-chip", {
            attrs: { color: "primary", text: "paperino", size: "l" }
          }),
          _c("va-chip", {
            attrs: { color: "primary", text: "topolino", size: "xl" }
          }),
          _c("va-chip", {
            attrs: { color: "primary", text: "cip", outline: "", size: "xs" }
          }),
          _c("va-chip", {
            attrs: { color: "primary", text: "ciop", outline: "", size: "s" }
          }),
          _c("va-chip", {
            attrs: {
              color: "primary",
              text: "paperino",
              outline: "",
              size: "m"
            }
          }),
          _c("va-chip", {
            attrs: {
              color: "primary",
              text: "topolino",
              outline: "",
              size: "l"
            }
          }),
          _c("va-chip", {
            attrs: {
              color: "primary",
              text: "paperino topolino",
              outline: "",
              size: "xl"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
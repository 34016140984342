var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "btn__chat__holder" },
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    _vm._g({}, on),
                    [
                      _c(
                        "va-button",
                        {
                          attrs: {
                            color: _vm.displayChat ? "primary" : _vm.iconColor,
                            type: "squareText"
                          },
                          on: {
                            click: function($event) {
                              _vm.displayChat = !_vm.displayChat
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("va va-chat")])],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("rooms.chat")))])]
      ),
      _vm.displayChat
        ? _c(
            "div",
            {
              staticClass:
                "border-a border-ra background-base pa-4 bottom-left chat-box elevation-16",
              attrs: { id: "popup__chat" }
            },
            [
              _c(
                "v-tabs",
                { attrs: { "fixed-tabs": "" } },
                [
                  _c("v-tab", [_vm._v(_vm._s(_vm.$t("rooms.chat")))]),
                  _c("v-tab", [_vm._v(_vm._s(_vm.$t("rooms.log")))]),
                  _c("v-tab-item", { staticClass: "pt-6" }, [_c("chat")], 1),
                  _c("v-tab-item", { staticClass: "pt-6" }, [_c("logs")], 1)
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
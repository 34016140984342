











import Vue from "vue";
import VaButton from "@/components/atoms/VaButton.vue";
import store from "@/store";
import iconcolorMixin from "@/mixins/iconcolorMixin";

export default Vue.extend({
  components: {
    VaButton
  },
  mixins: [iconcolorMixin],
  props: {
    hideBorder: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    light: false
  }),
  computed: {
    icon(): string {
      return this.$store.getters["app/darkTheme"]
        ? "va va-lightbulb"
        : "va va-lightbulb-fill";
    }
  },
  methods: {
    toggleLight() {
      store.commit("app/toggleDarkTheme");
    }
  }
});

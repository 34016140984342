import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex";
import { RootState } from "./index";
import "@/plugins/vueCookies";
import logger from "@/services/loggerService";
import * as webRtcService from "@/services/webRTCService";
import { VideoConferenceOptions } from "@/models/videoConferenceOptions.interface";
import * as configService from "@/services/configService";

export interface VideoConferenceState {
  isLoading: boolean;
  isRunning: boolean;
  options: VideoConferenceOptions | undefined;
  webRtcInstance: unknown;
  isPinned: boolean;
  isMinimized: boolean;
}

type SessionContext = ActionContext<VideoConferenceState, RootState>;

export const namespaced = true;

export const state = (): VideoConferenceState => ({
  isLoading: false,
  isRunning: false,
  options: undefined,
  webRtcInstance: undefined,
  isPinned: true,
  isMinimized: true
});

export const getters: GetterTree<VideoConferenceState, RootState> = {
  isLoading: (state): boolean => {
    return state.isLoading;
  },
  isRunning: (state): boolean => {
    return state.isRunning;
  },
  isPinned: (state): boolean => {
    return state.isPinned;
  },
  isMinimized: (state): boolean => {
    return state.isMinimized;
  }
};

export const mutations: MutationTree<VideoConferenceState> = {
  setLoading(state: VideoConferenceState, val: boolean) {
    state.isLoading = val;
  },
  setRunning(state: VideoConferenceState, val: boolean) {
    state.isRunning = val;
  },
  setPinned(state: VideoConferenceState, val: boolean) {
    state.isPinned = val;
  },
  setMinimized(state: VideoConferenceState, val: boolean) {
    if (state.isRunning) {
      val = false;
    }
    state.isMinimized = val;
  },
  setOptions(
    state: VideoConferenceState,
    options: { domain: string; containerId: string; jitsiOptions: unknown }
  ) {
    state.options = options;
  },
  setWebRtcInstance(state: VideoConferenceState, webRtc: unknown) {
    state.webRtcInstance = webRtc;
  }
};

export const actions: ActionTree<VideoConferenceState, RootState> = {
  async init(
    context: SessionContext,
    data: {
      containerId: string;
      conferenceId: string;
      userNickName: string;
      userEmail: string;
      roomSubject: string;
    }
  ) {
    context.commit("setLoading", true);
    try {
      const options = {
        domain: configService.webRtcBaseUrl || "",
        containerId: data.containerId,
        jitsiOptions: webRtcService.createOptions(
          data.conferenceId,
          data.containerId,
          data.userNickName,
          data.userEmail,
          false,
          false,
          data.roomSubject
        )
      };
      context.commit("setOptions", options);

      const webRtcInstance = await webRtcService.connect(options);
      context.commit("setWebRtcInstance", webRtcInstance);
      context.commit("setRunning", true);
    } catch (err) {
      logger.error(err);
      context.commit("setRunning", false);
    } finally {
      context.commit("setLoading", false);
    }
  },
  async stop(context: SessionContext) {
    if (context.state.webRtcInstance) {
      webRtcService.dispose(context.state.webRtcInstance);
      context.commit("setWebRtcInstance", undefined);
      context.commit("setRunning", false);
    }
  }
};

import { User, UserDeserializer } from "@/models/user.interface";
import i18n from "@/plugins/lang";

export type messageTypes =
  | "GroupCreateOrUpdated"
  | "ChatMeetingUpdated"
  | "OpenWebConference"
  | "OpenRoom"
  | "CloseWebConference"
  | "CloseRoom"
  | "CustomNotification"
  | "AddUserToGroup"
  | "RemoveUserFromGroup"
  | "UserLeaveGroup"
  | "Message"
  | "Error"
  | "Info";

export interface ChatMessage {
  creationDate?: Date;
  destinationId: string;
  isDeleted?: boolean;
  isGroup?: boolean;
  isReadInOtherDevice?: boolean;
  message: string;
  messageId: string;
  messageType: messageTypes;
  notificationType: "success" | "info" | "warning" | "error";
  user: User;
}

function logMessagesDecoder(
  code: number,
  message: string
): {
  description: string;
  messageType: messageTypes;
  notificationType: "success" | "info" | "warning" | "error";
} {
  switch (code) {
    case 110:
      return {
        description:
          i18n.t("messageTypes.110").toString() || "OpenWebConference",
        messageType: "OpenWebConference",
        notificationType: "success"
      };
    case 120:
      return {
        description: i18n.t("messageTypes.120").toString() || "OpenRoom",
        messageType: "OpenRoom",
        notificationType: "success"
      };
    case 130:
      return {
        description:
          i18n.t("messageTypes.130").toString() || "CloseWebConference",
        messageType: "CloseWebConference",
        notificationType: "info"
      };
    case 140:
      return {
        description: i18n.t("messageTypes.140").toString() || "CloseRoom",
        messageType: "CloseRoom",
        notificationType: "info"
      };
    case 200:
      return {
        description: i18n.t("messageTypes.200").toString() || "AddUserToGroup",
        messageType: "AddUserToGroup",
        notificationType: "info"
      };
    case 300:
      return {
        description:
          i18n.t("messageTypes.300").toString() || "RemoveUserFromGroup",
        messageType: "RemoveUserFromGroup",
        notificationType: "warning"
      };
    case 400:
      return {
        description: i18n.t("messageTypes.400").toString() || "UserLeaveGroup",
        messageType: "UserLeaveGroup",
        notificationType: "warning"
      };
    case 100:
      return {
        description:
          i18n.t("messageTypes.100").toString() || "GroupCreateOrUpdated",
        messageType: "GroupCreateOrUpdated",
        notificationType: "info"
      };
    case 800:
      return {
        description: message,
        messageType: "Info",
        notificationType: "info"
      };
    case 500:
      return {
        description: message,
        messageType: "Message",
        notificationType: "info"
      };
    default:
      return {
        description: message,
        messageType: "Message",
        notificationType: "info"
      };
  }
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function ChatMessageDeserializer(input: any): ChatMessage {
  const user = UserDeserializer(input.User);
  const decodedMsg = logMessagesDecoder(input.MessageType, input.Message || "");
  return {
    creationDate: new Date(input.CreationDate),
    destinationId: input.DestinationId || "",
    isDeleted: input.IsDeleted,
    isGroup: input.IsGroup,
    isReadInOtherDevice: input.IsReadInOtherDevice,
    message: decodedMsg.description,
    messageId: input.MessageId || "",
    messageType: decodedMsg.messageType,
    notificationType: decodedMsg.notificationType,
    user: user
  };
}

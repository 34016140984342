





























































































import Vue from "vue";
import CenterContent from "@/components/templates/CenterContent.vue";
import { Room } from "@/models/room.interface";
import VaIcon from "@/components/atoms/VaIcon.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import RoomMembers from "@/components/organisms/RoomMembers.vue";
import Logs from "@/components/organisms/Logs.vue";
import store from "@/store";
import VaTextField from "@/components/atoms/VaTextField.vue";
import AvatarUpload from "@/components/molecules/AvatarUpload.vue";
import validationMixin from "@/mixins/validationMixin";
import i18n from "@/plugins/lang";
import lodash from "lodash";
import { User } from "@/models/user.interface";
import tenantService from "@/services/tenantService";
import VaLoader from "@/components/atoms/VaLoader.vue";
// import VaTextArea from "@/components/atoms/VaTextArea.vue";

export default Vue.extend({
  name: "RoomDetail",
  components: {
    VaLoader,
    CenterContent,
    VaIcon,
    VaButton,
    RoomMembers,
    Logs,
    VaTextField,
    AvatarUpload
    // VaTextArea,
  },
  mixins: [validationMixin],
  data: () => ({
    valid: true,
    nameRules: [(value: string) => !!value || i18n.t("validations.required")],
    avatarFile: undefined as File | undefined
  }),
  computed: {
    bgLight() {
      return tenantService.requireBackground("room-waiting.jpg");
    },
    bgDark() {
      return tenantService.requireBackground("room-waiting-dark.jpg");
    },
    room(): Room {
      return lodash.cloneDeep(this.$store.getters["room/activeRoom"]);
    },
    isManager(): boolean {
      const me = this.$store.getters["users/user"];
      return (
        (!!me &&
          this.room.users?.some((user: User) => {
            return user?.isAdmin && user?.userId === me.userId;
          })) ||
        false
      );
    }
  },
  methods: {
    leaveRoom(room: Room) {
      this.$store.commit("notifications/displayConfirmDialog", {
        visible: true,
        title: i18n.t("rooms.leaving"),
        description: `${i18n.t("rooms.confirmLeaving")} ${room.name}`,
        callback: () => {
          this.$store.dispatch("room/leaveRoom", room);
          this.$router.push(`/room`);
        }
      });
    },
    editRoom(room: Room): void {
      this.$store.dispatch("room/editRoom", room);
      const activeRoom = store.getters["room/activeRoom"];
      if (activeRoom && activeRoom.groupId == room.groupId) {
        this.$store.commit("room/setActiveRoom", room);
      }
      if (this.avatarFile) {
        this.$store.dispatch("room/updateRoomAvatar", {
          file: this.avatarFile,
          room: room
        });
      }
    },
    onAvatarChanged(file: File) {
      this.avatarFile = file;
    }
  }
});

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex my-4" }, [
      _c("div", { staticClass: "mr-6" }, [
        _c("h1", [_vm._v("heading 1")]),
        _c("h2", [_vm._v("heading 2")]),
        _c("h3", [_vm._v("heading 3")]),
        _c("h4", [_vm._v("heading 4")]),
        _c("h5", [_vm._v("heading 5")]),
        _c("h6", [_vm._v("heading 6")])
      ]),
      _c("div", [
        _c("div", { staticClass: "subtitle-1" }, [_vm._v("subtitle-1")]),
        _c("div", { staticClass: "subtitle-2" }, [_vm._v("subtitle-2")]),
        _c("div", { staticClass: "body-1" }, [_vm._v("body-1")]),
        _c("div", { staticClass: "body-2" }, [_vm._v("body-2")]),
        _c("div", { staticClass: "button" }, [_vm._v("button")]),
        _c("div", { staticClass: "caption" }, [_vm._v("caption")]),
        _c("div", { staticClass: "overline" }, [_vm._v("overline")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    _vm._g({}, on),
                    [
                      _c(
                        "va-button",
                        {
                          attrs: { color: _vm.iconColor, type: "squareText" },
                          on: {
                            click: function($event) {
                              _vm.roomDialog = true
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("va va-computer-upload")])],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("rooms.sendToPod")))])]
      ),
      _c(
        "v-dialog",
        {
          attrs: { "content-class": "fullscreen" },
          model: {
            value: _vm.roomDialog,
            callback: function($$v) {
              _vm.roomDialog = $$v
            },
            expression: "roomDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "d-flex" },
                [
                  _c(
                    "div",
                    { staticClass: "flex-grow-1" },
                    [
                      _c("va-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: "va va-computer-upload", size: "m" }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("rooms.sendToPod")) + " ")
                    ],
                    1
                  ),
                  _c("va-button", {
                    attrs: {
                      color: _vm.iconColor,
                      type: "squareText",
                      icon: "va va-close-circle"
                    },
                    on: { click: _vm.onClose }
                  })
                ],
                1
              ),
              _c("v-card-text", { staticClass: "py-4" }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-center justify-center mb-6" },
                  [
                    _c("va-avatar", {
                      staticClass: "mr-3",
                      attrs: {
                        image:
                          _vm.room && _vm.room.avatar
                            ? _vm.room.avatar
                            : _vm.logo,
                        text: "conference room"
                      }
                    }),
                    _vm.room
                      ? _c("h2", [_vm._v(_vm._s(_vm.room.name))])
                      : _vm._e()
                  ],
                  1
                ),
                _c("div", { staticClass: "text-center mb-6" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("rooms.sendToPodDescription")) + " "
                  )
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "v-form",
                      {
                        ref: "shareRoomForm",
                        attrs: {
                          "lazy-validation": "",
                          onSubmit: "return false;"
                        },
                        on: { submit: _vm.onShareRoom },
                        model: {
                          value: _vm.valid,
                          callback: function($$v) {
                            _vm.valid = $$v
                          },
                          expression: "valid"
                        }
                      },
                      [
                        _c("va-text-field", {
                          attrs: {
                            mask: "NNN-NNN",
                            label: _vm.$t("rooms.roomCode"),
                            rules: [_vm.required],
                            disabled: _vm.$store.getters["room/isTransfering"]
                          },
                          on: { keyup: _vm.uppercase },
                          model: {
                            value: _vm.connectPin,
                            callback: function($$v) {
                              _vm.connectPin = $$v
                            },
                            expression: "connectPin"
                          }
                        }),
                        _c(
                          "div",
                          { staticClass: "d-flex mt-6 mb-4" },
                          [
                            _c("va-button", {
                              staticClass: "flex-grow-1",
                              attrs: {
                                loading:
                                  _vm.$store.getters["room/isTransfering"],
                                color: "primary",
                                cta: _vm.$t("rooms.sendToPod"),
                                icon: "va va-computer-upload",
                                submit: "",
                                disabled: _vm.connectPin.length < 6
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "va-notification",
        {
          attrs: { type: "success" },
          model: {
            value: _vm.successNotification,
            callback: function($$v) {
              _vm.successNotification = $$v
            },
            expression: "successNotification"
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("rooms.copySuccess")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
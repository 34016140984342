var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-autocomplete", {
    attrs: {
      items: _vm.entries,
      loading: _vm.isLoading,
      "search-input": _vm.search,
      outlined: "",
      "hide-no-data": "",
      "hide-selected": "",
      clearable: "",
      label: _vm.$t("rooms.searchUser"),
      placeholder: _vm.$t("rooms.autocompleteUser"),
      "clear-icon": "va va-close-circle"
    },
    on: {
      "update:searchInput": function($event) {
        _vm.search = $event
      },
      "update:search-input": function($event) {
        _vm.search = $event
      },
      "click:clear": function($event) {
        return _vm.$store.commit("users/clearSearchResults")
      }
    },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function(data) {
          return [
            _c(
              "div",
              {
                staticClass: "d-flex autocomplete-option",
                on: {
                  click: function($event) {
                    return _vm.onSelect(data.item.value)
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "flex-grow-1" },
                  [
                    _c("va-badge", {
                      attrs: {
                        image: data.item.value.avatar,
                        text: data.item.text
                      }
                    })
                  ],
                  1
                ),
                _vm.icon
                  ? _c("va-button", {
                      attrs: {
                        color: "secondary",
                        type: "square",
                        icon: _vm.icon
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        }
      }
    ]),
    model: {
      value: _vm.currentUser,
      callback: function($$v) {
        _vm.currentUser = $$v
      },
      expression: "currentUser"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
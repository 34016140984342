




























































import Vue from "vue";
import VaIcon from "@/components/atoms/VaIcon.vue";
import MetaHelper from "@/services/metaHelper";
import validationMixin from "@/mixins/validationMixin";
import CenterContent from "@/components/templates/CenterContent.vue";
import tenantService from "@/services/tenantService";
import VaAvatar from "@/components/atoms/VaAvatar.vue";
import SignInForm from "@/components/organisms/SignInForm.vue";
import roomBackgroundMixin from "@/mixins/roomBackgroundMixin";

export default Vue.extend({
  name: "SignIn",
  components: {
    SignInForm,
    CenterContent,
    VaIcon,
    VaAvatar
  },
  mixins: [validationMixin, roomBackgroundMixin],
  meta() {
    return MetaHelper.buildMeta("signIn");
  },
  data: () => ({
    email: "",
    pass: "",
    loginDialog: false,
    valid: true
  }),
  computed: {
    signupEnabled() {
      return (
        !process.env.VUE_APP_SIGNUP_DISABLED ||
        process.env.VUE_APP_SIGNUP_DISABLED === "false"
      );
    },
    logo() {
      return tenantService.requireAsset("logo-square.svg");
    },
    guestEnabled(): boolean {
      return this.$store.getters["app/guestEnabled"];
    }
  },
  methods: {
    onJoin() {
      if (
        (this.$refs.signInForm as Vue & { validate: () => boolean }).validate()
      ) {
        this.$store.dispatch("session/login", {
          username: this.email,
          password: this.pass
        });
      }
    }
  }
});

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.token
        ? [
            _c("h2", [_vm._v(_vm._s(_vm.$t("mfa.signInTitle")))]),
            _c("div", { staticClass: "mt-2 mb-8" }, [
              _vm._v(_vm._s(_vm.$t("mfa.signInDescription")))
            ])
          ]
        : _vm._e(),
      _vm.enabledProviders.length && !_vm.selectedProvider
        ? _c(
            "div",
            [
              _c("h3", { staticClass: "mb-2" }, [
                _vm._v(_vm._s(_vm.$t("mfa.choseProvider")))
              ]),
              _vm._l(_vm.enabledProviders, function(item) {
                return _c(
                  "div",
                  { key: item.type, staticClass: "mb-4" },
                  [
                    _c("va-button", {
                      attrs: {
                        cta: item.name,
                        loading: _vm.$store.getters["mfa/isLoading"]
                      },
                      on: {
                        click: function($event) {
                          return _vm.$store.dispatch("mfa/selectProvider", item)
                        }
                      }
                    })
                  ],
                  1
                )
              })
            ],
            2
          )
        : _vm._e(),
      _vm.enabledProviders.length && _vm.selectedProvider
        ? _c(
            "div",
            [
              _c("h3", { staticClass: "mb-2" }, [
                _vm._v(_vm._s(_vm.selectedProvider.name))
              ]),
              _c(
                "v-form",
                {
                  attrs: { "lazy-validation": "", onSubmit: "return false;" },
                  on: { submit: _vm.onSubmit },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _c("va-text-field", {
                        attrs: {
                          rules: [_vm.required],
                          dense: "",
                          label: _vm.$t("mfa.insertCode")
                        },
                        model: {
                          value: _vm.verificationCode,
                          callback: function($$v) {
                            _vm.verificationCode = $$v
                          },
                          expression: "verificationCode"
                        }
                      }),
                      _c("va-button", {
                        staticClass: "ml-4",
                        attrs: {
                          loading: _vm.$store.getters["session/isLoading"],
                          disabled: !_vm.valid || _vm.verificationCode === "",
                          cta: _vm.$t("signin.cta")
                        },
                        on: { click: _vm.onSubmit }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "wrapperCentered d-flex flex-column justify-space-between",
      style: _vm.backgroundStyles
    },
    [
      _c("LightSwitcher", { staticClass: "top-right mr-6 mt-6" }),
      _c("div", { staticClass: "wrapper mx-auto" }, [
        _c("div", { staticClass: "text-center mb-6" }, [
          _c("img", {
            staticClass: "logo",
            attrs: { alt: "Logo", src: _vm.logo }
          })
        ]),
        _c(
          "div",
          { staticClass: "main-content rounded" },
          [_vm._t("default")],
          2
        ),
        this.$slots.bottomContent
          ? _c("div", {}, [_vm._t("bottomContent")], 2)
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
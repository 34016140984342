import store from "@/store";
import { AxiosRequestConfig, ResponseType } from "axios";

export const buildRequestConfig = (
  contentType?: string,
  responseType?: ResponseType
): AxiosRequestConfig => {
  return {
    headers: {
      Authorization: `Bearer ${store.getters["conference/authData"].token}`,
      "Content-Type": contentType
    },
    responseType: responseType
  };
  // return config;
};

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    _vm._g({}, on),
                    [
                      _c(
                        "va-button",
                        {
                          attrs: {
                            color: _vm.minimized ? _vm.iconColor : "primary",
                            type: "squareText"
                          },
                          on: { click: _vm.toggleConferenceBox }
                        },
                        [_c("v-icon", [_vm._v("va va-videocamera")])],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("rooms.videoConference")))])]
      ),
      !_vm.minimized
        ? _c(
            "div",
            {
              class: "" + (_vm.pinnedCamera ? "pinnedCamera" : "bottom-right"),
              attrs: { id: "popup__conference" }
            },
            [
              !_vm.shouldWaitForHost
                ? _c(
                    "vue-resizable",
                    {
                      attrs: { active: [], dragSelector: ".drag-container-1" }
                    },
                    [
                      _c("div", { staticClass: "controllers-wrapper" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-space-between mb-n9" },
                          [
                            _c("va-button", {
                              staticClass: "drag-container-1 cursor-pointer",
                              attrs: {
                                color: "secondary",
                                type: "squareText",
                                icon: "va va-drag-21"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$store.commit(
                                    "videoConference/setPinned",
                                    false
                                  )
                                }
                              }
                            }),
                            _c("va-button", {
                              staticClass: "cursor-pointer",
                              attrs: {
                                color: "secondary",
                                type: "squareText",
                                icon: _vm.dockIcon
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$store.commit(
                                    "videoConference/setPinned",
                                    !_vm.pinnedCamera
                                  )
                                }
                              }
                            }),
                            _c("v-spacer", { staticClass: "flex-grow-1" }),
                            !_vm.videoConfStarted
                              ? _c("va-button", {
                                  attrs: {
                                    color: "secondary",
                                    type: "squareText",
                                    icon: "va va-collapse"
                                  },
                                  on: { click: _vm.toggleConferenceBox }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            ref: "videoconference",
                            staticClass:
                              "conference-wrapper text-center border-a border-ra"
                          },
                          [
                            !_vm.videoConfStarted && !_vm.loading
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "buttons-container d-flex flex-column justify-center align-center mt-n3"
                                  },
                                  [
                                    _c("div", { staticClass: "mx-auto " }, [
                                      _c("img", {
                                        attrs: {
                                          alt: "logo",
                                          height: "120",
                                          src: _vm.logo
                                        }
                                      })
                                    ]),
                                    _c("va-button", {
                                      staticClass: " mx-auto",
                                      attrs: {
                                        color: "primary",
                                        disabled: _vm.shouldWaitForHost,
                                        cta: _vm.shouldWaitForHost
                                          ? _vm.$t("conference.waiting")
                                          : _vm.$t("conference.join"),
                                        icon: "va va-on-off"
                                      },
                                      on: { click: _vm.startConference }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("div", {
                              attrs: { id: "video-conference-container" }
                            })
                          ]
                        )
                      ])
                    ]
                  )
                : _c("div", [
                    _c(
                      "div",
                      { staticClass: "secondary-outline rounded pa-3 d-flex" },
                      [
                        _c("va-loader", {
                          staticClass: "mr-4",
                          attrs: {
                            "is-loading": !_vm.videoConfStarted,
                            size: "s"
                          }
                        }),
                        _vm._v(" " + _vm._s(_vm.$t("conference.waiting")) + " ")
                      ],
                      1
                    )
                  ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
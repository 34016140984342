import { CanvasFile } from "@/models/wb/canvasFile.interface";
import * as wbFileService from "@/services/wb/wbFileService";

export async function GetImageElementDataUrl(
  file: CanvasFile
): Promise<string> {
  if (file.name.includes(".svg")) {
    const res = await wbFileService.fetchFile(file.fileId, true);
    return "data:image/svg+xml;base64," + btoa(res);
  }
  return await wbFileService.fetchFile(file.fileId);
}

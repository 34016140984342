import {
  PollResponse,
  PollResponseDeserializer
} from "@/models/poll/response.interface";

enum questionTypesDecoder {
  "openAnswer",
  "trueFalse",
  "multipleChoice",
  "multipleAnswer",
  "pollOpinion"
  // "example" = 11,
}

const possibleQuestionTypes = Object.keys(questionTypesDecoder).filter(v =>
  isNaN(Number(v))
);
export type QuestionType = typeof possibleQuestionTypes[number];

export interface Question {
  id: string; //ItemId
  question: string; //Question
  questionDescription: string; //QuestionDescription
  order: number; //Order
  type: QuestionType; //Type
  allowedResponse: PollResponse[]; //AllowedResponse
  answerId: string;
  answerMultiple: string[];
  answerText: string;
  isMultiple: boolean;
  isOpen: boolean;
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function QuestionDeserializer(input: any): Question {
  const allowedResponse = input.AllowedResponse.map(PollResponseDeserializer);
  // const suggestedAnswer =
  //   allowedResponse
  //     .filter((a: PollResponse) => a.isTrue)
  //     .map((a: PollResponse) => a.id)[0] || "";
  const type = questionTypesDecoder[input.Type];
  return {
    id: input.ItemId || "",
    question: input.Question || "",
    questionDescription: input.QuestionDescription || "",
    order: input.Order || 0,
    type: type,
    allowedResponse: allowedResponse,
    answerId: "",
    answerMultiple: [],
    answerText: "",
    isMultiple: type == "multipleAnswer",
    isOpen: type == "openAnswer"
  };
}

export function PollAnswerSerializer(q: Question): unknown {
  // const responseIds = q.type == "pollOpinion"? q.answerMultiple : [q.answerId];
  if (q.isOpen) {
    return {
      ItemId: q.id,
      Response: q.answerText
    };
  } else {
    return {
      ItemId: q.id,
      ResponseIds: q.isMultiple ? q.answerMultiple : [q.answerId]
    };
  }
}

//answer
// SetPollItemResponse
// Queste tre proprietà con l'iniziale maiuscola
// {
//   let itemId:String
//   var responseIds:[String]?
//   var response:String?
// }

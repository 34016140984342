import { render, staticRenderFns } from "./CreateTextboxBtn.vue?vue&type=template&id=1e6426b8&scoped=true&"
import script from "./CreateTextboxBtn.vue?vue&type=script&lang=ts&"
export * from "./CreateTextboxBtn.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e6426b8",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1e6426b8')) {
      api.createRecord('1e6426b8', component.options)
    } else {
      api.reload('1e6426b8', component.options)
    }
    module.hot.accept("./CreateTextboxBtn.vue?vue&type=template&id=1e6426b8&scoped=true&", function () {
      api.rerender('1e6426b8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/organisms/CreateTextboxBtn.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "center-content",
    {
      attrs: { "light-bg": _vm.bgLight, "dark-bg": _vm.bgDark },
      scopedSlots: _vm._u([
        {
          key: "headerContent",
          fn: function() {
            return [
              _c("div", { staticClass: "d-flex align-center pa-6" }, [
                _c(
                  "div",
                  {
                    staticClass: "d-flex cursor-pointer text-uppercase",
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/")
                      }
                    }
                  },
                  [
                    _c("va-icon", {
                      attrs: { icon: "va va-direction-left mr-4", size: "s" }
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("rooms.back")) + " ")
                  ],
                  1
                )
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "pa-6" },
        [
          _c("div", { staticClass: "d-flex mb-4" }, [
            _c("h2", { staticClass: "flex-grow-1" }, [
              _vm._v(_vm._s(_vm.$t("home.myRooms")))
            ]),
            _vm.canCreateRoom
              ? _c(
                  "div",
                  {
                    staticClass:
                      "primary--text font-weight-bold text-uppercase d-flex align-center cursor-pointer",
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/room/new")
                      }
                    }
                  },
                  [
                    _c("va-icon", {
                      attrs: {
                        icon: "va va-plus mr-2",
                        color: "primary",
                        size: "s"
                      }
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("rooms.newRoom")) + " ")
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("v-virtual-scroll", {
            attrs: {
              items: _vm.rooms,
              "item-height": "73",
              height: _vm.scrollerHeight
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      {
                        key: item.destinationId,
                        staticClass: "py-4 border-b d-flex align-center "
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "flex-grow-1 cursor-pointer",
                            on: {
                              click: function($event) {
                                return _vm.goToRoom(item)
                              }
                            }
                          },
                          [
                            _c("va-badge", {
                              attrs: { image: item.avatar, text: item.name }
                            })
                          ],
                          1
                        ),
                        _c("va-button", {
                          attrs: {
                            color: "secondary",
                            type: "squareText",
                            icon: "va va-cog-alt"
                          },
                          on: {
                            click: function($event) {
                              return _vm.editRoom(item)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
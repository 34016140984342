




















































import Vue from "vue";
import VaButton from "@/components/atoms/VaButton.vue";
import store from "@/store";
import { Room } from "@/models/room.interface";
import VaBadge from "@/components/molecules/VaBadge.vue";
import { User } from "@/models/user.interface";
import UserAutocomplete from "@/components/organisms/UserAutocomplete.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import validationMixin from "@/mixins/validationMixin";

export default Vue.extend({
  components: {
    VaTextField,
    UserAutocomplete,
    VaBadge,
    VaButton
  },
  mixins: [validationMixin],
  props: {
    isManager: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    valid: true
  }),
  computed: {
    room(): Room {
      return store.getters["room/activeRoom"];
    },
    isLoading(): boolean {
      return this.$store.getters["room/isLoading"];
    },
    scrollerHeight(): number {
      const room = store.getters["room/activeRoom"];
      return room.users.length < 4 ? room.users.length * 75 : 220;
    }
  },
  methods: {
    onRemoveUser(user: User) {
      store.commit("notifications/displayConfirmDialog", {
        visible: true,
        title: "Are you sure?",
        description: `You are removing ${user.firstName} ${user.lastName} from ${this.room.name}`,
        callback: () => {
          store.dispatch("room/removeUser", {
            room: this.room,
            userId: user.userId
          });
        }
      });
    },
    onAddUser(user: User) {
      store.dispatch("room/addUser", { room: this.room, user: user });
    },
    inviteByMail(mail: string) {
      store.dispatch("room/inviteByMail", { room: this.room, email: mail });
    }
  }
});

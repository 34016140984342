import { MutationTree, GetterTree, ActionContext, ActionTree } from "vuex";
import { RootState } from "./index";
import {
  getMfaProviders,
  getEnabledProviders,
  getProvidersChallenge,
  sendMFACodeAction
} from "@/services/authService";
import logger from "@/services/loggerService";
import { MfaProvider } from "@/models/mfaProvider";

export interface MFAState {
  providers: MfaProvider[];
  enabledProviders: MfaProvider[];
  selectedProvider: MfaProvider | undefined;
  isLoading: boolean;
}

type MFAContext = ActionContext<MFAState, RootState>;

export const namespaced = true;

export const state = (): MFAState => ({
  providers: [],
  enabledProviders: [],
  // providerToEnable: null,
  // mfaToken: "",
  selectedProvider: undefined,
  isLoading: false
});

export const getters: GetterTree<MFAState, RootState> = {
  providers: (state): MfaProvider[] => {
    return state.providers;
  },
  enabledProviders: (state): MfaProvider[] => {
    return state.enabledProviders;
  },
  selectedProvider: (state): MfaProvider | undefined => {
    return state.selectedProvider;
  },
  isLoading: (state): boolean => {
    return state.isLoading;
  }
};

export const mutations: MutationTree<MFAState> = {
  setLoading(state: MFAState, loading: boolean) {
    state.isLoading = loading;
  },

  setProviders(state: MFAState, providers: MfaProvider[]) {
    state.providers = providers;
  },
  setSelectedProvider(state: MFAState, provider: MfaProvider) {
    state.selectedProvider = provider;
  },

  setEnabledProviders(state: MFAState, providers: MfaProvider[]) {
    state.enabledProviders = providers;
  }
};

export const actions: ActionTree<MFAState, RootState> = {
  resetMfaFlow(context: MFAContext): void {
    context.commit("setEnabledProviders", []);
    context.commit("setSelectedProvider", undefined);
    context.commit("session/setMfaToken", "", { root: true });
    context.commit("setLoading", false);
  },

  async fetchAvailableProviders(context: MFAContext): Promise<void> {
    context.commit("setLoading", true);
    try {
      const providers = await getMfaProviders();
      context.commit("setProviders", providers);
    } catch (e) {
      logger.error(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async fetchEnabledProviders(context: MFAContext): Promise<void> {
    context.commit("setLoading", true);
    try {
      const providers = await getEnabledProviders();
      context.commit("setEnabledProviders", providers);
    } catch (e) {
      logger.error(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async selectProvider(
    context: MFAContext,
    provider: MfaProvider
  ): Promise<void> {
    context.commit("setLoading", true);
    try {
      const mfaToken = context.rootState.session.mfaToken;
      if (await sendMFACodeAction(provider, mfaToken)) {
        context.commit("setSelectedProvider", provider);
      }
    } catch (e) {
      logger.error(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async fetchProvidersChallenge(
    context: MFAContext,
    mfaToken: string
  ): Promise<void> {
    try {
      const providers = await getProvidersChallenge(mfaToken);
      context.commit("setEnabledProviders", providers);
    } catch (e) {
      logger.error(e);
    }
  }
};

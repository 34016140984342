import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex";
import { RootState } from "./index";
import * as RoomService from "@/services/roomService";
import "@/plugins/vueCookies";
import { ChatMessage } from "@/models/chatMessage.interface";
import logger from "@/services/loggerService";
import { chatService } from "@/services/chatSocketService";
import { Socket } from "@/models/socket.interface";
import { messageTypes } from "@/models/chatMessage.interface";

export interface ChatState {
  isLoading: boolean;
  messages: ChatMessage[];
  logs: ChatMessage[];
  socket: Socket | undefined;
  connectionReady: boolean;
}

type SessionContext = ActionContext<ChatState, RootState>;

export const namespaced = true;

export const state = (): ChatState => ({
  messages: [],
  logs: [],
  isLoading: false,
  socket: undefined,
  connectionReady: false
});

export const getters: GetterTree<ChatState, RootState> = {
  isLoading: (state): boolean => {
    return state.isLoading;
  },
  messages: (state): ChatMessage[] => {
    return state.messages;
  },
  logs: (state): ChatMessage[] => {
    return state.logs;
  },
  socket: (state): Socket | undefined => {
    return state.socket;
  },
  connectionReady: (state): boolean => {
    return state.connectionReady;
  }
};

export const mutations: MutationTree<ChatState> = {
  setLoading(state: ChatState, loading: boolean) {
    state.isLoading = loading;
  },
  setSocket(state: ChatState, socket: Socket) {
    state.socket = socket;
  },
  setMessages(state: ChatState, data: ChatMessage[]) {
    state.messages = data;
  },
  setLogs(state: ChatState, data: ChatMessage[]) {
    state.logs = data;
  },
  addMessage(state: ChatState, data: ChatMessage) {
    if (data.messageType === "Message") {
      state.messages.push(data);
    }
  },
  addLog(state: ChatState, data: ChatMessage) {
    if (data.messageType !== "Message") {
      state.logs.push(data);
    }
  },
  setConnectionReady(state: ChatState, ready: boolean) {
    state.connectionReady = ready;
  }
};

export const actions: ActionTree<ChatState, RootState> = {
  async init(context: SessionContext) {
    const authData = context.rootState.session.authData;
    if (authData) {
      if (context.state.socket) {
        await chatService.stopChatHubConnection(context.state.socket);
      }
      try {
        const socket = await chatService.initChatHubConnection(authData);
        context.commit("setSocket", socket);
      } catch (err) {
        logger.error(err);
      }
    }
  },
  async stop(context: SessionContext) {
    if (context.state.socket) {
      await chatService.stopChatHubConnection(context.state.socket);
    }
    context.commit("setSocket", undefined);
  },
  async sendLog(context: SessionContext, type: messageTypes) {
    const room = context.rootState.room.activeRoom;
    const socket = context.state.socket;
    if (room && socket) {
      try {
        await chatService.sendMessage(
          socket,
          type,
          "",
          room.destinationId,
          !!room.isGroup
        );
      } catch (err) {
        logger.error(err);
      }
    }
  },
  async sendMessage(context: SessionContext, message: string) {
    const room = context.rootState.room.activeRoom;
    const socket = context.state.socket;
    if (room && socket) {
      try {
        const chatMessage = {
          creationDate: new Date(),
          destinationId: room.destinationId,
          isGroup: room.isGroup,
          message: message,
          messageId: "",
          messageType: "Message",
          notificationType: "info",
          user: context.rootState.users.user
        };
        context.commit("addMessage", chatMessage);
        await chatService.sendMessage(
          socket,
          "Message",
          message,
          room.destinationId,
          !!room.isGroup
        );
      } catch (err) {
        logger.error(err);
      }
    }
  },
  receiveMessage(context: SessionContext, data: ChatMessage) {
    if (
      context.rootState.room.activeRoom?.destinationId == data.destinationId
    ) {
      if (data.messageType === "Message") {
        context.commit("addMessage", data);
      } else {
        context.commit("addLog", data);
        context.commit(
          "notifications/displayNotification",
          {
            message: `${data.user.userNickname}: ${data.message}`,
            type: data.notificationType
          },
          { root: true }
        );
      }
    }
  },
  async fetchMessages(context: SessionContext, roomId: string) {
    context.commit("setLoading", true);
    try {
      const messages = await RoomService.getRoomMessages(roomId);
      context.commit("setMessages", messages);
    } catch (err) {
      logger.error(err);
    } finally {
      context.commit("setLoading", false);
    }
  },
  async fetchLogs(context: SessionContext, roomId: string) {
    context.commit("setLoading", true);
    try {
      const messages = await RoomService.getRoomLogs(roomId);
      context.commit("setLogs", messages);
    } catch (err) {
      logger.error(err);
    } finally {
      context.commit("setLoading", false);
    }
  }
};

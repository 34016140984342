import { MutationTree, GetterTree } from "vuex";
import { RootState } from "./index";
import { Notification } from "@/models/notification.interface";
import { ConfirmDialog } from "@/models/confirmDialog.interface";

export interface NotificationState {
  notification: Notification | undefined;
  confirmDialog: ConfirmDialog;
}

// type NotificationsContext = ActionContext<NotificationState, RootState>;

export const namespaced = true;
const emptyDialog = {
  visible: false,
  title: "",
  description: "",
  ok: "",
  ko: "",
  callback: () => {
    return;
  }
};

export const state = (): NotificationState => ({
  notification: undefined,
  confirmDialog: emptyDialog
});

export const getters: GetterTree<NotificationState, RootState> = {
  notification: (state): Notification | undefined => {
    return state.notification;
  },
  confirmDialog: (state): ConfirmDialog | undefined => {
    return state.confirmDialog;
  }
};

export const mutations: MutationTree<NotificationState> = {
  displayNotification(state: NotificationState, notification: Notification) {
    state.notification = notification;
  },
  dismissNotification(state: NotificationState) {
    state.notification = undefined;
  },
  displayConfirmDialog(state: NotificationState, dialog: ConfirmDialog) {
    state.confirmDialog = dialog;
  },
  dismissConfirmDialog(state: NotificationState) {
    state.confirmDialog = emptyDialog;
  }
};

import {
  Question,
  QuestionDeserializer
} from "@/models/poll/question.interface";

export interface Poll {
  id: string; //PollId
  name: string; //Name
  description: string; //Description
  items: Question[]; //Items
  // createdByDeviceUniqueId: string; //CreatedByDeviceUniqueId
  // CreationDate
  // UpdateDate
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function PollDeserializer(input: any): Poll {
  return {
    id: input.PollId || "",
    name: input.Name || "",
    description: input.Description || "",
    items: input.Items.map(QuestionDeserializer)
  };
}
//
// export function ResponseSerializer(r: Response): unknown {
//   return {
//     ResponseId: r.id,
//     Response: r.response,
//     isTrue: r.isTrue
//   };
// }

export interface Size {
  width: number;
  height: number;
}

export function SizeDeserializer(input: string): Size {
  const strSizes = input.split(",");
  if (strSizes.length == 2) {
    return {
      width: parseInt(strSizes[0]),
      height: parseInt(strSizes[1])
    };
  } else {
    throw new Error(`SizeDeserializer error, malformed size string: ${input}`);
  }
}

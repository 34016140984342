











































































































import Vue from "vue";
import VaButton from "@/components/atoms/VaButton.vue";
import { Room } from "@/models/room.interface";
import VaAvatar from "@/components/atoms/VaAvatar.vue";
import store from "@/store";
import { downloadAppUr, webAppUrl, rmsBaseUrl } from "@/services/configService";
import VaNotification from "@/components/molecules/VaNotification.vue";
import i18n from "@/plugins/lang";
import tenantService from "@/services/tenantService";
import iconcolorMixin from "@/mixins/iconcolorMixin";
import UserAutocomplete from "@/components/organisms/UserAutocomplete.vue";
import { User } from "@/models/user.interface";
import VaTextField from "@/components/atoms/VaTextField.vue";
import validationMixin from "@/mixins/validationMixin";
import VaIcon from "@/components/atoms/VaIcon.vue";

export default Vue.extend({
  components: {
    VaIcon,
    VaTextField,
    UserAutocomplete,
    VaButton,
    VaAvatar,
    VaNotification
  },
  mixins: [iconcolorMixin, validationMixin],
  props: {},
  computed: {
    logo() {
      return tenantService.requireAsset("logo-square.svg");
    },
    room(): Room {
      return store.getters["room/activeRoom"];
    },
    meetingId(): string {
      const room = store.getters["room/activeRoom"];
      return room?.roomInfo?.shortId || "";
    },
    pin(): string {
      const room = store.getters["room/activeRoom"];
      return room?.roomInfo?.pin.toString() || "";
    },
    guestEnabled(): boolean {
      const room = store.getters["room/activeRoom"];
      return room?.roomInfo?.guestEnabled;
    }
  },
  data: () => ({
    roomDialog: false,
    successNotification: false,
    shareBtnColor: "secondary",
    shareBtnIcon: "va va-copy",
    inviteMail: ""
  }),
  methods: {
    onDismiss() {
      this.roomDialog = false;
      this.shareBtnColor = "secondary";
      this.shareBtnIcon = "va va-copy";
      this.inviteMail = "";
    },
    onOpen() {
      this.roomDialog = true;
      this.shareBtnColor = "secondary";
      this.shareBtnIcon = "va va-copy";
    },
    onCopyLink: function() {
      // let browserUrl = `${webAppUrl}/join?meetingId=${this.meetingId}&pin=${this.pin}`;
      const appUrl = this.room?.roomInfo?.sharedLink
        ? `${rmsBaseUrl()}${this.room.roomInfo.sharedLink}`
        : downloadAppUr;

      const roomName = this.room?.name ? this.room.name : "";
      const value =
        `${i18n.t("rooms.invitation.description")} ${roomName}\n\n` +
        `${i18n.t("rooms.invitation.app")}\n ${appUrl} \n\n` +
        `${i18n.t("rooms.invitation.browser")}\n ${webAppUrl}/join?meetingId=${
          this.meetingId
        }&pin=${this.pin} \n\n` +
        `${i18n.t("rooms.invitation.download")}\n ${downloadAppUr}`;
      navigator.clipboard.writeText(value);
      this.shareBtnColor = "success";
      this.shareBtnIcon = "va va-check";
      this.successNotification = true;
    },
    async onAddUser(user: User) {
      await store.dispatch("room/addUser", { room: this.room, user: user });
      this.onDismiss();
    },
    async inviteByMail(mail: string) {
      await store.dispatch("room/inviteByMail", {
        room: this.room,
        email: mail
      });
      this.onDismiss();
    }
  }
});

import logger from "@/services/loggerService";
import { v4 as uuidv4 } from "uuid";

const possibleMessageTypes = [
  "HubRequest",
  "HubResponse",
  "TrySendRequest",
  "SendResponse"
];
export type messageTypes = typeof possibleMessageTypes[number];

export interface MagoLinkMessage {
  type: messageTypes;
  id: string;
  key: string;
  attendResponse: boolean;
  isWaitingResponse: boolean;
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  data?: any;
}

export const buildRequestMsg = (
  key: string,
  data?: unknown,
  noResponse?: boolean
): MagoLinkMessage => {
  return {
    type: "TrySendRequest",
    id: uuidv4(),
    key: key,
    attendResponse: !noResponse,
    isWaitingResponse: true,
    data: data
  };
};

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function deserialize(input: any, type: messageTypes): MagoLinkMessage {
  return {
    type: type,
    id: input.requestId || "",
    key: input.requestKey || "",
    attendResponse: input.attendResponse || false,
    isWaitingResponse: false,
    data:
      input.responseData !== undefined ? input.responseData : input.requestData
  };
}

export function serialize(msg: MagoLinkMessage): unknown {
  const result = {
    requestId: msg.id,
    requestKey: msg.key,
    requestTime: new Date().toISOString(),
    attendResponse: msg.attendResponse
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  } as any;
  if (msg.data) {
    switch (msg.type) {
      case "HubRequest":
      case "TrySendRequest":
        result.requestData = msg.data;
        break;
      case "HubResponse":
      case "SendResponse":
        result.responseData = msg.data;
        break;
      default:
        logger.error(`Message deserializer: unhandled message type`);
    }
  }
  return result;
}

import { render, staticRenderFns } from "./Rooms.vue?vue&type=template&id=0798d46d&scoped=true&"
import script from "./Rooms.vue?vue&type=script&lang=ts&"
export * from "./Rooms.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0798d46d",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VVirtualScroll})


/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0798d46d')) {
      api.createRecord('0798d46d', component.options)
    } else {
      api.reload('0798d46d', component.options)
    }
    module.hot.accept("./Rooms.vue?vue&type=template&id=0798d46d&scoped=true&", function () {
      api.rerender('0798d46d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/pages/Rooms.vue"
export default component.exports
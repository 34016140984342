import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: localStorage.getItem("darkTheme") === "true",

    options: {
      customProperties: true
    },
    themes: {
      dark: {
        primary: {
          darken4: "#123381",
          darken3: "#1D49A0",
          darken2: "#2B63BF",
          darken1: "#3B80DF",
          base: "#69A6EB",
          lighten1: "#89BFF5",
          lighten2: "#89BFF5",
          lighten3: "#D8EEFD",
          lighten4: "#D8EEFD"
        },
        accent: "#e91e63",
        secondary: {
          darken4: "#646D79",
          darken3: "#7E8A9A",
          darken2: "#97A1AE",
          darken1: "#ACB4BE",
          base: "#BEC4CC",
          lighten1: "#CFD3D9",
          lighten2: "#DEE1E5",
          lighten3: "#ECEEF0",
          lighten4: "#FDFDFE"
        },
        success: {
          darken4: "#167930",
          darken3: "#239637",
          darken2: "#33B33E",
          darken1: "#47D147",
          base: "#7CE372",
          lighten1: "#A2F191",
          lighten2: "#C9FAB8",
          lighten3: "#E6FCDB",
          lighten4: "#E6FCDB",
          lighten5: "#E6FCDB"
        },
        info: "#3351F5",
        warning: {
          darken4: "#93700E",
          darken3: "#B79016",
          darken2: "#B79016",
          darken1: "#FFD52D",
          base: "#FFE261",
          lighten1: "#FFEB81",
          lighten2: "#FFF3AB",
          lighten3: "#FFFAD5",
          lighten4: "#FFFAD5",
          lighten5: "#FFFAD5"
        },
        error: {
          darken4: "#93193D",
          darken3: "#B72745",
          darken2: "#DB394C",
          darken1: "#FF4F54",
          base: "#FF827A",
          lighten1: "#FFA495",
          lighten2: "#FFC9B8",
          lighten3: "#FFE7DB",
          lighten4: "#FFE7DB",
          lighten5: "#FFE7DB"
        },
        background: {
          darken4: "#181C20",
          darken3: "#252A2F",
          darken2: "#2C3237",
          darken1: "#343A40",
          base: "#3A4047",
          lighten1: "#585D63",
          lighten2: "#75797E",
          lighten3: "#9DA0A3",
          lighten4: "#C4C6C8",
          lighten5: "#E7E8E9"
        }
      },
      light: {
        primary: {
          darken4: "#0B236B",
          darken3: "#123381",
          darken2: "#1D49A0",
          darken1: "#2B63BF",
          base: "#3B80DF",
          lighten1: "#69A6EB",
          lighten2: "#89BFF5",
          lighten3: "#89BFF5",
          lighten4: "#D8EEFD",
          lighten5: "#D8EEFD"
        },
        accent: "#3351F5",
        secondary: {
          darken4: "#3A4047",
          darken3: "#646D79",
          darken2: "#7E8A9A",
          darken1: "#97A1AE",
          base: "#ACB4BE",
          lighten1: "#BEC4CC",
          lighten2: "#CFD3D9",
          lighten3: "#DEE1E5",
          lighten4: "#ECEEF0",
          lighten5: "#FDFDFE"
        },
        success: {
          darken4: "#0D642B",
          darken3: "#167930",
          darken2: "#239637",
          darken1: "#33B33E",
          base: "#47D147",
          lighten1: "#7CE372",
          lighten2: "#A2F191",
          lighten3: "#C9FAB8",
          lighten4: "#E6FCDB",
          lighten5: "#E6FCDB"
        },
        info: "#3351F5",
        warning: {
          darken4: "#7A5A08",
          darken3: "#93700E",
          darken2: "#B79016",
          darken1: "#B79016",
          base: "#FFD52D",
          lighten1: "#FFE261",
          lighten2: "#FFEB81",
          lighten3: "#FFF3AB",
          lighten4: "#FFFAD5",
          lighten5: "#FFFAD5"
        },
        error: {
          darken4: "#7A0F37",
          darken3: "#93193D",
          darken2: "#B72745",
          darken1: "#DB394C",
          base: "#FF4F54",
          lighten1: "#FF827A",
          lighten2: "#FFA495",
          lighten3: "#FFC9B8",
          lighten4: "#FFE7DB",
          lighten5: "#FFE7DB"
        }
      }
    }
  },
  icons: {
    iconfont: "fa"
  }
});

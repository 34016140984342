var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "offset-y": "",
        left: "",
        "nudge-bottom": 14,
        "close-on-content-click": false
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "div",
                              _vm._g({}, on),
                              [
                                _c("va-button", {
                                  attrs: {
                                    icon: "va va-picture",
                                    color: _vm.iconColor,
                                    type: "squareText",
                                    loading:
                                      _vm.$store.getters[
                                        "canvas/isBackgroundLoading"
                                      ]
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.isOpen = !_vm.isOpen
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("conference.changeBackground")))
                  ])
                ]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { width: "400" } },
        [
          _c("v-card-title", [
            _vm._v(_vm._s(_vm.$t("conference.changeBackground")))
          ]),
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "d-flex flex-wrap mr-n4 justify-space-between " },
              _vm._l(_vm.availableColors, function(color) {
                return _c("va-button", {
                  key: color,
                  staticClass: "mr-4 mt-4",
                  attrs: {
                    icon: "va va-circle",
                    color: color,
                    type: "squareFill"
                  },
                  on: {
                    click: function($event) {
                      return _vm.setBackgroundColor(color)
                    }
                  }
                })
              }),
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex flex-wrap mr-n4" },
              [
                _vm._l(_vm.availableBackgrounds, function(img, i) {
                  return _c(
                    "div",
                    {
                      key: i,
                      staticClass:
                        "rounded secondary lighten-4 overflow-hidden mt-4 mr-4"
                    },
                    [
                      _c("v-img", {
                        attrs: {
                          src: img,
                          "max-height": "44",
                          "max-width": "78"
                        },
                        on: {
                          click: function($event) {
                            return _vm.setFixedImage(img)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                _c("va-button", {
                  staticClass: "mt-4",
                  staticStyle: { "margin-left": "94px" },
                  attrs: {
                    icon: "va va-picture",
                    color: "secondary darken-1",
                    size: "l"
                  },
                  on: { click: _vm.onUploadFile }
                })
              ],
              2
            ),
            _c("input", {
              ref: "uploader",
              staticClass: "d-none",
              attrs: { type: "file", accept: "image/*" },
              on: { change: _vm.handleUpload }
            })
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
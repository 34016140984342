



























import Vue from "vue";
import { ConfirmDialog } from "@/models/confirmDialog.interface";
import VaButton from "@/components/atoms/VaButton.vue";

export default Vue.extend({
  components: {
    VaButton
  },
  computed: {
    dialog(): ConfirmDialog {
      return this.$store.getters["notifications/confirmDialog"];
    }
  },
  methods: {
    confirm() {
      this.dialog.callback();
      this.dismiss();
    },
    dismiss() {
      if (this.dialog.closeCallback) this.dialog.closeCallback();
      this.$store.commit("notifications/dismissConfirmDialog");
    }
  }
});

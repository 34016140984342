export default {
  getTenant(): string {
    return process.env.VUE_APP_TENANT || "Mago";
  },
  getTenantCode(): string {
    return process.env.VUE_APP_TENANT_CODE || "REM-MAG-APP";
  },
  getTenantProductName(): string {
    const tenantNames = {
      Valarea: "Valarea Web App",
      Mago: "Mago",
      IdeaHubRoom: "IdeaHub Room",
      SmartTech: "TeamWorks App",
      TAEasyMeeting: "TA Easy Meeting App"
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return tenantNames[this.getTenant()] || "Mago";
  },
  requireAsset(asset: string): string {
    const t = this.getTenant();
    return require(`@/assets/img/logos/${t}/${asset}`);
  },
  requireBackground(asset: string): string {
    const t = this.getTenant();
    return require(`@/assets/img/backgrounds/${t}/${asset}`);
  }
};

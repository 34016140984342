










import Vue from "vue";

export default Vue.extend({
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String,
      required: false,
      default: "m",
      validator(value) {
        return ["l", "m", "s"].includes(value);
      }
    },
    color: {
      type: String,
      required: false
    }
  },
  computed: {
    pixelSize(): number {
      switch (this.size) {
        case "s":
          return 24;
        case "m":
          return 40;
        case "l":
          return 84;
        default:
          return 40;
      }
    }
  }
});

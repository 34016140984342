
























































































































































import Vue from "vue";
import store from "@/store";
import VaButton from "@/components/atoms/VaButton.vue";
import HexColorSelector from "@/components/molecules/HexColorSelector.vue";
import Konva from "konva";
import { openWindow } from "@/services/wb/elements/wbWebElement.service";
import { BoardElement } from "@/models/wb/boardElement.interface";
import { isVideo } from "@/services/wb/elements/wbWebElement.service";
import i18n from "@/plugins/lang";
import iconcolorMixin from "@/mixins/iconcolorMixin";

export default Vue.extend({
  name: "ContextMenu",
  components: {
    VaButton,
    HexColorSelector
  },
  mixins: [iconcolorMixin],
  computed: {
    selectedImage(): BoardElement | undefined {
      return store.getters["wb/files/selectedImage"];
    },
    stage(): Konva.Stage {
      return store.getters["canvas/stage"];
    },
    focusOnfile(): boolean {
      return store.getters["wb/files/focusOnfile"];
    },
    isWriter(): boolean {
      return store.getters["conference/isWriter"];
    }
  },
  methods: {
    deleteElement() {
      this.$store.commit("notifications/displayConfirmDialog", {
        visible: true,
        title: i18n.t("conference.confirmCancelElementTitle"),
        description: `${i18n.t("conference.confirmCancelElementDescription")}`,
        callback: () => {
          this.$store.dispatch("wb/files/deleteSelectedImage");
        }
      });
    },
    isVideo(element: BoardElement): boolean {
      return (
        element &&
        element.file.type === "url" &&
        !!element.file.details &&
        isVideo(element.file.details.text)
      );
    },
    changePdfPage(offset: number) {
      this.$store.dispatch("wb/files/changePdfPage", {
        pageId: this.$store.getters["canvas/page"].uuid,
        file: this.$store.getters["wb/files/selectedImage"],
        offset: offset
      });
    },
    openUrl(img: BoardElement) {
      if (img.file?.details?.text) {
        openWindow(img.file.details.text);
      }
    },
    getPosition(): { top: number; left: number } | undefined {
      if (!this.focusOnfile && this.selectedImage) {
        this.selectedImage?.image?.getAbsolutePosition();
        const position = this.selectedImage?.image?.getClientRect();
        let el = document
          ? document?.getElementById("wbContainer")?.getBoundingClientRect()
          : null;
        if (!el) return;
        return {
          top: !this.focusOnfile
            ? position.y + position.height + 4
            : el.y + el.height - 50,
          left: !this.focusOnfile ? position.x : el.x + el.width / 2 - 125
        };
      } else {
        return;
      }
    }
  }
});


















































































import Vue from "vue";
import store from "@/store";
import { ModelViewerElement } from "@google/model-viewer/lib/model-viewer";
import { takeSnapShot } from "@/services/wb/elements/wbGlbElement.service";
import { RenderedMaterial } from "@/models/wb/solidMaterial.interface";
import * as glbService from "@/services/wb/elements/wbGlbElement.service";

export default Vue.extend({
  components: {},
  data: () => ({
    modelRotating: false,
    drawer: false,
    materials: [] as RenderedMaterial[] | undefined,
    selectedMaterial: undefined as RenderedMaterial | undefined,
    glbService: glbService
  }),
  props: {
    resource: {
      type: String,
      required: true
    }
  },
  computed: {
    selectedImage() {
      return store.getters["wb/files/selectedImage"];
    },
    sphericalCoordinates(): string {
      return store.getters["wb/files/selectedImage"]?.file?.details
        ?.sphericalCoordinates;
    },
    remoteMaterials(): string {
      return store.getters["wb/files/selectedImage"]?.file?.details?.materials;
    },
    modelViewer(): ModelViewerElement {
      return this.$refs.modelViewer as ModelViewerElement;
    }
  },
  watch: {
    sphericalCoordinates: function(newval) {
      if (newval && !this.modelRotating) {
        //coordinates received from socket
        this.modelViewer.cameraOrbit = newval;
      }
    },
    remoteMaterials: function(newval) {
      if (this.modelViewer.model?.materials && newval) {
        this.materials = glbService.createRenderedMaterials(
          this.modelViewer.model?.materials,
          newval || []
        );
        glbService.initMaterials(this.materials);
      }
    }
  },
  methods: {
    togglePalette() {
      this.drawer = !this.drawer;
      if (this.drawer) {
        store.commit("videoConference/setPinned", false);
      }
    },
    changeColor(mat: RenderedMaterial) {
      glbService.applyColor(mat);
      store.dispatch("wb/files/updateGlbMaterial", {
        element: this.selectedImage,
        material: mat
      });
    },
    changeMetal(mat: RenderedMaterial) {
      glbService.applyMetal(mat);
      store.dispatch("wb/files/updateGlbMaterial", {
        element: this.selectedImage,
        material: mat
      });
    },
    changeRoughness(mat: RenderedMaterial) {
      glbService.applyRoughness(mat);
      store.dispatch("wb/files/updateGlbMaterial", {
        element: this.selectedImage,
        material: mat
      });
    },
    async takeSnapShot(): Promise<void> {
      // const url = await takeSnapShot(this.modelViewer);
      this.$emit("snapshotAcquired", await takeSnapShot(this.modelViewer));
    },
    updateRotation(): void {
      const orbit = this.modelViewer.getCameraOrbit().toString();
      if (this.selectedImage.file.details) {
        this.selectedImage.file.details.sphericalCoordinates = orbit;
      } else {
        this.selectedImage.file.details = { sphericalCoordinates: orbit };
      }
      this.selectedImage.file.inFullscreen = true;
      store.dispatch("wb/files/updateGlbPosition", this.selectedImage);
    },
    onZoomModel() {
      this.updateRotation();
    },
    initModel() {
      this.modelViewer.cameraOrbit =
        this.selectedImage?.file.details?.sphericalCoordinates || "auto";
      if (this.modelViewer.model?.materials) {
        this.materials = glbService.createRenderedMaterials(
          this.modelViewer.model?.materials,
          this.selectedImage?.file.details?.materials || []
        );
        glbService.initMaterials(this.materials);
        this.selectedMaterial = this.materials[0];
      }
      store.commit("wb/files/setSelectedModelViewer", this.modelViewer);
    },
    onStartRotateModel() {
      this.modelRotating = true;
    },
    onStopRotateModel() {
      this.modelRotating = false;
      setTimeout(this.updateRotation, 200);
    },
    onRotateModel() {
      if (this.modelRotating) {
        this.updateRotation();
      }
    }
  }
});

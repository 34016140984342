export interface PollResponse {
  id: string; //ResponseId
  response: string; //Response
  isTrue: boolean; //IsTrue
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function PollResponseDeserializer(input: any): PollResponse {
  return {
    id: input.ResponseId || "",
    response: input.Response || "",
    isTrue: input.IsTrue || false
  };
}

export function PollResponseSerializer(r: PollResponse): unknown {
  return {
    ResponseId: r.id,
    Response: r.response,
    isTrue: r.isTrue
  };
}



















































import Vue from "vue";
import VaButton from "@/components/atoms/VaButton.vue";
import { getBackupCodes, resetBackupCodes } from "@/services/authService";
import logger from "@/services/loggerService";
import VaLoader from "@/components/atoms/VaLoader.vue";

export default Vue.extend({
  name: "BackupCodesProvider",
  data() {
    return {
      codes: [] as string[],
      loading: false,
      dialog: false
    };
  },
  components: {
    VaLoader,
    VaButton
  },
  methods: {
    async onGetBackupCodes() {
      this.dialog = true;
      if (this.codes.length == 0) {
        try {
          this.loading = true;
          this.codes = await getBackupCodes();
        } catch (e) {
          logger.error(e);
        } finally {
          this.loading = false;
        }
      }
    },

    async onResetBackupCodes() {
      try {
        this.loading = true;
        await resetBackupCodes();
        this.codes = await getBackupCodes();
      } catch (e) {
        logger.error(e);
      } finally {
        this.loading = false;
      }
    }
  }
});

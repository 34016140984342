








































































import Vue from "vue";
import VaButton from "@/components/atoms/VaButton.vue";
import { HTMLInputEvent } from "@/models/htmlInputEvent.interface";
import * as wbFileService from "@/services/wb/wbFileService";
import iconcolorMixin from "@/mixins/iconcolorMixin";
import {
  availableBackgrounds,
  availableBgColors
} from "@/services/configService";

export default Vue.extend({
  name: "BackgroundTool",
  components: { VaButton },
  mixins: [iconcolorMixin],
  data: () => ({
    isOpen: false,
    availableBackgrounds: availableBackgrounds,
    availableColors: availableBgColors
  }),
  methods: {
    onUploadFile() {
      const element = this.$refs.uploader as HTMLElement;
      element.click();
    },

    async setBackgroundColor(color: string) {
      this.$store.dispatch("canvas/removeBackgroundImage");
      this.isOpen = false;
      this.$store.dispatch("canvas/updateBackgroundColor", {
        color: color,
        sendMessage: true
      });
    },

    async setFixedImage(img: string) {
      this.$store.commit("canvas/setBackgroundLoading", true);
      this.isOpen = false;
      const fileId = await wbFileService.uploadFileRest(img, "fixedbg.png");
      this.$store.dispatch("canvas/updateBackgroundImage", {
        imageId: fileId,
        sendMessage: true
      });
    },

    async handleUpload(event: HTMLInputEvent) {
      if (event?.target?.files?.length) {
        this.$store.commit("canvas/setBackgroundLoading", true);
        this.isOpen = false;
        const file = event.target.files[0];
        const fileId = await wbFileService.uploadBackgroundFile(file);
        this.$store.dispatch("canvas/updateBackgroundImage", {
          imageId: fileId,
          sendMessage: true
        });
        if (this.$refs.uploader) {
          (this.$refs.uploader as HTMLInputElement).value = "";
        }
      }
    }
  }
});

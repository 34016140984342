



























































import Vue from "vue";
import VaButton from "@/components/atoms/VaButton.vue";
import VaAvatar from "@/components/atoms/VaAvatar.vue";
import { User } from "@/models/user.interface";
import { ConferenceAuthData } from "@/models/conferenceAuthData.interface";
import VaLoader from "@/components/atoms/VaLoader.vue";
import iconcolorMixin from "@/mixins/iconcolorMixin";

export default Vue.extend({
  components: {
    VaButton,
    VaAvatar,
    VaLoader
  },
  mixins: [iconcolorMixin],
  data: () => ({
    showMenu: false
  }),
  computed: {
    isLoading(): ConferenceAuthData {
      return this.$store.getters["conference/isLoading"];
    },
    authData(): ConferenceAuthData {
      return this.$store.getters["conference/authData"];
    },
    connectedUsers(): User[] {
      return this.$store.getters["conference/users"];
    },
    allowedWriters(): number {
      return this.$store.getters["users/user"].subscription
        ?.meetingMaxWriterUser;
    },
    totalWriters(): number {
      let writers = this.$store.getters["conference/users"].filter(
        (u: User) => {
          return this.isWriter(u);
        }
      ).length;
      //if current user is moderator add it to the writers count
      if (this.authData.user.isModerator) writers++;
      return writers;
    }
  },
  methods: {
    isWriter(user: User): boolean {
      return !!user?.roles?.length && user.roles.indexOf("Writer") >= 0;
    },
    toggleWriteRole(user: User) {
      if (this.isWriter(user)) {
        this.$store.dispatch("conference/removeUserRole", {
          user: user,
          role: "Writer"
        });
      } else {
        this.$store.dispatch("conference/addUserRole", {
          user: user,
          role: "Writer"
        });
      }
    }
  }
});

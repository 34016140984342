var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.providers
        ? _c("h2", { staticClass: "mb-2" }, [
            _vm._v(_vm._s(_vm.$t("mfa.title")))
          ])
        : _vm._e(),
      _vm._l(_vm.providers, function(item) {
        return _c(
          "div",
          { key: item.type, staticClass: "d-flex justify-space-between" },
          [
            _vm._v(" " + _vm._s(item.name) + " "),
            _c("va-button", {
              staticClass: "mb-2",
              attrs: {
                size: "s",
                loading:
                  _vm.isLoading ||
                  (_vm.activeProvider && _vm.activeProvider.type === item.type),
                cta: _vm.isEnabled(item)
                  ? _vm.$t("mfa.disable")
                  : _vm.$t("mfa.enable")
              },
              on: {
                click: function($event) {
                  _vm.isEnabled(item)
                    ? _vm.disableProvider(item)
                    : _vm.enableProvider(item)
                }
              }
            })
          ],
          1
        )
      }),
      _vm.activeProvider && _vm.activeProvider.type === 1
        ? _c("div", [
            _c("div", [_vm._v(_vm._s(_vm.$t("mfa.enableEmail")))]),
            _c(
              "div",
              { staticClass: "d-flex mt-3" },
              [
                _c("va-text-field", {
                  attrs: {
                    rules: [_vm.required],
                    dense: "",
                    label: _vm.$t("mfa.insertCode")
                  },
                  model: {
                    value: _vm.emailVerificationCode,
                    callback: function($$v) {
                      _vm.emailVerificationCode = $$v
                    },
                    expression: "emailVerificationCode"
                  }
                }),
                _c("va-button", {
                  staticClass: "ml-4",
                  attrs: {
                    loading: _vm.isLoading,
                    cta: _vm.$t("mfa.verifyCode")
                  },
                  on: { click: _vm.enableMailProvider }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c("mfa-qrcode-dialog", {
            attrs: { setup: _vm.setup, mfaToken: _vm.mfaToken },
            on: { click: _vm.resetActivation }
          })
        ],
        1
      ),
      _vm.enabledProviders.length > 0 ? _c("backup-codes-provider") : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
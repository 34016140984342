var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "py-3" }, [
    _vm.date
      ? _c("div", { staticClass: "mx-auto mb-1 text-center text--disabled" }, [
          _vm._v(" " + _vm._s(_vm.formattedTime) + " ")
        ])
      : _vm._e(),
    _c(
      "div",
      {
        class: [
          "d-flex",
          "align-center",
          "rounded",
          "pa-3",
          _vm.computedType.color + "-outline"
        ]
      },
      [
        _c("va-icon", {
          staticClass: "mr-3",
          attrs: { icon: _vm.computedType.icon, color: _vm.computedType.color }
        }),
        _vm.sender
          ? _c("div", { staticClass: "mr-3 flex-shrink-0 font-weight-bold" }, [
              _vm._v(" " + _vm._s(_vm.sender) + ": ")
            ])
          : _vm._e(),
        _vm._t("default")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pr-1 py-3" }, [
    !_vm.isOwner
      ? _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("va-avatar", {
              staticClass: "mr-3 mt-3",
              attrs: { image: _vm.image, text: _vm.sender }
            }),
            _c(
              "div",
              { staticClass: "rounded elevation-2 pa-3 message" },
              [
                _c("div", { staticClass: "mb-1 font-weight-bold" }, [
                  _vm._v(" " + _vm._s(_vm.sender) + " ")
                ]),
                _vm._t("default"),
                _vm.date
                  ? _c(
                      "div",
                      { staticClass: "text-right text--disabled font-size-s" },
                      [_vm._v(" " + _vm._s(_vm.formattedTime) + " ")]
                    )
                  : _vm._e()
              ],
              2
            )
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "d-flex justify-end" },
          [
            _c(
              "div",
              {
                staticClass:
                  "rounded elevation-2 pa-3 my-message primary darken-2 secondary--text text--lighten-4"
              },
              [
                _vm._t("default"),
                _vm.date
                  ? _c(
                      "div",
                      { staticClass: "text-right text--disabled font-size-s" },
                      [_vm._v(" " + _vm._s(_vm.formattedTime) + " ")]
                    )
                  : _vm._e()
              ],
              2
            ),
            _c("va-avatar", {
              staticClass: "ml-3 mt-2",
              attrs: { image: _vm.image, text: _vm.sender }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./BackgroundTool.vue?vue&type=template&id=468977f8&scoped=true&"
import script from "./BackgroundTool.vue?vue&type=script&lang=ts&"
export * from "./BackgroundTool.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "468977f8",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCardText,VCardTitle,VImg,VMenu,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('468977f8')) {
      api.createRecord('468977f8', component.options)
    } else {
      api.reload('468977f8', component.options)
    }
    module.hot.accept("./BackgroundTool.vue?vue&type=template&id=468977f8&scoped=true&", function () {
      api.rerender('468977f8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/organisms/BackgroundTool.vue"
export default component.exports
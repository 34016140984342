




































import Vue from "vue";
import VaButton from "@/components/atoms/VaButton.vue";
import iconcolorMixin from "@/mixins/iconcolorMixin";

export default Vue.extend({
  components: { VaButton },
  mixins: [iconcolorMixin]
});

















import Vue from "vue";
import { OauthProvider } from "@/models/oauth.interface";
import logger from "@/services/loggerService";

export default Vue.extend({
  name: "AppleSignIn",
  components: {},
  computed: {
    providerType(): number {
      return OauthProvider.Apple;
    }
  },
  beforeDestroy() {
    document.removeEventListener("AppleIDSignInOnSuccess", data =>
      this.onSuccess(data)
    );
    document.removeEventListener("AppleIDSignInOnFailure", err =>
      logger.error(err as ErrorEvent)
    );
  },
  mounted() {
    if (!window.AppleID) {
      throw new Error(
        '"https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js" needs to be included as a <script>'
      );
    }
    window.AppleID.auth.init({
      clientId: process.env.VUE_APP_APPLE_CLIENT_ID,
      scope: "name email",
      redirectURI: `${window.location.origin}/signin`,
      usePopup: true
    });

    document.addEventListener("AppleIDSignInOnSuccess", data =>
      this.onSuccess(data)
    );
    document.addEventListener("AppleIDSignInOnFailure", err =>
      logger.error(err as ErrorEvent)
    );
  },
  methods: {
    //TODO: move in mixin
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    async onSuccess(appleUser: any) {
      this.$store.dispatch("session/federateLogin", {
        oauthProviderType: this.providerType,
        callbackUrl: `${window.location.origin}/signin`,
        authCode: appleUser.detail.authorization.id_token
      });
    }
  }
});

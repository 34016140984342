var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "wrapperCentered d-flex flex-column justify-space-between",
      style: _vm.backgroundStyles
    },
    [
      _c(
        "div",
        { staticClass: "top-header top-left pa-6 d-flex" },
        [
          _c("div", { staticClass: "flex-grow-1" }, [
            _c("img", {
              staticClass: "logo",
              attrs: { alt: "Logo", src: _vm.logo }
            })
          ]),
          _c("LightSwitcher")
        ],
        1
      ),
      _c("div", { staticClass: "wrapper mx-auto pt-14" }, [
        _c(
          "div",
          { staticClass: "main-content border-ra" },
          [
            this.$slots.headerContent
              ? _c(
                  "div",
                  { staticClass: "background-dark border-rt border-b" },
                  [_vm._t("headerContent")],
                  2
                )
              : _vm._e(),
            _vm._t("default")
          ],
          2
        ),
        this.$slots.bottomContent
          ? _c(
              "div",
              { staticClass: "main-content rounded mt-6" },
              [_vm._t("bottomContent")],
              2
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }






















































import Vue, { PropType } from "vue";
import QrcodeVue from "qrcode.vue";
import {
  confirmMFAActivationCode,
  verifyMFACode
} from "@/services/authService";
import { MfaSetup } from "@/models/mfaProvider";
import logger from "@/services/loggerService";
import i18n from "@/plugins/lang";
import VaTextField from "@/components/atoms/VaTextField.vue";
import validationMixin from "@/mixins/validationMixin";

export default Vue.extend({
  name: "MfaQrcodeDialog",
  components: {
    VaTextField,
    QrcodeVue
  },
  mixins: [validationMixin],
  props: {
    setup: {
      type: Object as PropType<MfaSetup>,
      required: true
    },
    mfaToken: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      code: "",
      enabling: false,
      loading: false,
      requiredRules: [
        (value: string) => !!value || i18n.t("validations.required")
      ]
    };
  },
  methods: {
    async enableMFA() {
      try {
        const confirmed = await verifyMFACode(this.code, this.mfaToken);
        if (confirmed)
          this.$store.commit("notifications/displayConfirmDialog", {
            visible: true,
            title: this.$i18n.t("mfa.confirmActivationTitle"),
            description: `${this.$i18n.t(
              "mfa.confirmAuthenticatorDescription"
            )}`,
            callback: async () => {
              try {
                await confirmMFAActivationCode(this.mfaToken);
                this.$store.dispatch("mfa/fetchEnabledProviders");
                this.$store.commit("notifications/displayNotification", {
                  message: i18n.t("mfa.codeSent"),
                  type: "success"
                });
                this.$emit("click");
              } catch (e) {
                logger.error(e);
              }
            }
          });
      } catch (e) {
        logger.error(e);
      } finally {
        this.loading = false;
      }
    }
  },
  computed: {}
});

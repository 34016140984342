

















import Vue from "vue";
import {
  cleanUrl,
  openWindow
} from "@/services/wb/elements/wbWebElement.service";

export default Vue.extend({
  props: {
    url: {
      type: String,
      required: true
    }
  },
  computed: {
    cleanUrl(): string {
      return cleanUrl(this.url);
    }
  },
  methods: {
    openWindow
  }
});

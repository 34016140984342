import "@/plugins/vueCookies";
import {
  AuthData,
  AuthDataDeserializer,
  LegacyConfirmData,
  ResetPasswordData,
  SignUpData
} from "@/models/authData.interface";
import { User, UserDeserializer } from "@/models/user.interface";
import axios, { AxiosResponse } from "axios";
import * as configService from "@/services/configService";
import { MfaProvider, MfaProviderDeserializer } from "@/models/mfaProvider";
import { OauthProvider } from "@/models/oauth.interface";
import { getBrowserVersion } from "@/services/browserVersionService";

export async function login(
  username: string,
  password: string
): Promise<AuthData | { mfaToken: string }> {
  let resp;
  try {
    resp = await axios.post(configService.authUrl(), {
      deviceUniqueId: getBrowserVersion(),
      appPlatform: "web",
      grantType: "password",
      email: username,
      password: password
    });
  } catch (e) {
    if (e.response.data.errorCode === "A403") {
      return { mfaToken: e.response.data.info.mfaToken };
    }
  }
  const authData = AuthDataDeserializer(resp?.data);
  const requestOptions = {
    headers: { authorization: `Bearer ${authData.token}` }
  };
  const legacyResp = await axios.get(
    configService.getUserMeUrl(),
    requestOptions
  );
  let legacyUrl = legacyResp?.data?.settings?.legacyServerUrl || "";
  //clean trailing slashes
  if (legacyUrl.endsWith("/")) legacyUrl = legacyUrl.slice(0, -1);
  authData.legacyServerUrl = legacyUrl;
  return authData;
}

export async function signUp(data: SignUpData): Promise<AxiosResponse> {
  return await axios.post(configService.signUpUrl(), data);
}
export async function inviteSignUp(data: SignUpData): Promise<AxiosResponse> {
  return await axios.post(configService.inviteSignUpUrl(), data);
}

export async function confirmEmail(
  email: string,
  token: string
): Promise<AxiosResponse> {
  return await axios.post(configService.confirmMailUrl(), {
    email: email,
    token: token
  });
}
export async function confirmResend(email: string): Promise<AxiosResponse> {
  return await axios.post(configService.confirmResendUrl(), {
    Email: email
  });
}

export async function forgotPassword(email: string): Promise<AxiosResponse> {
  return await axios.post(configService.forgotPasswordUrl(), { email: email });
}

export async function resetPassword(
  data: ResetPasswordData
): Promise<AxiosResponse> {
  return await axios.post(configService.resetPasswordUrl(), data);
}

export async function federateLogin(
  oauthProviderType: OauthProvider,
  callbackUrl: string,
  authCode: string
): Promise<AuthData | { mfaToken: string }> {
  let resp;
  try {
    resp = await axios.post(configService.authUrl(), {
      deviceUniqueId: getBrowserVersion(),
      appPlatform: "web",
      grantType: "access_token",
      oauthProviderType: oauthProviderType,
      callbackUrl: callbackUrl,
      authCode: authCode
    });
  } catch (e) {
    if (e.response.data.errorCode === "A403") {
      return { mfaToken: e.response.data.info.mfaToken };
    }
  }
  const authData = AuthDataDeserializer(resp?.data);
  const requestOptions = {
    headers: { authorization: `Bearer ${authData.token}` }
  };
  const legacyResp = await axios.get(
    configService.getUserMeUrl(),
    requestOptions
  );
  let legacyUrl = legacyResp?.data?.settings?.legacyServerUrl || "";
  //clean trailing slashes
  if (legacyUrl.endsWith("/")) legacyUrl = legacyUrl.slice(0, -1);
  authData.legacyServerUrl = legacyUrl;
  return authData;
}

export async function mfaLogin(token: string, code: string): Promise<AuthData> {
  const resp = await axios.post(configService.authUrl(), {
    deviceUniqueId: getBrowserVersion(),
    appPlatform: "web",
    grantType: "password",
    mfaToken: token,
    mfaCode: code
  });
  const authData = AuthDataDeserializer(resp?.data);
  const requestOptions = {
    headers: { authorization: `Bearer ${authData.token}` }
  };
  const legacyResp = await axios.get(
    configService.getUserMeUrl(),
    requestOptions
  );
  authData.legacyServerUrl = legacyResp?.data?.settings?.legacyServerUrl || "";
  return authData;
}

export function isAuthdataExpired(data: AuthData): boolean {
  return new Date(data.expires) < new Date();
}

export async function getUser(): Promise<User> {
  const resp = await axios.get(configService.getUserProfileUrl());
  return UserDeserializer(resp.data);
}

export async function refreshToken(oldData: AuthData): Promise<AuthData> {
  const resp = await axios.post(configService.refreshAuthUrl(), oldData);
  const newData = AuthDataDeserializer(resp.data);
  newData.legacyServerUrl = oldData.legacyServerUrl;
  newData.chatServerUrl = oldData.chatServerUrl;
  return newData;
}

/*----- MFA CALLS---------*/

export async function getMfaProviders(): Promise<Array<MfaProvider>> {
  const res = await axios.get(configService.mfaProvidersUrl());
  return res.data.providers.map(MfaProviderDeserializer);
}

export async function getEnabledProviders(): Promise<Array<MfaProvider>> {
  const res = await axios.get(configService.enabledProvidersUrl());
  return res.data.providers.map(MfaProviderDeserializer);
}

export async function getProvidersChallenge(
  mfaToken: string
): Promise<MfaProvider[]> {
  const res = await axios.post(configService.providersChallengeUrl(), {
    mfaToken
  });
  return res.data.providers.map(MfaProviderDeserializer);
}

export async function sendMFACode(
  provider: MfaProvider
): Promise<string | undefined> {
  const res = await axios.post(configService.mfaEnableCodeUrl(), {
    providerType: provider.type
  });
  return res.data?.mfaToken;
}

export async function verifyMFACode(
  authCode: string,
  mfaToken: string
): Promise<boolean> {
  const res = await axios.post(configService.mfaVerificationCodeUrl(), {
    authCode: authCode,
    mfaToken: mfaToken
  });
  return res.status === 200;
}

export async function confirmMFAActivationCode(
  mfaToken: string
): Promise<boolean> {
  const res = await axios.put(configService.confirmMFAActivationUrl(), {
    mfaToken: mfaToken
  });
  return res.status === 202;
}

export async function disableMFAProvider(provider: MfaProvider): Promise<void> {
  await axios.delete(
    `${configService.confirmMFAActivationUrl()}?providerType=${provider.type}`
  );
}

export async function sendMFACodeAction(
  provider: MfaProvider,
  mfaToken: string
): Promise<boolean> {
  const res = await axios.post(configService.mfaCheckCodeUrl(), {
    providerType: provider.type,
    mfaToken: mfaToken
  });
  return !!res.data?.mfaToken;
}

export async function getBackupCodes(): Promise<string[]> {
  const res = await axios.get(configService.backupCodesUrl());
  return res.data
    .filter(
      (item: { isConsumed: boolean; backupCode: string }) => !item.isConsumed
    )
    .map(
      (item: { isConsumed: boolean; backupCode: string }) => item.backupCode
    );
}
export async function resetBackupCodes(): Promise<string[]> {
  return axios.post(configService.resetBackupCodesUrl());
}

///LEGACY FUNCTIONS -------------------------------
export async function confirmEmailLegacy(
  email: string,
  token: string
): Promise<AxiosResponse> {
  return await axios.post(configService.confirmMailUrlLegacy(), {
    Email: email,
    Token: token
  });
}

export async function confirmInvitationLegacyNoInfo(
  email: string,
  token: string
): Promise<AxiosResponse> {
  return await axios.post(configService.confirmInvitationUrlLegacy(), {
    email: email,
    token: token
  });
}

export async function confirmInvitationLegacyFull(
  data: LegacyConfirmData
): Promise<AxiosResponse> {
  return await axios.post(configService.confirmInvitationUrlLegacy(), data);
}

export async function resetPasswordLegacy(
  data: ResetPasswordData
): Promise<AxiosResponse> {
  const legacyData = {
    Email: data.email,
    Token: data.token,
    Password: data.password,
    ConfirmPassword: data.password
  };
  return await axios.post(configService.resetPasswordUrlLegacy(), legacyData);
}

import { User, UserDeserializer } from "@/models/user.interface";
import * as configService from "@/services/configService";

export interface GuestRoom {
  meetingId: string;
  webConferenceActive: boolean;
}

export interface RoomInfo {
  webConferenceId: string;
  shortId: string;
  sharedLink: string;
  sharedModeratorLink: string;
  pin: string | number;
  moderatorPin: string | number;
  guestEnabled: boolean;
}

export interface Room {
  groupId: string;
  destinationId?: string;
  name: string;
  isGroup?: boolean;
  description?: string;
  groupStyle?: number;
  users?: User[];
  createdByUserId?: string;
  creationDate?: string;
  updateDate?: Date;
  avatarDate?: Date;
  avatar?: string;
  groupInfo?: Room;
  roomInfo?: RoomInfo;
}

function getGroupAvatarSrc(
  groupId: string,
  avatarDate: string | undefined,
  isGroup: boolean,
  size = 250
): string {
  if (avatarDate) {
    return `${configService.getChatAvatarUrl(isGroup)}/${groupId}/${size}`;
  }
  return "";
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function RoomDeserializer(input: any): Room {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  const users = input.Users?.map((rawUser: any) => {
    return rawUser ? UserDeserializer(rawUser) : undefined;
  });
  const groupInfo = input.GroupInfo
    ? RoomDeserializer(input.GroupInfo)
    : undefined;

  let roomInfo;
  if (input.RoomInfo) {
    //should never be called
    roomInfo = RoomInfoDeserializer(input.RoomInfo);
  }

  //HACK: caused by different api responses, if room has defined no "Isgroup" field but  has user is a group
  let isGroup: boolean;
  if (input.IsGroup === false || groupInfo?.isGroup === false) {
    isGroup = false;
  } else {
    isGroup = input.IsGroup || !groupInfo?.isGroup || input.Users;
  }

  const destinationId =
    input.DestinationId || groupInfo?.destinationId || input.GroupId;
  return {
    groupId: input.GroupId || groupInfo?.groupId || "",
    destinationId: destinationId,
    name: input.Name || groupInfo?.name || "",
    isGroup: isGroup,
    description: input.Description || groupInfo?.description || "",
    groupStyle: input.GroupStyle || groupInfo?.groupStyle,
    users: users || groupInfo?.users,
    createdByUserId: input.CreatedByUserId || groupInfo?.createdByUserId,
    creationDate: input.CreationDate || groupInfo?.creationDate,
    updateDate: input.UpdateDate || groupInfo?.updateDate,
    avatarDate: input.AvatarDate || groupInfo?.avatarDate,
    avatar: getGroupAvatarSrc(destinationId, input.AvatarDate, isGroup),
    groupInfo: groupInfo,
    roomInfo: roomInfo
  };
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function RoomInfoDeserializer(input: any): RoomInfo {
  return {
    webConferenceId: input.WebConferenceId || "",
    shortId: input.ShortId || "",
    sharedLink: input.SharedLink || "",
    sharedModeratorLink: input.SharedModeratorLink || "",
    pin: input.Pin || "",
    moderatorPin: input.ModeratorPin || "",
    guestEnabled: !!input.GuestEnabled
  };
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function GuestRoomDeserializer(input: any): GuestRoom {
  return {
    meetingId: input.MeetingId?.replace(/-/g, "") || "",
    webConferenceActive: input.WebConferenceActive
  };
}

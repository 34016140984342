var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-chip",
    {
      attrs: {
        color: _vm.color,
        label: "",
        outlined: _vm.outline,
        "text-color": _vm.textColor,
        large: _vm.size === "l",
        "x-large": _vm.size === "xl",
        small: _vm.size === "s",
        "x-small": _vm.size === "xs"
      }
    },
    [_vm._v(" " + _vm._s(_vm.text) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Va-ChatMessage")]),
      _c("v-divider", { staticClass: "mb-4" }),
      _c(
        "div",
        { staticClass: " my-4" },
        [
          _c(
            "va-chat-message",
            { attrs: { sender: "Mago User", date: new Date() } },
            [
              _vm._v(" msg with "),
              _c("em", [_vm._v("some "), _c("b", [_vm._v("formatting")])])
            ]
          ),
          _c(
            "va-chat-message",
            {
              attrs: {
                message: _vm.loremIpsum.substring(0, 100),
                image: _vm.images[5],
                sender: "Marco Zuccaroli"
              }
            },
            [_vm._v(" " + _vm._s(_vm.loremIpsum.substring(0, 100)) + " ")]
          ),
          _c(
            "va-chat-message",
            {
              attrs: {
                "is-owner": true,
                image: _vm.images[0],
                sender: "Marco Zuccaroli",
                date: new Date()
              }
            },
            [_vm._v(" " + _vm._s(_vm.loremIpsum.substring(0, 80)) + " ")]
          ),
          _c(
            "va-chat-message",
            {
              attrs: {
                message: _vm.loremIpsum,
                sender: "Luca Terzaghi",
                date: new Date()
              }
            },
            [_vm._v(" " + _vm._s(_vm.loremIpsum) + " ")]
          ),
          _c(
            "va-chat-message",
            {
              attrs: {
                "is-owner": true,
                message: _vm.loremIpsum,
                sender: "Marco Zuccaroli",
                date: new Date()
              }
            },
            [_vm._v(" " + _vm._s(_vm.loremIpsum) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex";
import store, { RootState } from "@/store";
import { Poll } from "@/models/poll/poll.interface";
import * as conferenceService from "@/services/conferenceSocketService";
import { Question } from "@/models/poll/question.interface";

export interface PollsState {
  activePoll?: Poll;
}

type PollsContext = ActionContext<PollsState, RootState>;

export const namespaced = true;

export const state = (): PollsState => ({
  activePoll: undefined
});

export const getters: GetterTree<PollsState, RootState> = {
  activePoll: (state): Poll | undefined => {
    return state.activePoll;
  }
};

export const mutations: MutationTree<PollsState> = {
  setActivePoll(state: PollsState, poll: Poll | undefined) {
    state.activePoll = poll;
  }
};

export const actions: ActionTree<PollsState, RootState> = {
  sendAnswer(context: PollsContext, answeredQuestion: Question) {
    const s = store.getters["conference/socket"];
    conferenceService.sendAnswer(s, answeredQuestion);
  }
  // setToolByDevice(context: PollsContext, event: PointerEvent) {
  //   //if tool is set to drag and trigger event is pen automatically switch to draw
  //   if (event.pointerType == "pen" && context.state.tool == "drag") {
  //     context.dispatch("setTool", "draw");
  //   }
  // }
};

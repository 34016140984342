import Vue from "vue";

export default Vue.extend({
  computed: {
    iconColor(): string {
      return this.$store.getters["app/darkTheme"]
        ? "secondary lighten-4"
        : "secondary darken-4";
    }
  }
});

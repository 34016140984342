import axios, { AxiosRequestConfig } from "axios";
import * as configService from "@/services/configService";
import logger from "@/services/loggerService";
import store from "@/store";
import router from "@/router/index";
import { isAuthdataExpired } from "@/services/authService";
import tenantService from "@/services/tenantService";

const _notAuthorizedRoutes = (): string[] => {
  return [
    configService.authUrl(),
    configService.authConferenceUrl(),
    configService.getUserMeUrl(),
    configService.refreshAuthUrl(),
    configService.magolinkPasscodeUrl
  ];
};

const _requestHandler = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  config.headers["tenant_code"] = tenantService.getTenantCode();
  config.headers["Label"] = tenantService.getTenant();
  config.headers["Product"] = configService.applicationName;
  if (
    config.url &&
    !config.headers.Authorization &&
    !_notAuthorizedRoutes().includes(config.url)
  ) {
    let authData = store.getters["session/authData"];
    if (authData && authData.token) {
      if (isAuthdataExpired(authData)) {
        try {
          await store.dispatch("session/refreshAuth");
          authData = store.getters["session/authData"];
        } catch (err) {
          store.dispatch("session/logout");
        }
      }
      config.headers["authorization"] = `Bearer ${authData.token}`;
    }
  }
  return config;
};

export default function start(): void {
  axios.interceptors.request.use(
    request => _requestHandler(request),
    error => {
      logger.error(error);
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (
        error.response?.status === 401 &&
        !error.request?.responseURL.includes("/api/auth") &&
        !error.request?.responseURL.includes("/auth/user/passcode")
      ) {
        store.dispatch("session/logout");
        router.push(`/signin`);
      }
      logger.error(error);
      return Promise.reject(error);
    }
    // error => {
    //   if (
    //     error.response?.status === 401 &&
    //     !error.request?.responseURL.includes("/api/auth")
    //   ) {
    //     store.dispatch("session/logout");
    //     router.push(`/signin`);
    //   }
    //   logger.error(error);
    //   return Promise.reject(error);
    // }
  );
}



































import Vue from "vue";
import Buttons from "@/components/_showcase/atoms/_buttons.vue";
import Typography from "@/components/_showcase/atoms/_typography.vue";
import Avatar from "@/components/_showcase/atoms/_avatar.vue";
import Icons from "@/components/_showcase/atoms/_icons.vue";
import Chips from "@/components/_showcase/atoms/_chips.vue";
import Badge from "@/components/_showcase/molecules/_badge.vue";
import ChatMessage from "@/components/_showcase/molecules/_chatMessage.vue";
import LogMessage from "@/components/_showcase/molecules/_logMessage.vue";
import TextField from "@/components/_showcase/atoms/_textField.vue";
import Notification from "@/components/_showcase/molecules/_notification.vue";
import LightSwitcher from "@/components/organisms/LightSwitcher.vue";

export default Vue.extend({
  name: "DesignSystem",
  components: {
    LightSwitcher,
    Typography,
    Buttons,
    Avatar,
    Icons,
    Chips,
    Badge,
    ChatMessage,
    LogMessage,
    TextField,
    Notification
  },
  data() {
    return {
      tab: "molecules"
    };
  }
});

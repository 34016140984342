import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex";
import { RootState } from "@/store";
import { Tool, ToolColor } from "@/models/wb/tool.interface";

export interface ToolsState {
  tool: Tool;
  drawColor: ToolColor;
  markerColor: ToolColor;
  drawSize: number;
  markerSize: number;
  eraserSize: number;
}

type ToolsContext = ActionContext<ToolsState, RootState>;

export const namespaced = true;

export const state = (): ToolsState => ({
  tool: "drag",
  drawColor: "black",
  markerColor: "yellow",
  drawSize: 5,
  markerSize: 10,
  eraserSize: 20
});

export const getters: GetterTree<ToolsState, RootState> = {
  tool: (state): Tool => {
    return state.tool;
  },
  drawColor: (state): ToolColor => {
    return state.drawColor;
  },
  markerColor: (state): ToolColor => {
    return state.markerColor;
  },
  drawSize: (state): number => {
    return state.drawSize;
  },
  markerSize: (state): number => {
    return state.markerSize;
  },
  eraserSize: (state): number => {
    return state.eraserSize;
  }
};

export const mutations: MutationTree<ToolsState> = {
  setTool(state: ToolsState, tool: Tool) {
    state.tool = tool;
  },
  setDrawColor(state: ToolsState, color: ToolColor) {
    state.drawColor = color;
  },
  setMarkerColor(state: ToolsState, color: ToolColor) {
    state.markerColor = color;
  },
  setDrawSize(state: ToolsState, size: number) {
    state.drawSize = size;
  },
  setMarkerSize(state: ToolsState, size: number) {
    state.markerSize = size;
  },
  setEraserSize(state: ToolsState, size: number) {
    state.eraserSize = size;
  }
};

export const actions: ActionTree<ToolsState, RootState> = {
  setTool(context: ToolsContext, tool: Tool) {
    context.commit("setTool", tool);
    context.commit("canvas/setDraggable", tool === "drag", { root: true });
  },
  setToolByDevice(context: ToolsContext, event: PointerEvent) {
    //if tool is set to drag and trigger event is pen automatically switch to draw
    if (event.pointerType == "pen" && context.state.tool == "drag") {
      context.dispatch("setTool", "draw");
    }
  }
};





















































import Vue from "vue";
import * as wbFileService from "@/services/wb/wbFileService";
import VaButton from "@/components/atoms/VaButton.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import validationMixin from "@/mixins/validationMixin";
import iconcolorMixin from "@/mixins/iconcolorMixin";

export default Vue.extend({
  name: "CreateUrlBtn",
  components: { VaTextField, VaButton },
  mixins: [validationMixin, iconcolorMixin],
  data: () => ({
    openOptions: false,
    valid: true,
    newUrl: "https://"
  }),
  methods: {
    async createUrlElement(url: string) {
      if (url && this.valid && url !== "https://") {
        this.openOptions = false;
        await wbFileService.createUrlElement(url);
        this.newUrl = "https://";
      }
    },
    onClose() {
      this.newUrl = "https://";
    }
  }
});

import * as configService from "@/services/configService";

export interface UserSubscription {
  bingSearchDailyQuota?: number;
  bingSearchEnabled: boolean;
  description: string;
  digitalSignatureEnabled: boolean;
  globalChatEnabled: boolean;
  globalChatFileShareEnabled: boolean;
  globalChatMaxFileLength?: number;
  globalChatMaxUserToGroup?: number;
  globalChatStyleEnabled: boolean;
  guestEnabled: boolean;
  magicToolsEnabled: boolean;
  magocastEnabled: boolean;
  magocastQuota?: number;
  meetingChatEnabled: boolean;
  meetingFileUploadQuota?: number;
  meetingMaxConcurrentUsers?: number;
  meetingMaxWriterUser?: number;
  meetingsMaxItems?: number;
  meetingsStorageQuota?: number;
  myScriptEnabled: boolean;
  podEnabled: boolean;
  recordingEnabled: boolean;
  subscriptionId: string;
  webConferenceEnabled: boolean;
  webinarEnabled: boolean;
  webinarMaxUsers?: number;
  // chatServerUrl: string;
}

export interface User {
  availableStorageQuota?: number;
  avatarDate?: Date;
  avatar?: string;
  city?: string;
  email?: string;
  firstName?: string;
  isAdmin?: boolean;
  lastName?: string;
  roles?: string[];
  settingsInfo?: {
    magoCastServerUrl?: string;
    webAppServerUrl: string;
    webConferenceJoinUrl: string;
    chatServerUrl: string;
  };
  storageQuota?: number;
  subscription?: UserSubscription;
  updateDate?: Date;
  userId: string;
  userName?: string;
  userNickname?: string;
  userUniqueId?: string;
  isHub?: boolean;
  isModerator?: boolean;
  isWriter?: boolean;
  deviceOs?: string;
  visibleForSearch?: boolean;
}

export function getUserAvatarSrc(
  userId: string,
  avatarDate: string | undefined,
  baseUrl: string | undefined,
  size = 250
): string {
  if (avatarDate) {
    if (baseUrl) {
      return `${baseUrl}api/Users/GetUserAvatar/${userId}/${size}`;
    }
    return `${configService.getUserAvatarUrl()}/${userId}/${size}`;
  }
  return "";
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function UserDeserializer(input: any): User {
  const subscription = input.Subscription
    ? UserSubscriptionDeserializer(input.Subscription)
    : undefined;
  return {
    availableStorageQuota: input.AvailableStorageQuota,
    avatarDate: input.AvatarDate,
    avatar: getUserAvatarSrc(
      input.UserId,
      input.AvatarDate,
      input.SettingsInfo?.ChatServerUrl
    ),
    city: input.City || "",
    email: input.Email,
    firstName: input.FirstName,
    isAdmin: input.IsAdmin,
    lastName: input.LastName,
    roles: input.Roles,
    settingsInfo: {
      magoCastServerUrl: input.SettingsInfo?.MagoCastServerUrl || "",
      webAppServerUrl: input.SettingsInfo?.WebAppServerUrl || "",
      webConferenceJoinUrl: input.SettingsInfo?.WebConferenceJoinUrl || "",
      chatServerUrl: input.SettingsInfo?.ChatServerUrl || ""
    },
    userNickname: input.FirstName + " " + input.LastName,
    storageQuota: input.StorageQuota,
    subscription: subscription,
    updateDate: input.UpdateDate,
    userId: input.UserId,
    userName: input.UserName,
    visibleForSearch: input.VisibleForSearch
  };
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function MeetingUserDeserializer(input: any): User {
  return {
    userId: input.UserUniqueId,
    roles: input.Roles,
    userNickname: input.UserNickname,
    userUniqueId: input.UserUniqueId,
    isHub: input.IsHub,
    isModerator: input.IsModerator,
    isWriter: input.IsWriter,
    deviceOs: input.DeviceOs
  };
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function UserSubscriptionDeserializer(input: any): UserSubscription {
  return {
    bingSearchDailyQuota: input.BingSearchDailyQuota,
    bingSearchEnabled: input.BingSearchEnabled,
    description: input.Description || "",
    digitalSignatureEnabled: input.DigitalSignatureEnabled,
    globalChatEnabled: input.GlobalChatEnabled,
    globalChatFileShareEnabled: input.GlobalChatFileShareEnabled,
    globalChatMaxFileLength: input.GlobalChatMaxFileLength,
    globalChatMaxUserToGroup: input.GlobalChatMaxUserToGroup,
    globalChatStyleEnabled: input.GlobalChatStyleEnabled,
    guestEnabled: input.GuestEnabled,
    magicToolsEnabled: input.MagicToolsEnabled,
    magocastEnabled: input.MagocastEnabled,
    magocastQuota: input.MagocastQuota,
    meetingChatEnabled: input.MeetingChatEnabled,
    meetingFileUploadQuota: input.MeetingFileUploadQuota,
    meetingMaxConcurrentUsers: input.MeetingMaxConcurrentUsers,
    meetingMaxWriterUser: input.MeetingMaxWriterUser,
    meetingsMaxItems: input.MeetingsMaxItems,
    meetingsStorageQuota: input.MeetingsStorageQuota,
    myScriptEnabled: input.MyScriptEnabled,
    podEnabled: input.PodEnabled,
    recordingEnabled: input.RecordingEnabled,
    subscriptionId: input.SubscriptionId || "",
    webConferenceEnabled: input.WebConferenceEnabled,
    webinarEnabled: input.WebinarEnabled,
    webinarMaxUsers: input.WebinarMaxUsers
  };
}

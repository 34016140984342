import Konva from "konva";
import store from "@/store";
import * as wbDrawService from "@/services/wb/wbDrawService";
import * as wbZoomService from "@/services/wb/wbZoomService";
import * as wbFileService from "@/services/wb/wbFileService";
//import { throttle } from "lodash";

function getStageSize(
  availableWidth: number,
  availableHeight: number,
  pageWidth: number,
  pageHeight: number
): { width: number; height: number; scale: number } {
  const canvasRatio = pageWidth / pageHeight;

  if (availableWidth / availableHeight > canvasRatio) {
    //fit to height
    return {
      width: availableHeight * canvasRatio,
      height: availableHeight,
      scale: availableHeight / pageHeight
    };
  } else {
    //fit to width
    return {
      width: availableWidth,
      height: availableWidth / canvasRatio,
      scale: availableWidth / pageWidth
    };
  }
}

function CreateStage(
  containerId: string,
  availableWidth: number,
  availableHeight: number,
  pageWidth: number,
  pageHeight: number,
  drawActive = true,
  zoomActive = true
): Konva.Stage {
  const { width, height, scale } = getStageSize(
    availableWidth,
    availableHeight,
    pageWidth,
    pageHeight
  );

  const stageConfig = {
    width: width,
    height: height,
    scale: {
      x: scale,
      y: scale
    },
    container: containerId,
    draggable: true
  };

  const stage = new Konva.Stage(stageConfig);

  if (zoomActive) {
    store.commit("canvas/setBaseScale", scale);
    store.commit("canvas/seCurrentScale", scale);
    stage.on("wheel", e => {
      wbZoomService.zoomStage(stage, e);
    });
    stage.dragBoundFunc(wbZoomService.fixOutOfBoundStagePosition);
  }
  if (drawActive) {
    /*stage.on("pointerdown", (e)=>{
      if(e.evt.pointerType!="pen"){
        return wbDrawService.startDrawLine(e);
      }
    });*/
    stage.on("mousedown", e => {
      return wbDrawService.startDrawLine(e);
    });
    /*stage.on(
      "touchstart",
      throttle(e => {
        if (e.pointerId === 0) {
        return wbDrawService.startDrawLine(e);
        }
      }, 10)
    );*/
    stage.on("touchstart", e => {
      return wbDrawService.startDrawLine(e);
    });
    stage.on("mousemove", e => {
      return wbDrawService.drawLine(e);
    });
    /*stage.on(
      "touchmove",
      throttle(e => {
        if (e.pointerId === 0) {
          //console.log(e);
          return wbDrawService.drawLine(e);
        }
      }, 90)
    );*/

    stage.on("touchmove", e => {
      //console.log(e.evt.touches);
      return wbDrawService.drawLine(e);
    });

    //stage.on("mouseup touchend", e => {
    //console.log(e);
    //wbDrawService.endDrawLine();
    //});
    stage.on("mouseup touchend", wbDrawService.endDrawLine);
  }

  return stage;
}

function CreateLayer(): Konva.Layer {
  return new Konva.Layer();
}

async function createBackground(stage: Konva.Stage): Promise<Konva.Shape> {
  /* create a rect with teh same size of the stage, il will host background props
  fill: "#FFFF"
  fillPatternImage: 'http://image.com'
  fillPriority: "pattern/color"*/
  return new Konva.Rect({
    x: 0,
    y: 0,
    width: stage.width(),
    height: stage.height(),
    scaleX: 1 / stage.scaleX(),
    scaleY: 1 / stage.scaleY(),
    // remove background from hit graph for better perf
    // because we don't need any events on the background
    listening: false
  });
}

async function updateBackgroundImage(
  stage: Konva.Stage,
  background: Konva.Shape,
  src: string
): Promise<void> {
  const imageObj = new Image();
  imageObj.src = src;

  imageObj.onload = function() {
    background.fillPriority("pattern");
    background.fillPatternImage(imageObj);
    background.fillPatternRepeat("no-repeat");

    const imgW = imageObj.width;
    const imgH = imageObj.height;
    const stgH = stage.height();
    const stgW = stage.width();

    const canvasRatio = stgW / stgH;
    const imgRatio = imgW / imgH;

    //check if we need to fit vertically or horizontally
    const scale = imgRatio > canvasRatio ? stgH / imgH : stgW / imgW;

    // const calcHeight = imgH * scale;
    // const calcWidth = imgW * scale;

    //center the backgroung
    const offsetX = (imgW * scale - stgW) / 2 / scale;
    const offsetY = (imgH * scale - stgH) / 2 / scale;

    background.fillPatternOffset({ x: offsetX, y: offsetY });
    background.fillPatternScale({ x: scale, y: scale });
  };
}

function createImageTransformer(): Konva.Transformer {
  const imageTransformer = new Konva.Transformer({
    enabledAnchors: ["top-left", "top-right", "bottom-left", "bottom-right"]
  });
  imageTransformer.on("transformend", async () => {
    wbFileService.onTransformElement();
  });

  return imageTransformer;
}

export const wbCanvasService = {
  CreateStage,
  CreateLayer,
  createImageTransformer,
  createBackground,
  updateBackgroundImage
};

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "center-content",
    {
      attrs: { "light-bg": _vm.bgLight, "dark-bg": _vm.bgDark },
      scopedSlots: _vm._u([
        {
          key: "headerContent",
          fn: function() {
            return [
              _c(
                "div",
                {
                  staticClass: "d-flex justify-space-between align-center pa-6"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex cursor-pointer text-uppercase",
                      on: {
                        click: function($event) {
                          return _vm.$router.push("/room")
                        }
                      }
                    },
                    [
                      _c("va-icon", {
                        attrs: { icon: "va va-direction-left mr-4", size: "s" }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("rooms.backToRooms")) + " ")
                    ],
                    1
                  )
                ]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.room
        ? _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("rooms.roomSettingsFor")) +
                    " " +
                    _vm._s(_vm.room.name) +
                    " "
                )
              ]),
              _c(
                "v-card-text",
                { staticClass: "py-4" },
                [
                  _c(
                    "v-tabs",
                    { staticClass: "mt-2" },
                    [
                      _c("v-tab", [_vm._v(_vm._s(_vm.$t("shared.settings")))]),
                      _c("v-tab", [_vm._v(_vm._s(_vm.$t("rooms.log")))]),
                      _c("v-tab", [_vm._v(_vm._s(_vm.$t("rooms.members")))]),
                      _c("v-tab-item", { staticClass: "pt-6" }, [
                        _c("div", {}, [
                          _c(
                            "div",
                            { staticClass: "d-flex mb-7" },
                            [
                              _c("va-button", {
                                staticClass: "flex-grow-1",
                                attrs: {
                                  cta: _vm.$t("rooms.leaveRoom"),
                                  color: "error",
                                  type: "outline",
                                  icon: "va va-close-circle"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.leaveRoom(_vm.room)
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _vm.isManager
                            ? _c(
                                "div",
                                { staticClass: "mb-4" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c("avatar-upload", {
                                        staticClass: "mx-auto mb-4",
                                        attrs: { image: _vm.room.avatar },
                                        on: { fileChanged: _vm.onAvatarChanged }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-form",
                                    {
                                      ref: "editRoomForm",
                                      attrs: {
                                        "lazy-validation": "",
                                        onSubmit: "return false;"
                                      },
                                      on: {
                                        submit: function($event) {
                                          return _vm.editRoom(_vm.room)
                                        }
                                      },
                                      model: {
                                        value: _vm.valid,
                                        callback: function($$v) {
                                          _vm.valid = $$v
                                        },
                                        expression: "valid"
                                      }
                                    },
                                    [
                                      _c("va-text-field", {
                                        staticClass: "mb-6",
                                        attrs: {
                                          rules: [_vm.required],
                                          label: _vm.$t("rooms.roomName"),
                                          disabled:
                                            _vm.$store.getters["room/isLoading"]
                                        },
                                        model: {
                                          value: _vm.room.name,
                                          callback: function($$v) {
                                            _vm.$set(_vm.room, "name", $$v)
                                          },
                                          expression: "room.name"
                                        }
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "text-right" },
                                        [
                                          _c("va-button", {
                                            attrs: {
                                              disabled: !_vm.valid,
                                              submit: "",
                                              loading:
                                                _vm.$store.getters[
                                                  "room/isLoading"
                                                ],
                                              cta: _vm.$t("shared.save")
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ]),
                      _c(
                        "v-tab-item",
                        { staticClass: "pt-6" },
                        [_c("logs")],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { staticClass: "pt-6" },
                        [
                          _c("room-members", {
                            attrs: { "is-manager": _vm.isManager }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("va-loader", {
                staticClass: "mx-auto my-12",
                attrs: { size: "l", "is-loading": true, color: "primary" }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
















































import Vue from "vue";
import AuthContent from "@/components/templates/AuthContent.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import i18n from "@/plugins/lang";
import MetaHelper from "@/services/metaHelper";
import logger from "@/services/loggerService";
import validationMixin from "@/mixins/validationMixin";

export default Vue.extend({
  name: "SignIn",
  components: {
    VaButton,
    AuthContent,
    VaTextField
  },
  mixins: [validationMixin],
  meta() {
    return MetaHelper.buildMeta("resetPass");
  },
  data: () => ({
    pass: "",
    repeatPass: "",
    valid: true
  }),
  computed: {
    token(): string {
      return this.$store.getters["session/mfaToken"];
    }
  },
  methods: {
    async onReset() {
      if (
        (this.$refs.resetForm as Vue & { validate: () => boolean }).validate()
      ) {
        if (this.$route.query.mail && this.$route.query.reset) {
          await this.$store.dispatch("session/resetPasswordLegacy", {
            token: this.$route.query.token,
            password: this.pass,
            email: this.$route.query.email
          });
        } else {
          logger.error(`Malformed reset url: ${this.$route.fullPath}`);
          this.$store.commit(
            "notifications/displayNotification",
            {
              message: i18n.t("resetPassword.malformedUrl"),
              type: "error"
            },
            { root: true }
          );
        }
      }
    }
  }
});

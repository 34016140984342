










import Vue from "vue";
import { OauthProvider } from "@/models/oauth.interface";
import * as msal from "@azure/msal-browser";
import logger from "@/services/loggerService";
import { PublicClientApplication } from "@azure/msal-browser";

export default Vue.extend({
  name: "MicosoftSignIn",
  components: {},
  data() {
    return {
      msalInstance: (undefined as unknown) as PublicClientApplication
    };
  },
  computed: {
    providerType(): number {
      return OauthProvider.Microsoft;
    }
  },
  mounted() {
    const msalConfig = {
      auth: {
        clientId: process.env.VUE_APP_TEAMS_CLIENT_ID || "",
        redirectUri: window.location.href
      }
    };

    this.msalInstance = new msal.PublicClientApplication(msalConfig);
  },
  methods: {
    async handleSignIn() {
      try {
        const msalUser = await this.msalInstance.loginPopup({
          scopes: ["openid User.ReadBasic.All"],
          prompt: "select_account"
        });
        if (msalUser) await this.onSuccess(msalUser);
      } catch (e) {
        logger.error(e);
      }
    },
    //TODO: move in mixin
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    async onSuccess(microsoftUser: any) {
      this.$store.dispatch("session/federateLogin", {
        oauthProviderType: this.providerType,
        callbackUrl: window.location.href,
        authCode: microsoftUser.idToken
      });
    }
  }
});

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "pa-6" },
        [
          _c("v-card-title", { staticClass: "pa-6" }, [
            _c("h2", [_vm._v(_vm._s(_vm.$t("mfa.title")))])
          ]),
          _c(
            "v-card-text",
            { staticClass: "text-center ma-6" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("h3", [
                        _c("strong", [_vm._v("1")]),
                        _vm._v(" - " + _vm._s(_vm.$t("mfa.qrTitle")))
                      ]),
                      _c("small", { staticClass: "mt-4 d-block" }, [
                        _vm._v(_vm._s(_vm.$t("mfa.qrDescription")))
                      ]),
                      _c("qrcode-vue", {
                        staticClass: "d-flex justify-center my-6",
                        attrs: { value: _vm.setup.deepLink, size: "300" }
                      }),
                      _c("small", [
                        _c("span", { staticClass: "d-block" }, [
                          _vm._v(_vm._s(_vm.$t("mfa.qrTip")))
                        ]),
                        _c("span", { staticClass: "d-block" }, [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.setup.encodedSecret))
                          ])
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "mt-6 mt-md-0",
                      attrs: { cols: "12", md: "6" }
                    },
                    [
                      _c("h3", [
                        _c("strong", [_vm._v("2")]),
                        _vm._v(" - " + _vm._s(_vm.$t("mfa.qrTitle2")))
                      ]),
                      _c("va-text-field", {
                        staticClass: "mt-4",
                        attrs: {
                          rules: [_vm.required],
                          dense: "",
                          label: _vm.$t("mfa.insertCode")
                        },
                        model: {
                          value: _vm.code,
                          callback: function($$v) {
                            _vm.code = $$v
                          },
                          expression: "code"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { color: "secondary", outlined: "" },
                  on: {
                    click: function() {
                      return _vm.$emit("click")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("shared.dismiss")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", loading: _vm.enabling },
                  on: { click: _vm.enableMFA }
                },
                [_vm._v(" " + _vm._s(_vm.$t("shared.confirm")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import i18n from "@/plugins/lang";
import tenantService from "@/services/tenantService";

interface MetaProperty {
  charset?: string;
  name?: string;
  content?: string;
}

interface Meta {
  title?: string;
  titleTemplate?: string;
  htmlAttrs: {
    lang?: string;
    amp?: boolean;
  };
  bodyAttrs: {
    class?: Array<string>;
  };
  meta: Array<MetaProperty>;
}

const defaultOptions: Meta = {
  title: tenantService.getTenantProductName(),
  titleTemplate: `%s | ${tenantService.getTenantProductName()}`,
  htmlAttrs: {
    lang: "en",
    amp: true
  },
  bodyAttrs: {
    class: []
  },
  meta: [
    { charset: "utf-8" },
    { name: "viewport", content: "width=device-width,initial-scale=1.0" }
  ]
};

interface MetaOptions {
  title: string;
  titleTemplate?: string;
  description?: string;
}

export default {
  buildMeta(page?: string): Meta {
    const options: Meta = { ...defaultOptions };
    if (!page) return options;
    const pageOptions: MetaOptions = (i18n.t(
      "meta." + page
    ) as unknown) as MetaOptions;
    if (pageOptions.description) {
      options.meta.push({
        name: "description",
        content: pageOptions.description
      });
      delete pageOptions.description;
    }
    options.htmlAttrs.lang = i18n.locale;
    return {
      ...options,
      ...pageOptions
    };
  }
};

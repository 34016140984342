var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      on: {
        keyup: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.onSendMessage.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "div",
        {
          ref: "messages_wrapper",
          staticClass: "overflow-y-auto",
          staticStyle: { "max-height": "220px" }
        },
        [
          _vm._l(_vm.messages, function(message, n) {
            return _c(
              "va-chat-message",
              {
                key: n,
                attrs: {
                  "is-owner": message.user.userId === _vm.user.userId,
                  image: message.user.avatar,
                  sender: message.user.firstName + " " + message.user.lastName,
                  date: message.creationDate
                }
              },
              [_vm._v(" " + _vm._s(message.message) + " ")]
            )
          }),
          _c("div", { ref: "messages_bottom" })
        ],
        2
      ),
      _c("va-text-field", {
        attrs: {
          name: "chatbox",
          label: _vm.$t("rooms.chatLabel"),
          appendIcon: ""
        },
        model: {
          value: _vm.message,
          callback: function($$v) {
            _vm.message = $$v
          },
          expression: "message"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
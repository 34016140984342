import { Stroke, StrokeDeserializer } from "@/models/wb/stroke.interface";
import {
  CanvasFile,
  ElementDeserializer
} from "@/models/wb/canvasFile.interface";
import { Size, SizeDeserializer } from "@/models/wb/size.interface";
import { emptyFileId } from "@/services/configService";

export interface Page {
  uuid: string;
  background: {
    color: string;
    image?: string;
  };
  creationDate?: Date;
  files: CanvasFile[];
  meetingId: string;
  size?: Size;
  strokes: Stroke[];
  thumbFileId: string;
  thumbnailUrl: string;
}

export interface SocketPageElement {
  pageKey: string;
  elementKey: string;
  fileId: string;
  pdfFileId: string;
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export async function PageDeserializer(input: any, id = ""): Promise<Page> {
  const files = [] as CanvasFile[];
  for (const key of Object.keys(input.Elements)) {
    const rawElement = input.Elements[key];
    rawElement.uuid = key;
    if (rawElement.FileId !== emptyFileId) {
      const el = await ElementDeserializer(rawElement);
      if (el) {
        files.push(el);
      }
    }
  }

  const strokes =
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
    input.Strokes?.map((rawStroke: any): Stroke | undefined => {
      return rawStroke ? StrokeDeserializer(rawStroke) : undefined;
    }) || [];
  const background = {
    color: input.Background || "#FFFFFF",
    image: input.BackgroundFileId
  };

  return {
    uuid: id,
    background: background,
    creationDate: new Date(input.CreationDate),
    files: files,
    meetingId: input.MeetingId || "",
    size: SizeDeserializer(input.Size.toString()),
    strokes: strokes,
    thumbFileId: input.ThumbFileId || "",
    thumbnailUrl: ""
  };
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-6" },
    [
      _c("div", { staticClass: "display-2 my-4" }, [_vm._v("Showcase")]),
      _c("LightSwitcher", { staticClass: "top-right mr-6 mt-6" }),
      _c(
        "v-tabs",
        [
          _c("v-tab", [_vm._v("atoms")]),
          _c("v-tab", [_vm._v("molecules")]),
          _c("v-tab", [_vm._v("templates")]),
          _c(
            "v-tab-item",
            { staticClass: "pt-6" },
            [
              _c("typography"),
              _c("chips"),
              _c("icons"),
              _c("avatar"),
              _c("buttons"),
              _c("text-field")
            ],
            1
          ),
          _c(
            "v-tab-item",
            { staticClass: "pt-6" },
            [
              _c("log-message"),
              _c("badge"),
              _c("chat-message"),
              _c("notification")
            ],
            1
          ),
          _c(
            "v-tab-item",
            { staticClass: "pt-6" },
            [
              _c("router-link", { attrs: { to: "/design/maincontent" } }, [
                _vm._v("main view")
              ]),
              _c("br"),
              _c("router-link", { attrs: { to: "/design/authcontent" } }, [
                _vm._v("auth view")
              ]),
              _c("br"),
              _c(
                "router-link",
                { attrs: { to: "/design/conferencecontent" } },
                [_vm._v("conference view")]
              ),
              _c("br")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
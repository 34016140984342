import { MeetingUserDeserializer, User } from "@/models/user.interface";

export interface ConferenceAuthData {
  expires: Date;
  meetingServerUrl: string;
  token: string;
  user: User;
  meetingId: string;
  pin: string;
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function ConferenceAuthDataDeserializer(input: any): ConferenceAuthData {
  const expires = new Date(input.ExpirationDate || "");
  const user = MeetingUserDeserializer(input.User);
  let meetingServerUrl = input.MeetingServerUrl || "";
  //clean trailing slashes
  if (meetingServerUrl.endsWith("/"))
    meetingServerUrl = meetingServerUrl.slice(0, -1);
  return {
    expires: expires,
    meetingServerUrl: meetingServerUrl,
    token: input.Token || "",
    user: user,
    meetingId: input.meetingId || "",
    pin: input.pin || ""
  };
}

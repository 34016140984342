





























import Vue from "vue";
import VaButton from "@/components/atoms/VaButton.vue";
import { User } from "@/models/user.interface";
import VaBadge from "@/components/molecules/VaBadge.vue";

export default Vue.extend({
  props: {
    icon: {
      type: String,
      required: false
    }
  },
  components: {
    VaBadge,
    VaButton
  },
  data: () => ({
    currentUser: null,
    search: null
  }),
  mounted() {
    this.$store.commit("users/clearSearchResults");
  },
  computed: {
    isLoading(): boolean {
      return this.$store.getters["users/isLoading"];
    },
    entries(): { text: string; value: User }[] {
      const res = this.$store.getters["users/searchResults"];
      return res.map((user: User) => {
        return {
          text: user.firstName + " " + user.lastName,
          value: user
        };
      });
    }
  },
  watch: {
    async search(val) {
      if (val?.length > 3 && !this.isLoading) {
        this.$store.dispatch("users/searchUsers", val);
      }
    }
  },
  methods: {
    onSelect(val: string) {
      this.$emit("userSelected", val);
      this.$store.commit("users/clearSearchResults");
    }
  }
});

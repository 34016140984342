var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Va-Icon")]),
      _c("h3", [_vm._v("Colors")]),
      _c("v-divider", { staticClass: "mb-4" }),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between  my-4" },
        [
          _c("va-icon", { attrs: { icon: "fas fa-arrow-circle-up" } }),
          _c("va-icon", {
            attrs: { icon: "fas fa-arrow-circle-up", color: "primary" }
          }),
          _c("va-icon", {
            attrs: { icon: "fas fa-arrow-circle-up", color: "secondary" }
          }),
          _c("va-icon", {
            attrs: { icon: "fas fa-arrow-circle-up", color: "success" }
          }),
          _c("va-icon", {
            attrs: { icon: "fas fa-arrow-circle-up", color: "warning" }
          }),
          _c("va-icon", {
            attrs: { icon: "fas fa-arrow-circle-up", color: "error" }
          }),
          _c("va-icon", {
            attrs: { icon: "fas fa-arrow-circle-up", square: "" }
          }),
          _c("va-icon", {
            attrs: {
              icon: "fas fa-arrow-circle-up",
              square: "",
              color: "primary"
            }
          }),
          _c("va-icon", {
            attrs: {
              icon: "fas fa-arrow-circle-up",
              square: "",
              color: "secondary"
            }
          }),
          _c("va-icon", {
            attrs: {
              icon: "fas fa-arrow-circle-up",
              square: "",
              color: "success"
            }
          }),
          _c("va-icon", {
            attrs: {
              icon: "fas fa-arrow-circle-up",
              square: "",
              color: "warning"
            }
          }),
          _c("va-icon", {
            attrs: {
              icon: "fas fa-arrow-circle-up",
              square: "",
              color: "error"
            }
          })
        ],
        1
      ),
      _c("h3", [_vm._v("Size")]),
      _c("v-divider", { staticClass: "mb-4" }),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between  my-4" },
        [
          _c("va-icon", { attrs: { icon: "fas fa-arrow-circle-up" } }),
          _c("va-icon", {
            attrs: { icon: "fas fa-arrow-circle-up", size: "xs" }
          }),
          _c("va-icon", {
            attrs: { icon: "fas fa-arrow-circle-up", size: "s" }
          }),
          _c("va-icon", {
            attrs: { icon: "fas fa-arrow-circle-up", size: "m" }
          }),
          _c("va-icon", {
            attrs: { icon: "fas fa-arrow-circle-up", size: "l" }
          }),
          _c("va-icon", {
            attrs: { icon: "fas fa-arrow-circle-up", square: "" }
          }),
          _c("va-icon", {
            attrs: { icon: "fas fa-arrow-circle-up", square: "", size: "xs" }
          }),
          _c("va-icon", {
            attrs: { icon: "fas fa-arrow-circle-up", square: "", size: "s" }
          }),
          _c("va-icon", {
            attrs: { icon: "fas fa-arrow-circle-up", square: "", size: "m" }
          }),
          _c("va-icon", {
            attrs: { icon: "fas fa-arrow-circle-up", square: "", size: "l" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "logs_wrapper",
      staticClass: "overflow-y-auto",
      staticStyle: { "max-height": "260px" }
    },
    [
      _vm._l(_vm.messages, function(message, n) {
        return _c(
          "va-log-message",
          {
            key: n,
            attrs: {
              sender: message.user.firstName + " " + message.user.lastName,
              date: message.creationDate,
              type: message.notificationType
            }
          },
          [_vm._v(" " + _vm._s(message.message) + " ")]
        )
      }),
      _c("div", { ref: "logs_bottom" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
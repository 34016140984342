

















































import Vue from "vue";
import VaButton from "@/components/atoms/VaButton.vue";
import HexColorSelector from "@/components/molecules/HexColorSelector.vue";
import store from "@/store";
import { FileDetails } from "@/models/wb/canvasFile.interface";
import iconcolorMixin from "@/mixins/iconcolorMixin";

export default Vue.extend({
  name: "TextBoxDetail",
  components: { HexColorSelector, VaButton },
  mixins: [iconcolorMixin],
  data: () => ({
    loading: false,
    details:
      store.getters["wb/files/selectedImage"].file.details ||
      ({
        foreground: "",
        background: "",
        text: ""
      } as FileDetails),
    changed: false
  }),
  methods: {
    changeForeground(event: string): void {
      this.changed = true;
      this.details.foreground = event;
    },
    changeBackground(event: string): void {
      this.changed = true;
      this.details.background = event;
    },
    async closeDetail() {
      await store.dispatch("wb/files/unfocusImage");
    },
    async updateTextBox(): Promise<void> {
      this.loading = true;
      if (this.changed) {
        const element = store.getters["wb/files/selectedImage"];
        element.file.details = this.details;
        await store.dispatch("wb/files/updatedTextElement", element);
      }
    }
  }
});

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "center-content",
    {
      attrs: { "light-bg": _vm.bgLight, "dark-bg": _vm.bgDark },
      scopedSlots: _vm._u([
        {
          key: "headerContent",
          fn: function() {
            return [
              _c("div", { staticClass: "d-flex align-center pa-6" }, [
                _c(
                  "div",
                  {
                    staticClass: "d-flex cursor-pointer text-uppercase",
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/room")
                      }
                    }
                  },
                  [
                    _c("va-icon", {
                      attrs: { icon: "va va-direction-left mr-4", size: "s" }
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("shared.back")) + " ")
                  ],
                  1
                )
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "pa-6" },
        [
          _c("h2", { staticClass: "mb-4" }, [
            _vm._v(_vm._s(_vm.$t("rooms.newRoom")))
          ]),
          _vm.canCreateRoom
            ? _c(
                "v-form",
                {
                  ref: "newRoomForm",
                  attrs: { "lazy-validation": "", onSubmit: "return false;" },
                  on: { submit: _vm.createRoom },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c("va-text-field", {
                    staticClass: "mb-6",
                    attrs: {
                      rules: [_vm.required],
                      label: _vm.$t("rooms.name"),
                      name: "room-name"
                    },
                    model: {
                      value: _vm.name,
                      callback: function($$v) {
                        _vm.name = $$v
                      },
                      expression: "name"
                    }
                  }),
                  _c("user-autocomplete", {
                    staticClass: "mb-1",
                    attrs: { icon: "va va-plus" },
                    on: { userSelected: _vm.onSelectUser }
                  }),
                  _c("v-virtual-scroll", {
                    attrs: {
                      items: _vm.users,
                      "item-height": "73",
                      height: _vm.scrollerHeight
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "div",
                                {
                                  key: item.userId,
                                  staticClass:
                                    "py-4 border-b d-flex align-center "
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex-grow-1 cursor-pointer"
                                    },
                                    [
                                      _c("va-badge", {
                                        attrs: {
                                          image: item.avatar,
                                          text:
                                            (item.firstName || item.email) +
                                            " " +
                                            (item.lastName || "")
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  item.userId !== _vm.user.userId
                                    ? _c("va-button", {
                                        attrs: {
                                          color: "error",
                                          type: "square",
                                          icon: "va va-trash"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.onRemoveUser(item)
                                          }
                                        }
                                      })
                                    : _c("va-button", {
                                        staticClass: "cursor-default",
                                        attrs: {
                                          color: "secondary",
                                          type: "outline",
                                          cta: _vm.$t("rooms.manager")
                                        }
                                      })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3186043895
                    )
                  }),
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("va-button", {
                        staticClass: "flex-grow-1",
                        attrs: {
                          disabled: !_vm.valid,
                          submit: "",
                          loading: _vm.$store.getters["session/isLoading"],
                          cta: _vm.$t("rooms.create")
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
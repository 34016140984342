

























import Vue from "vue";
import VaButton from "@/components/atoms/VaButton.vue";
import { HTMLInputEvent } from "@/models/htmlInputEvent.interface";
import * as wbFileService from "@/services/wb/wbFileService";
import iconcolorMixin from "@/mixins/iconcolorMixin";
import store from "@/store";
import i18n from "@/plugins/lang";

export default Vue.extend({
  components: {
    VaButton
  },
  data: () => ({}),
  mixins: [iconcolorMixin],
  computed: {},
  methods: {
    onUploadFile() {
      const element = this.$refs.uploader as HTMLElement;
      element.click();
    },
    async handleUpload(event: HTMLInputEvent) {
      if (event?.target?.files?.length) {
        // this.$store.commit("wb/files/setAssetsLoading", true);
        const file = event.target.files[0];
        if (file.size > 1048576 * 15) {
          //5mb
          store.commit("notifications/displayConfirmDialog", {
            visible: true,
            title: i18n.t("rooms.largeFileTitle"),
            description: i18n.t("rooms.largeFileDescription"),
            ok: "",
            ko: "",
            callback: async () => {
              await this.uploadFile(file);
            }
          });
        } else {
          await this.uploadFile(file);
        }
      }
    },
    async uploadFile(file: File) {
      const fileBlob = URL.createObjectURL(file);
      await wbFileService.uploadFile(fileBlob, file.name);
      if (this.$refs.uploader) {
        (this.$refs.uploader as HTMLInputElement).value = "";
      }
    }
  }
});

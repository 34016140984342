var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "btn__chat__holder" },
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    _vm._g({}, on),
                    [
                      _c(
                        "v-badge",
                        {
                          attrs: {
                            color: "error",
                            overlap: "",
                            content: 1,
                            value: _vm.isActivePoll
                          }
                        },
                        [
                          _c(
                            "va-button",
                            {
                              attrs: {
                                color: _vm.displayPoll
                                  ? "primary"
                                  : _vm.iconColor,
                                type: "squareText"
                              },
                              on: {
                                click: function($event) {
                                  _vm.displayPoll = !_vm.displayPoll
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("va va-survey")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("poll.poll")))])]
      ),
      _vm.displayPoll
        ? _c(
            "div",
            {
              staticClass:
                "border-a border-ra background-base pa-0 ml-4 mb-6 bottom-left popup-box elevation-16",
              attrs: { id: "popup__poll" }
            },
            [
              _vm.isActivePoll
                ? _c(
                    "v-stepper",
                    {
                      model: {
                        value: _vm.stepCounter,
                        callback: function($$v) {
                          _vm.stepCounter = $$v
                        },
                        expression: "stepCounter"
                      }
                    },
                    [
                      _c(
                        "v-stepper-header",
                        _vm._l(_vm.poll.items, function(step, i) {
                          return _c(
                            "v-stepper-step",
                            {
                              key: i,
                              attrs: {
                                complete: _vm.stepCounter > i + 1,
                                step: i + 1
                              }
                            },
                            [_vm._v(" " + _vm._s(step.question) + " ")]
                          )
                        }),
                        1
                      ),
                      _c(
                        "v-stepper-items",
                        _vm._l(_vm.poll.items, function(step, i) {
                          return _c(
                            "v-stepper-content",
                            { key: i, attrs: { step: i + 1 } },
                            [
                              _c(
                                "div",
                                { staticClass: "mb-12" },
                                [
                                  _c("div", [
                                    _c("h2", [_vm._v(_vm._s(step.question))]),
                                    _c("div", { staticClass: "mt-2 mb-3" }, [
                                      _vm._v(_vm._s(step.questionDescription))
                                    ])
                                  ]),
                                  !step.isMultiple && !step.isOpen
                                    ? _c(
                                        "v-radio-group",
                                        {
                                          staticClass: "ml-3",
                                          model: {
                                            value: step.answerId,
                                            callback: function($$v) {
                                              _vm.$set(step, "answerId", $$v)
                                            },
                                            expression: "step.answerId"
                                          }
                                        },
                                        _vm._l(step.allowedResponse, function(
                                          response
                                        ) {
                                          return _c("v-radio", {
                                            key: response.id,
                                            attrs: {
                                              label: response.response,
                                              value: response.id
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    : _vm._e(),
                                  step.isMultiple
                                    ? _c(
                                        "div",
                                        _vm._l(step.allowedResponse, function(
                                          response
                                        ) {
                                          return _c(
                                            "div",
                                            { key: response.id },
                                            [
                                              _c("v-checkbox", {
                                                staticClass: "mb-n8 ml-3",
                                                attrs: {
                                                  label: response.response,
                                                  value: response.id
                                                },
                                                model: {
                                                  value: step.answerMultiple,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      step,
                                                      "answerMultiple",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "step.answerMultiple"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        }),
                                        0
                                      )
                                    : _vm._e(),
                                  step.isOpen
                                    ? _c("va-text-area", {
                                        model: {
                                          value: step.answerText,
                                          callback: function($$v) {
                                            _vm.$set(step, "answerText", $$v)
                                          },
                                          expression: "step.answerText"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              i + 1 < _vm.poll.items.length
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "float-right",
                                      attrs: { color: "primary" },
                                      on: { click: _vm.onNext }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("poll.next")) + " "
                                      )
                                    ]
                                  )
                                : _c(
                                    "v-btn",
                                    {
                                      staticClass: "float-right",
                                      attrs: { color: "primary" },
                                      on: { click: _vm.onFinish }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("poll.finish")) +
                                          " "
                                      )
                                    ]
                                  ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "float-right mr-4",
                                  attrs: { color: "primary", disabled: i == 0 },
                                  on: { click: _vm.onPrev }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("poll.prev")) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _c("div", { staticClass: "pa-4" }, [
                    _c("h3", { staticClass: "mb-2" }, [
                      _vm._v(_vm._s(_vm.$t("poll.poll")))
                    ]),
                    _vm._v(" " + _vm._s(_vm.$t("poll.noActivePoll")) + " ")
                  ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }





























import Vue from "vue";
import { User } from "@/models/user.interface";
import { ChatMessage } from "@/models/chatMessage.interface";
import VaChatMessage from "@/components/molecules/VaChatMessage.vue";
import { Room } from "@/models/room.interface";
import store from "@/store";
import VaTextField from "@/components/atoms/VaTextField.vue";

export default Vue.extend({
  components: {
    VaChatMessage,
    VaTextField
  },
  data: () => ({
    message: ""
  }),
  computed: {
    user(): User {
      return this.$store.getters["users/user"];
    },
    messages(): ChatMessage[] {
      return this.$store.getters["chat/messages"];
    },
    pageHeight(): number {
      return document.body.scrollHeight;
    },
    room(): Room {
      return this.$store.getters["room/activeRoom"];
    }
  },
  updated() {
    this.scrollBottom();
  },
  mounted() {
    this.scrollBottom();
  },
  watch: {
    room: function(newVal) {
      if (newVal) {
        store.dispatch("chat/init");
      }
    }
  },
  methods: {
    scrollBottom() {
      this.$vuetify.goTo(this.$refs.messages_bottom as Vue, {
        container: this.$refs.messages_wrapper as Vue
      });
    },
    onSendMessage() {
      this.$store.dispatch("chat/sendMessage", this.message);
      this.message = "";
    }
  }
});

import Vue from "vue";
import VueI18n from "vue-i18n";

import enLocale from "@/plugins/lang/en";
import itLocale from "@/plugins/lang/it";

import { Dictionary } from "@/models/genericTypes";

Vue.use(VueI18n);

const messages = {
  en: {
    ...enLocale
  },
  it: {
    ...itLocale
  }
};

const getLocale = (): string => {
  const loc = navigator.language || "en";
  return loc.split("-")[0];
};

const i18n = new VueI18n({
  locale: getLocale(),
  messages,
  silentFallbackWarn: false
});

export default i18n;

export const setLocales = (lang: string, messages: Dictionary): void => {
  i18n.setLocaleMessage(lang, messages);
};

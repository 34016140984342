














































import Vue from "vue";
import { MfaProvider } from "@/models/mfaProvider";
import VaButton from "@/components/atoms/VaButton.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import validationMixin from "@/mixins/validationMixin";

export default Vue.extend({
  name: "MfaLogin",
  components: { VaTextField, VaButton },
  mixins: [validationMixin],
  data() {
    return {
      valid: false,
      verificationCode: ""
    };
  },
  beforeMount() {
    this.$store.dispatch("mfa/resetMfaFlow");
  },
  methods: {
    async onSubmit(): Promise<void> {
      await this.$store.dispatch("session/mfaLogin", {
        token: this.token,
        code: this.verificationCode
      });
    }
  },
  computed: {
    enabledProviders(): MfaProvider[] {
      return this.$store.getters["mfa/enabledProviders"];
    },
    token(): string {
      return this.$store.getters["session/mfaToken"];
    },
    selectedProvider(): MfaProvider {
      return this.$store.getters["mfa/selectedProvider"];
    }
  },
  watch: {
    token(newVal) {
      if (newVal) this.$store.dispatch("mfa/fetchProvidersChallenge", newVal);
    }
  }
});












































































































































































































































































import Vue from "vue";
import VaButton from "@/components/atoms/VaButton.vue";

export default Vue.extend({
  name: "Buttons",
  components: {
    VaButton
  }
});

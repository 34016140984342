import { RouteConfig } from "vue-router";
import DesignSystem from "@/components/_showcase/DesignSystem.vue";

export const designSystemRoutes: Array<RouteConfig> = [
  /**  -----  design system links ----- **/
  {
    path: "/design",
    name: "DesignSystem",
    component: DesignSystem
  },
  {
    path: "/design/maincontent",
    name: "templateMain",
    component: () => import("@/components/_showcase/templates/_main.vue")
  },
  {
    path: "/design/authcontent",
    name: "templateAuth",
    component: () => import("@/components/_showcase/templates/_auth.vue")
  },
  {
    path: "/design/conferencecontent",
    name: "templateConference",
    component: () => import("@/components/_showcase/templates/_conference.vue")
  },
  {
    path: "/design*",
    redirect: "/design"
  }
];

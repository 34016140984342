var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: { top: "", "nudge-top": 10, "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                return [
                  _c("va-button", {
                    attrs: {
                      icon: _vm.icon,
                      color: _vm.iconColor,
                      btnClass: _vm.btnClass,
                      type: "square"
                    },
                    on: {
                      click: function($event) {
                        _vm.open = !_vm.open
                      }
                    }
                  })
                ]
              }
            }
          ]),
          model: {
            value: _vm.open,
            callback: function($$v) {
              _vm.open = $$v
            },
            expression: "open"
          }
        },
        _vm._l(_vm.availableColors, function(availableColor, i) {
          return _c(
            "v-list-item",
            {
              key: i,
              on: {
                click: function($event) {
                  return _vm.onSelectColor(availableColor)
                }
              }
            },
            [
              _c("va-button", {
                attrs: {
                  icon: "va va-circle-fill",
                  color: availableColor,
                  type: "icon"
                }
              })
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
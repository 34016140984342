



























import Vue from "vue";
import VaAvatar from "@/components/atoms/VaAvatar.vue";
import VaIcon from "@/components/atoms/VaIcon.vue";
import { HTMLInputEvent } from "@/models/htmlInputEvent.interface";

export default Vue.extend({
  components: {
    VaAvatar,
    VaIcon
  },
  props: {
    image: {
      type: String,
      required: true
    }
  },
  data: () => ({
    newAvatar: ""
  }),
  computed: {
    avatar(): string {
      if (this.newAvatar == "") {
        return this.image || "";
      }
      return this.newAvatar;
    }
  },
  methods: {
    onUpdateAvatar() {
      const element = this.$refs.uploader as HTMLElement;
      element.click();
    },
    updateFile(event: HTMLInputEvent) {
      if (event?.target?.files?.length) {
        const file = event.target.files[0];
        this.newAvatar = URL.createObjectURL(file);
        this.$emit("fileChanged", file);
      }
    }
  }
});

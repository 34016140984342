



































































import Vue from "vue";
import AuthContent from "@/components/templates/AuthContent.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import MetaHelper from "@/services/metaHelper";
import MfaLogin from "@/components/organisms/auth/MfaLogin.vue";
import validationMixin from "@/mixins/validationMixin";
import VaLogMessage from "@/components/molecules/VaLogMessage.vue";
import VaLoader from "@/components/atoms/VaLoader.vue";

export default Vue.extend({
  name: "SignIn",
  components: {
    VaLoader,
    VaLogMessage,
    MfaLogin,
    VaButton,
    AuthContent,
    VaTextField
  },
  mixins: [validationMixin],
  meta() {
    return MetaHelper.buildMeta("signUp");
  },
  data: () => ({
    pass: "",
    repeatPass: "",
    valid: true
  }),
  computed: {
    pending(): boolean {
      return this.$store.getters["session/signupPending"];
    },
    error(): boolean {
      return this.$store.getters["session/signupError"];
    },
    success(): boolean {
      return this.$store.getters["session/signupSuccess"];
    },
    token(): string {
      return this.$store.getters["session/mfaToken"];
    }
  },
  mounted() {
    this.$store.commit("session/setSignupPending", true);
    this.onConfirm();
  },
  methods: {
    async onConfirm(): Promise<void> {
      if (
        this.$route.query.email &&
        this.$route.query.token &&
        this.$route.query.reset
      ) {
        await this.$store.dispatch("session/confirmEmailLegacy", {
          email: this.$route.query.email,
          token: this.$route.query.token as string
        });
      } else {
        this.$store.commit("session/setSignupError", true);
      }
    },
    async onSubmit() {
      if (
        (this.$refs.confirmForm as Vue & {
          validate: () => boolean;
        }).validate() &&
        this.$route.query.email &&
        this.$route.query.reset
      ) {
        await this.$store.dispatch("session/resetPasswordLegacy", {
          token: this.$route.query.reset,
          password: this.pass,
          email: this.$route.query.email
        });
      }
    }
  }
});

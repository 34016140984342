var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "textBoxDetail" } },
    [
      _c("v-card", {}, [
        _c(
          "div",
          { staticClass: "d-flex align-end flex-column" },
          [
            _c("va-button", {
              staticClass: "ml-auto mr-2",
              attrs: {
                icon: "va va-close-circle",
                color: _vm.iconColor,
                type: "squareText",
                disabled: _vm.loading
              },
              on: { click: _vm.closeDetail }
            })
          ],
          1
        ),
        _c("div", { staticClass: "textContent__wrapper mx-4" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.details.text,
                expression: "details.text"
              }
            ],
            staticClass: "textContent__input",
            style:
              "backgroundColor: " +
              _vm.details.background +
              "; color: " +
              _vm.details.foreground,
            domProps: { value: _vm.details.text },
            on: {
              change: function($event) {
                _vm.changed = true
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.details, "text", $event.target.value)
              }
            }
          })
        ]),
        _c(
          "div",
          { staticClass: "d-flex px-4 pb-4 mt-4" },
          [
            _c("hex-color-selector", {
              staticClass: "mr-4",
              attrs: { icon: "va va-fill" },
              on: {
                selectColor: function($event) {
                  return _vm.changeBackground($event)
                }
              }
            }),
            _c("hex-color-selector", {
              attrs: { icon: "va va-font" },
              on: {
                selectColor: function($event) {
                  return _vm.changeForeground($event)
                }
              }
            }),
            _c("v-spacer"),
            _c("va-button", {
              attrs: {
                type: "square",
                color: _vm.iconColor,
                icon: "va va-save",
                cta: _vm.$t("shared.save"),
                loading: _vm.loading
              },
              on: { click: _vm.updateTextBox }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
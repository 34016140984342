import Vue from "vue";
import router from "@/router";
import VueGtm from "vue-gtm";

if (
  !process.env.VUE_APP_EXCLUDE_EXTERNAL_SERVICES ||
  process.env.VUE_APP_EXCLUDE_EXTERNAL_SERVICES === "false"
) {
  try {
    Vue.use(VueGtm, {
      id: process.env.VUE_APP_GTM_ID,
      debug: process.env.VUE_APP_GTM_DEBUG,
      vueRouter: router
    });
  } catch (e) {
    /* eslint-disable no-console */
    console.error("Error on GTM startup");
    console.log(e);
  }
}



























































































import Vue from "vue";
import VaAvatar from "@/components/atoms/VaAvatar.vue";

export default Vue.extend({
  name: "Buttons",
  components: {
    VaAvatar
  },
  data() {
    return {
      toggle: true,
      images: [
        "https://secure.gravatar.com/avatar/079380eec7864703303fb51d924c23db?s=80",
        "https://gitlab.com/uploads/-/system/user/avatar/6457113/avatar.png?width=80",
        "https://gitlab.com/uploads/-/system/user/avatar/3869662/avatar.png?width=90",
        "https://secure.gravatar.com/avatar/3fba030b6aceed03de7fce5ab2288601?s=80",
        "https://remago.com/img/logo-full.941bca57.svg",
        require("@/assets/img/logos/Mago/logo-square.svg")
      ]
    };
  }
});

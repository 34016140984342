var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Va-LogMessage")]),
      _c("v-divider", { staticClass: "mb-4" }),
      _c(
        "div",
        { staticClass: " my-4" },
        [
          _c(
            "va-log-message",
            { attrs: { sender: "Mago User", date: new Date(), type: "info" } },
            [
              _vm._v(" msg with "),
              _c("em", [_vm._v("some "), _c("b", [_vm._v("formatting")])])
            ]
          ),
          _c(
            "va-log-message",
            {
              attrs: {
                sender: "Marco Zuccaroli",
                date: new Date(),
                type: "success"
              }
            },
            [_vm._v(" " + _vm._s(_vm.loremIpsum.substring(0, 100)) + " ")]
          ),
          _c(
            "va-log-message",
            {
              attrs: {
                sender: "Marco Zuccaroli",
                date: new Date(),
                type: "warning"
              }
            },
            [_vm._v(" " + _vm._s(_vm.loremIpsum.substring(0, 80)) + " ")]
          ),
          _c(
            "va-log-message",
            {
              attrs: {
                sender: "Luca Terzaghi",
                date: new Date(),
                type: "error"
              }
            },
            [_vm._v(" " + _vm._s(_vm.loremIpsum) + " ")]
          ),
          _c(
            "va-log-message",
            { attrs: { sender: "Marco Zuccaroli", date: new Date() } },
            [_vm._v("box without type")]
          ),
          _c("va-log-message", { attrs: { sender: "Test", type: "info" } }, [
            _vm._v("box without date")
          ]),
          _c("va-log-message", { attrs: { date: new Date(), type: "info" } }, [
            _vm._v("box without sender")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
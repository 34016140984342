var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    _vm._g({}, on),
                    [
                      _c("va-button", {
                        attrs: {
                          icon: "va va-add-file",
                          color: _vm.iconColor,
                          type: "squareText"
                        },
                        on: { click: _vm.onUploadFile }
                      })
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("rooms.insertFile")))])]
      ),
      _c("input", {
        ref: "uploader",
        staticClass: "d-none",
        attrs: {
          type: "file",
          accept: "image/*,.pdf,.glb,video/*,.docx,.doc,.pptx,.ppt,.xls,.xlsx"
        },
        on: { change: _vm.handleUpload }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
//Sentry integration
import Vue from "vue";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

if (
  !process.env.VUE_APP_EXCLUDE_EXTERNAL_SERVICES ||
  process.env.VUE_APP_EXCLUDE_EXTERNAL_SERVICES === "false"
) {
  try {
    Sentry.init({
      dsn: process.env.VUE_APP_SENTRY_DSN,
      autoSessionTracking: true,
      release: "MagoApp@" + process.env.VUE_APP_VERSION,
      integrations: [
        new Integrations.BrowserTracing({
          // eslint-disable-next-line
          // @ts-ignore
          Vue,
          logErrors: true,
          attachProps: true
        })
      ],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
      environment: process.env.NODE_ENV
    });
  } catch (e) {
    /* eslint-disable no-console */
    console.error("Error on sentry startup");
    console.log(e);
  }
}

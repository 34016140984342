import { Point, PointDeserializer } from "@/models/wb/point.interface";

export interface Stroke {
  color: string;
  width: number;
  height: number;
  isHighlighter?: boolean;
  points?: Point[];
  flattenedPoints: number[];
  mode?: "marker" | "eraser" | "draw";
}

export interface SocketStroke {
  Id: string; //line id - don't remove it's necessary for windows client
  P?: string[]; //points
  R: string; //container width & height
  S: number; //stroke state - unuseful?
  M: number; //mode "marker" | "eraser" | "draw"
  D: string; //stroke thickness
  C: string; //color
  T?: string; //container tag - bisogna capire a cosa serve
}

// fix wrong color passed by API
// need to remove the first two characters OMG WHI???
export function fixColor(c?: string | undefined): string {
  if (!c) return "#000000";
  return `#${c.substring(3)}`;
}

function getStrokeMode(mode: number): "marker" | "eraser" | "draw" {
  if (mode === 20) return "marker";
  if (mode === 13) return "eraser";
  return "draw";
}

function flattenPoints(points: Point[]): number[] {
  const flatPoints = [] as number[];
  points.map((point: Point) => {
    flatPoints.push(point.x);
    flatPoints.push(point.y);
  });
  return flatPoints;
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function StrokeDeserializer(input: any): Stroke {
  if (input.DrawingAttributes) {
    const points = input.StylusPoints?.map(PointDeserializer) || [];
    const flatPoints = flattenPoints(points);
    return {
      color: fixColor(input.DrawingAttributes?.Color),
      width: input.DrawingAttributes.Width || 0,
      height: input.DrawingAttributes.Height || 0,
      isHighlighter: input.DrawingAttributes.IsHighlighter || false,
      points: points,
      flattenedPoints: flatPoints,
      mode: input.DrawingAttributes.IsHighlighter ? "marker" : "draw"
    };
  } else {
    throw new Error(
      "StrokeDeserializer error, missing DrawingAttributes or StylusPoints"
    );
  }
}

export function SocketStrokeDeserializer(
  input: SocketStroke,
  scale: number
): Stroke {
  return {
    color: getStrokeMode(input.M) === "eraser" ? "#FFFFFF" : fixColor(input.C),
    width: parseInt(input.D.split(",")[0]),
    height: parseInt(input.D.split(",")[1]), //getStageWidth()/remotePageSize.height;
    points: input.P?.map(
      (point): Point => {
        return {
          x: Number(point.split(",")[0]) * scale,
          y: Number(point.split(",")[1]) * scale,
          pressure: Number(point.split(",")[2])
        };
      }
    ),
    flattenedPoints: input.P
      ? input.P?.map((point: string) => {
          return [
            Number(point.split(",")[0]) / scale,
            Number(point.split(",")[1]) / scale
          ];
        }).flat()
      : [],
    mode: getStrokeMode(input.M)
  };
}

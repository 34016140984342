import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex";
import { RootState } from "./index";
import "@/plugins/vueCookies";
import logger from "@/services/loggerService";
import { User } from "@/models/user.interface";
import * as UserService from "@/services/userService";
import i18n from "@/plugins/lang";

export interface UsersState {
  user: User | undefined;
  searchResults: User[];
  isLoading: boolean;
}

type UsersContext = ActionContext<UsersState, RootState>;

export const namespaced = true;

export const state = (): UsersState => ({
  user: undefined,
  searchResults: [] as User[],
  isLoading: false
});

export const getters: GetterTree<UsersState, RootState> = {
  isLoading: (state): boolean => {
    return state.isLoading;
  },
  searchResults: (state): User[] => {
    return state.searchResults;
  },
  user: (state): User | undefined => {
    return state.user;
  },
  canCreateRoom: (state): boolean => {
    return state.user?.subscription?.description !== "Free";
  }
};

export const mutations: MutationTree<UsersState> = {
  setLoading(state: UsersState, loading: boolean) {
    state.isLoading = loading;
  },
  setSearchResults(state: UsersState, data: User[]) {
    state.searchResults = data;
  },
  clearSearchResults(state: UsersState) {
    state.searchResults = [];
  },
  setUser(state: UsersState, data: User | undefined) {
    state.user = data;
  }
};

export const actions: ActionTree<UsersState, RootState> = {
  async searchUsers(context: UsersContext, data: string) {
    context.commit("setLoading", true);
    try {
      const res = await UserService.findUsers(data);
      context.commit("setSearchResults", res);
    } catch (err) {
      logger.error(err);
    } finally {
      context.commit("setLoading", false);
    }
  },
  async updateUser(context: UsersContext, data: User) {
    context.commit("setLoading", true);
    try {
      const res = await UserService.updateUser(data);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("profile.updated"),
          type: "success"
        },
        { root: true }
      );
      context.commit("setUser", res);
    } catch (err) {
      logger.error(err);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("profile.updateError"),
          type: "error"
        },
        { root: true }
      );
    } finally {
      context.commit("setLoading", false);
    }
  },
  async updateUserAvatar(context: UsersContext, data: File) {
    context.commit("setLoading", true);
    try {
      await UserService.uploadAvatar(data);
      const _user = { ...context.state.user } as User;
      //force avatar reload
      _user.avatar = `${_user.avatar}?date=${new Date().getTime()}`;
      context.commit("setUser", _user);
    } catch (err) {
      logger.error(err);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("profile.uploadAvatarError"),
          type: "error"
        },
        { root: true }
      );
    } finally {
      context.commit("setLoading", false);
    }
  }
};

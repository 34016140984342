


















































































import Vue from "vue";
import VaButton from "@/components/atoms/VaButton.vue";
import { Room } from "@/models/room.interface";
import VaAvatar from "@/components/atoms/VaAvatar.vue";
import store from "@/store";
import VaNotification from "@/components/molecules/VaNotification.vue";
import tenantService from "@/services/tenantService";
import iconcolorMixin from "@/mixins/iconcolorMixin";
import VaTextField from "@/components/atoms/VaTextField.vue";
import VaIcon from "@/components/atoms/VaIcon.vue";
import { transferRoom } from "@/services/magoLinkService";
import validationMixin from "@/mixins/validationMixin";

export default Vue.extend({
  components: {
    VaIcon,
    VaTextField,
    VaButton,
    VaAvatar,
    VaNotification
  },
  mixins: [iconcolorMixin, validationMixin],
  props: {},
  computed: {
    logo() {
      return tenantService.requireAsset("logo-square.svg");
    },
    room(): Room {
      return store.getters["room/activeRoom"];
    },
    isTransfering(): boolean {
      return store.getters["room/isTransfering"];
    }
  },
  data: () => ({
    roomDialog: false,
    successNotification: false,
    connectPin: ""
  }),
  watch: {
    isTransfering: function(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.roomDialog = false;
        this.connectPin = "";
      }
    }
  },
  methods: {
    onShareRoom: async function() {
      await transferRoom(this.connectPin, this.room);
    },
    onClose: function() {
      this.roomDialog = false;
      this.connectPin = "";
    },
    uppercase() {
      this.connectPin = this.connectPin.toUpperCase();
    }
  }
});

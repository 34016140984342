var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.selectedImage || _vm.focusOnfile
    ? _c(
        "div",
        {
          staticClass: "d-flex flex-direction-column context-menu",
          class: _vm.focusOnfile
            ? "justify-center fullscreenMenu"
            : "justify-start",
          style: _vm.getPosition()
            ? "left: " +
              _vm.getPosition().left +
              "px; top: " +
              _vm.getPosition().top +
              "px; position: absolute;"
            : ""
        },
        [
          _vm.selectedImage && _vm.selectedImage.file.type !== "text"
            ? _c("va-button", {
                staticClass: "mr-1",
                attrs: {
                  id: "image-tool",
                  icon: !_vm.focusOnfile ? "va va-expand" : "va va-collapse",
                  color: _vm.iconColor,
                  type: "square"
                },
                on: {
                  click: function($event) {
                    return _vm.$store.dispatch("wb/files/focusImage")
                  }
                }
              })
            : _vm._e(),
          !_vm.focusOnfile && _vm.isWriter
            ? _c("va-button", {
                staticClass: "mr-1",
                attrs: {
                  id: "copy-tool",
                  icon: "va va-copy-alt",
                  color: _vm.iconColor,
                  type: "square",
                  disabled: _vm.focusOnfile
                },
                on: {
                  click: function($event) {
                    return _vm.$store.dispatch("wb/files/duplicateFile")
                  }
                }
              })
            : _vm._e(),
          !_vm.focusOnfile && _vm.isWriter
            ? _c("va-button", {
                staticClass: "mr-1",
                attrs: {
                  id: "delete-tool",
                  icon: "va va-trash",
                  color: _vm.iconColor,
                  type: "square",
                  disabled: _vm.focusOnfile
                },
                on: { click: _vm.deleteElement }
              })
            : _vm._e(),
          !_vm.focusOnfile &&
          _vm.selectedImage &&
          _vm.selectedImage.file.type === "text" &&
          _vm.isWriter
            ? _c("va-button", {
                staticClass: "mr-1",
                attrs: {
                  id: "text-tool",
                  icon: "va va-text",
                  color: _vm.iconColor,
                  type: "square"
                },
                on: {
                  click: function($event) {
                    return _vm.$store.dispatch("wb/files/focusImage")
                  }
                }
              })
            : _vm._e(),
          !_vm.focusOnfile &&
          _vm.selectedImage &&
          _vm.selectedImage.file.type === "post-it" &&
          _vm.isWriter
            ? _c("hex-color-selector", {
                staticClass: "mr-1",
                attrs: { icon: "va va-fill", color: "secondary darken-2" },
                on: {
                  selectColor: function($event) {
                    return _vm.$store.dispatch("wb/files/updatePostItDetail", {
                      background: $event
                    })
                  }
                }
              })
            : _vm._e(),
          _vm.focusOnfile &&
          _vm.selectedImage &&
          _vm.selectedImage.file.type === "glb" &&
          _vm.isWriter
            ? _c("va-button", {
                staticClass: "mr-1",
                attrs: {
                  id: "snapshot-tool",
                  icon: "va va-screenshot",
                  color: _vm.iconColor,
                  type: "square"
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("makeSnapshot")
                  }
                }
              })
            : _vm._e(),
          _vm.focusOnfile &&
          _vm.selectedImage &&
          _vm.selectedImage.file.type === "glb" &&
          _vm.isWriter
            ? _c("va-button", {
                staticClass: "mr-1",
                attrs: {
                  id: "3d-palette",
                  icon: "va va-palette",
                  color: _vm.iconColor,
                  type: "square"
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("togglePalette")
                  }
                }
              })
            : _vm._e(),
          !_vm.focusOnfile && _vm.isVideo(_vm.selectedImage)
            ? _c("va-button", {
                staticClass: "mr-1",
                attrs: {
                  id: "focusvideo-tool",
                  icon: "va va-play",
                  color: _vm.iconColor,
                  type: "square"
                },
                on: {
                  click: function($event) {
                    return _vm.$store.dispatch("wb/files/focusImage")
                  }
                }
              })
            : _vm._e(),
          _vm.selectedImage &&
          _vm.selectedImage.file.type === "url" &&
          !_vm.isVideo(_vm.selectedImage)
            ? _c("va-button", {
                staticClass: "mr-1",
                attrs: {
                  id: "url-tool",
                  icon: "va va-globe",
                  color: _vm.iconColor,
                  type: "square"
                },
                on: {
                  click: function($event) {
                    return _vm.openUrl(_vm.selectedImage)
                  }
                }
              })
            : _vm._e(),
          _vm.selectedImage &&
          (_vm.selectedImage.file.type === "pdf" ||
            _vm.selectedImage.file.type === "document")
            ? _c(
                "div",
                [
                  _c("va-button", {
                    staticClass: "mr-1",
                    attrs: {
                      id: "arrow-left",
                      icon: "va va-direction-left",
                      color: "secondary darken-2",
                      type: "square",
                      disabled: _vm.selectedImage.file.currentPage === 1
                    },
                    on: {
                      click: function($event) {
                        return _vm.changePdfPage(-1)
                      }
                    }
                  }),
                  _c("va-button", {
                    staticClass: "mr-1",
                    attrs: {
                      id: "arrow-right",
                      icon: "va va-direction-right",
                      color: "secondary darken-2",
                      type: "square",
                      disabled:
                        _vm.selectedImage.file.currentPage ===
                        _vm.selectedImage.file.filePagesQuantity
                    },
                    on: {
                      click: function($event) {
                        return _vm.changePdfPage(1)
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
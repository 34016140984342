import { Page, PageDeserializer } from "@/models/wb/page.interface";

export interface ShortMeeting {
  uuid: string;
  // pages: Map<string, Page>;
  pages: Page[];
  selectedPage: string;
  chatActive: boolean;
}

export function getCurrentPage(
  meeting: ShortMeeting | undefined
): Page | undefined {
  if (!meeting) return;
  return meeting.pages.find(p => {
    return p.uuid === meeting.selectedPage;
  });
}

export async function ShortMeetingDeserializer(
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  input: any
): Promise<ShortMeeting> {
  const pages = [] as Page[];
  if (input.Pages) {
    for (const key of Object.keys(input.Pages)) {
      const rawPage = input.Pages[key];
      const p = await PageDeserializer(rawPage, key);
      if (p) {
        pages.push(p);
      }
    }
  }

  return {
    uuid: input.MeetingId || "",
    pages: pages,
    selectedPage: input.SelectedPageKey || "",
    chatActive: input.ChatActive || false
  };
}

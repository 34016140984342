





















import Vue from "vue";
import LightSwitcher from "@/components/organisms/LightSwitcher.vue";
import tenantService from "@/services/tenantService";

export default Vue.extend({
  name: "template_main",
  components: {
    LightSwitcher
  },
  props: {
    // title: {
    //   type: String,
    //   required: false
    // },
    // middleText: {
    //   type: String,
    //   required: false
    // },
    lightBg: {
      type: String,
      required: false
    },
    darkBg: {
      type: String,
      required: false
    }
  },
  computed: {
    logo() {
      return tenantService.requireAsset("logo.svg");
    },
    backgroundStyles() {
      const img = this.$store.getters["app/darkTheme"]
        ? this.darkBg || this.lightBg
        : this.lightBg || this.darkBg;
      if (img) {
        return { background: `url(${img}) center center / cover no-repeat` };
      } else {
        return {};
      }
    }
  }
});


















import Vue from "vue";
import * as wbFileService from "@/services/wb/wbFileService";
import VaButton from "@/components/atoms/VaButton.vue";
import iconcolorMixin from "@/mixins/iconcolorMixin";
export default Vue.extend({
  name: "CreatePostItBtn",
  components: { VaButton },
  mixins: [iconcolorMixin],
  methods: {
    async onCreatePostIt() {
      await wbFileService.createPostIt();
    }
  }
});

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Va-Badge")]),
      _c("h3", [_vm._v("Icons")]),
      _c("v-divider", { staticClass: "mb-4" }),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between  my-4" },
        [
          _c("va-badge", {
            attrs: { image: _vm.images[5], text: "Mago User" }
          }),
          _c("va-badge", {
            attrs: { image: _vm.images[0], text: "Mago User" }
          }),
          _c("va-badge", { attrs: { text: "Mago User" } }),
          _c("va-badge", { attrs: { text: "Mago user", color: "primary" } })
        ],
        1
      ),
      _c("h3", [_vm._v("Initials")]),
      _c("v-divider", { staticClass: "mb-4" }),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between  my-4" },
        [
          _c("va-badge", { attrs: { text: "Marco" } }),
          _c("va-badge", { attrs: { text: "Marco Valerio Masi" } }),
          _c("va-badge", { attrs: { text: "Mago-User" } }),
          _c("va-badge", { attrs: { text: "Strange*char" } }),
          _c("va-badge", { attrs: { text: "Various'char" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
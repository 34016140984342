



































































import Vue from "vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import MetaHelper from "@/services/metaHelper";
import MfaLogin from "@/components/organisms/auth/MfaLogin.vue";
import validationMixin from "@/mixins/validationMixin";
import VaLogMessage from "@/components/molecules/VaLogMessage.vue";
import VaLoader from "@/components/atoms/VaLoader.vue";
import CenterContent from "@/components/templates/CenterContent.vue";
import roomBackgroundMixin from "@/mixins/roomBackgroundMixin";
import logger from "@/services/loggerService";

export default Vue.extend({
  name: "SignIn",
  components: {
    CenterContent,
    VaLoader,
    VaLogMessage,
    MfaLogin,
    VaButton,
    VaTextField
  },
  mixins: [validationMixin, roomBackgroundMixin],
  meta() {
    return MetaHelper.buildMeta("signUp");
  },
  data: () => ({
    pass: "",
    repeatPass: "",
    valid: true
  }),
  computed: {
    pending(): boolean {
      return this.$store.getters["session/signupPending"];
    },
    error(): boolean {
      return this.$store.getters["session/signupError"];
    },
    success(): boolean {
      return this.$store.getters["session/signupSuccess"];
    },
    token(): string {
      return this.$store.getters["session/mfaToken"];
    }
  },
  mounted() {
    this.$store.commit("session/setSignupPending", true);
    this.onConfirm();
  },
  methods: {
    async onConfirm(): Promise<void> {
      if (
        this.$route.query.mail &&
        this.$route.query.confirmationToken &&
        this.$route.query.resetToken
      ) {
        await this.$store.dispatch("session/confirmEmail", {
          email: this.$route.query.mail,
          token: encodeURIComponent(
            this.$route.query.confirmationToken as string
          )
        });
      } else {
        logger.error(
          "Malformed confirm url: missing one or more confirmation params mail, confirmationToken or resetToken"
        );
        this.$store.commit("session/setSignupError", true);
      }
    },
    async onSubmit() {
      if (
        (this.$refs.confirmForm as Vue & {
          validate: () => boolean;
        }).validate() &&
        this.$route.query.mail &&
        this.$route.query.resetToken
      ) {
        await this.$store.dispatch("session/resetPassword", {
          token: this.$route.query.resetToken,
          password: this.pass,
          email: this.$route.query.mail
        });
      }
    }
  }
});

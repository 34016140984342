var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$store.getters["session/notConfirmedError"]
    ? _c(
        "div",
        { staticClass: "py-3" },
        [
          _vm.$store.getters["session/signupSuccess"]
            ? _c("va-log-message", { attrs: { type: "success" } }, [
                _vm._v(" " + _vm._s(_vm.$t("signUp.success")) + " ")
              ])
            : _c(
                "div",
                {
                  class: [
                    "d-flex",
                    "align-center",
                    "rounded",
                    "pa-3",
                    "secondary-outline"
                  ]
                },
                [
                  _c("va-icon", {
                    staticClass: "mr-3",
                    attrs: {
                      icon: "va va-info-circle",
                      color: "'secondary-outline'"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "text-center flex-fill" },
                    [
                      _c("div", { staticClass: "font-weight-bold mb-2" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("confirm.notConfirmedErr")) + " "
                        )
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("confirm.resend")) + " "),
                      _c("va-button", {
                        staticClass: "ml-2",
                        attrs: {
                          color: "secondary",
                          cta: _vm.$t("confirm.resendCTA")
                        },
                        on: {
                          click: function($event) {
                            return _vm.$store.dispatch(
                              "session/confirmResend",
                              _vm.email
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
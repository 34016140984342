









































































import Vue from "vue";
import CenterContent from "@/components/templates/CenterContent.vue";
import VaIcon from "@/components/atoms/VaIcon.vue";
import MetaHelper from "@/services/metaHelper";
import i18n from "@/plugins/lang";
import VaTextField from "@/components/atoms/VaTextField.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import AvatarUpload from "@/components/molecules/AvatarUpload.vue";
import MfaSettings from "@/components/organisms/MfaSettings.vue";
import validationMixin from "@/mixins/validationMixin";
import roomBackgroundMixin from "@/mixins/roomBackgroundMixin";

export default Vue.extend({
  name: "Rooms",
  components: {
    MfaSettings,
    CenterContent,
    VaIcon,
    VaTextField,
    VaButton,
    AvatarUpload
  },
  mixins: [validationMixin, roomBackgroundMixin],
  meta() {
    return MetaHelper.buildMeta("profile");
  },
  data() {
    return {
      valid: true,
      requiredRules: [
        (value: string) => !!value || i18n.t("validations.required")
      ],
      overlay: false,
      avatarFile: undefined as File | undefined
    };
  },
  computed: {
    mfaEnabled() {
      return (
        !process.env.VUE_APP_OAUTH_DISABLED ||
        process.env.VUE_APP_OAUTH_DISABLED === "false"
      );
    },
    user: {
      get: function(): string {
        return { ...this.$store.getters["users/user"] };
      }
    },
    isLoading(): boolean {
      return this.$store.getters["users/isLoading"];
    }
  },
  methods: {
    async onUpdateUser() {
      await this.$store.dispatch("users/updateUser", this.user);
      if (this.avatarFile) {
        this.$store.dispatch("users/updateUserAvatar", this.avatarFile);
      }
    },
    onAvatarChanged(file: File) {
      this.avatarFile = file;
    }
  }
});

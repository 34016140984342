var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: {
            "offset-x": "",
            right: "",
            "nudge-right": 16,
            "close-on-content-click": false
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                return [
                  _c("va-button", {
                    staticClass: "tool-selector-btn",
                    attrs: {
                      icon: _vm.icon,
                      color: _vm.active ? _vm.color : _vm.iconColor,
                      type: _vm.active ? "squareSolid" : "squareText",
                      name: _vm.name + "-btn"
                    },
                    on: { click: _vm.onSelectTool }
                  })
                ]
              }
            }
          ]),
          model: {
            value: _vm.menu,
            callback: function($$v) {
              _vm.menu = $$v
            },
            expression: "menu"
          }
        },
        [
          _c(
            "v-list",
            {
              staticClass: "text-center tool-submenu",
              class: [_vm.displaySubmenu ? "" : "hide-submenu"],
              attrs: { name: _vm.name + "-menu" }
            },
            [
              _vm.colorSelector
                ? _c(
                    "div",
                    _vm._l(_vm.availableColors, function(availableColor, i) {
                      return _c(
                        "v-list-item",
                        {
                          key: i,
                          on: {
                            click: function($event) {
                              return _vm.onSelectColor(availableColor)
                            }
                          }
                        },
                        [
                          _c("va-button", {
                            attrs: {
                              icon: "va va-circle-fill",
                              color: availableColor,
                              type: "icon"
                            }
                          })
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _vm.size
                ? _c("v-slider", {
                    attrs: { vertical: "", min: "1", max: _vm.maxToolSize },
                    model: {
                      value: _vm.toolSize,
                      callback: function($$v) {
                        _vm.toolSize = $$v
                      },
                      expression: "toolSize"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
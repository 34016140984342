import Konva from "konva";
import * as configService from "@/services/configService";
import store from "@/store";
import { KonvaEventObject } from "konva/lib/Node";

export function fixOutOfBoundStagePosition(pos: {
  x: number;
  y: number;
}): { x: number; y: number } {
  const zoomPercentage = store.getters["canvas/zoomPercentage"];
  const stage = store.getters["canvas/stage"];
  const width = stage.width();
  const height = stage.height();
  const scaledWidth = (width * zoomPercentage) / 100;
  const scaledHeight = (height * zoomPercentage) / 100;
  const xDiff = scaledWidth - width;
  const yDiff = scaledHeight - height;

  let x = pos.x;
  if (pos.x + scaledWidth - xDiff >= width) x = 0; //exiting right
  if (pos.x + xDiff <= 0) x = -xDiff; //exiting left

  let y = pos.y;
  if (pos.y + scaledHeight - yDiff > height) y = 0; //exiting bottom
  if (pos.y + yDiff <= 0) y = -yDiff; //exiting top

  return {
    x: x,
    y: y
  };
}

export function zoomStage(
  stage: Konva.Stage,
  event?: KonvaEventObject<WheelEvent>,
  zoomOut?: boolean
): void {
  const center = {
    x: stage.width() / 2,
    y: stage.height() / 2
  };
  let zoomAmount = configService.zoomAmount;

  if (event) {
    event.evt.preventDefault();
    zoomOut = event.evt.deltaY < 0;
    const pointer = stage.getPointerPosition();
    if (pointer) {
      center.x = pointer.x;
      center.y = pointer.y;
    }
    zoomAmount = configService.wheelZoomAmount;
  }

  const oldScale = stage.scaleX();
  if (!store.getters["wb/files/focusOnfile"]) {
    const mousePointTo = {
      x: (center.x - stage.x()) / oldScale,
      y: (center.y - stage.y()) / oldScale
    };
    const newScale = zoomOut ? oldScale / zoomAmount : oldScale * zoomAmount;
    const perc = newScale / store.getters["canvas/baseScale"];
    if (1 > perc) {
      resetZoom(stage);
    } else if (perc < configService.maxZoom) {
      store.commit("wb/files/deselectImage");
      store.commit("canvas/seCurrentScale", newScale);
      stage.scale({ x: newScale, y: newScale });

      const newPos = fixOutOfBoundStagePosition({
        x: center.x - mousePointTo.x * newScale,
        y: center.y - mousePointTo.y * newScale
      });
      stage.position(newPos);
    }
  }
}

export function resetZoom(stage: Konva.Stage): void {
  const scale = store.getters["canvas/baseScale"];
  store.commit("wb/files/deselectImage");
  store.commit("canvas/seCurrentScale", scale);
  stage.scale({ x: scale, y: scale });
  stage.position({ x: 0, y: 0 });
}

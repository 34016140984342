import { ChatMessageDeserializer } from "@/models/chatMessage.interface";
import store from "@/store";
import * as signalr from "signalr-no-jquery";
import { AuthData } from "@/models/authData.interface";
import logger from "@/services/loggerService";
import * as configService from "@/services/configService";
import { Connection } from "signalr-no-jquery";
import { Socket } from "@/models/socket.interface";
import { messageTypes } from "@/models/chatMessage.interface";

export const chatService = {
  initChatHubConnection,
  stopChatHubConnection,
  sendMessage
};

async function _init(authData: AuthData): Promise<Socket> {
  const connection = signalr.hubConnection(authData.chatServerUrl, {
    qs: "access_token=" + authData.token
  });
  const proxy = connection.createHubProxy("ChatHub");
  proxy.on("OnSendMessage", (rawMsg: unknown) => {
    const chatMessageInfo = ChatMessageDeserializer(rawMsg);
    store.dispatch("chat/receiveMessage", chatMessageInfo);
  });
  return { type: "chat", connection, proxy };
}

async function _connect(connection: Connection): Promise<void> {
  let currentRetry = 0; // Gestione retry max 3
  try {
    connection.disconnected(() => {
      store.commit("chat/setConnectionReady", false);
      logger.log("Chat Hub SignalR state disconnected ");
    });

    connection.reconnecting(() => {
      store.commit("chat/setConnectionReady", false);
      logger.log("SignalR state reconnected ");
    });

    connection.start({ transport: ["webSockets", "longPolling"] }).done(() => {
      store.commit("chat/setConnectionReady", true);
      logger.log("Chat Hub Connection Done!");
    });
  } catch (err) {
    currentRetry++;
    if (currentRetry < configService.socketRetryAttempts) {
      logger.warn("SignalR connection error: retrying");
      logger.warn(err);
      setTimeout(function() {
        _connect(connection);
      }, configService.socketRetryTimeout);
    } else {
      logger.error(
        "Cannot connect to the server at this time. Please try login again."
      );
      logger.error(err);
    }
  }
}

async function initChatHubConnection(authData: AuthData): Promise<Socket> {
  const connectionResults = await _init(authData);
  await _connect(connectionResults.connection);
  return connectionResults;
}

async function stopChatHubConnection(socket: Socket): Promise<void> {
  socket.connection.stop();
}

async function sendMessage(
  socket: Socket,
  type: messageTypes,
  message = "",
  groupId = "",
  isGroup: boolean
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
): Promise<any> {
  if (socket.connection && socket.proxy) {
    switch (type) {
      case "Message":
        return socket.proxy.invoke(
          "SendMessage",
          type,
          message,
          null,
          groupId,
          isGroup
        );
      case "OpenWebConference":
        return socket.proxy.invoke("OpenWebConference", groupId, true);
      case "CloseWebConference":
        return socket.proxy.invoke("CloseWebConference", groupId, true);
      default:
        throw new Error("unhandled message type");
    }
  }
}

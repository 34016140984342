var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    _vm._g({}, on),
                    [
                      _c(
                        "va-button",
                        {
                          attrs: { color: _vm.iconColor, type: "squareText" },
                          on: { click: _vm.onOpen }
                        },
                        [_c("v-icon", [_vm._v("va va-b-add")])],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("rooms.share")))])]
      ),
      _c(
        "v-dialog",
        {
          attrs: { "content-class": "fullscreen", width: "700" },
          model: {
            value: _vm.roomDialog,
            callback: function($$v) {
              _vm.roomDialog = $$v
            },
            expression: "roomDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "d-flex" },
                [
                  _c(
                    "div",
                    { staticClass: "flex-grow-1" },
                    [
                      _c("va-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: "va va-b-add", size: "m" }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("rooms.share")) + " ")
                    ],
                    1
                  ),
                  _c("va-button", {
                    attrs: {
                      color: _vm.iconColor,
                      type: "squareText",
                      icon: "va va-close-circle"
                    },
                    on: { click: _vm.onDismiss }
                  })
                ],
                1
              ),
              _c("v-card-text", { staticClass: "py-4" }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-center justify-center mb-6" },
                  [
                    _c("va-avatar", {
                      staticClass: "mr-3",
                      attrs: {
                        image:
                          _vm.room && _vm.room.avatar
                            ? _vm.room.avatar
                            : _vm.logo,
                        text: "conference room"
                      }
                    }),
                    _vm.room
                      ? _c("h2", [_vm._v(_vm._s(_vm.room.name))])
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex justify-space-between" },
                  [
                    _c("div", { staticStyle: { width: "50%" } }, [
                      _c("div", { staticClass: "text-center my-4" }, [
                        _c("h2", { staticClass: "mb-2" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("conference.inviteGuest")) + " "
                          )
                        ]),
                        _vm.guestEnabled
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("conference.inviteGuestDescription")
                                )
                              )
                            ])
                          : _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("conference.inviteGuestDisabled"))
                              )
                            ])
                      ]),
                      _vm.guestEnabled
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-space-between py-2 border-b"
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("join.meetingidLabel")))
                                ]),
                                _c("span", [_vm._v(_vm._s(_vm.meetingId))])
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-space-between py-2 border-b"
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("join.pinLabel")))
                                ]),
                                _c("span", [_vm._v(_vm._s(_vm.pin))])
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex mt-6 mb-2" },
                              [
                                _c("va-button", {
                                  staticClass: "flex-grow-1",
                                  attrs: {
                                    loading:
                                      _vm.$store.getters["room/isLoading"],
                                    color: _vm.shareBtnColor,
                                    cta: _vm.$t("rooms.copy"),
                                    icon: _vm.shareBtnIcon
                                  },
                                  on: { click: _vm.onCopyLink }
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("v-divider", {
                      staticClass: "mx-6 my-4",
                      attrs: { vertical: "" }
                    }),
                    _c(
                      "div",
                      { staticStyle: { width: "50%" } },
                      [
                        _c("div", { staticClass: "text-center my-4" }, [
                          _c("h2", { staticClass: "mb-2" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("conference.inviteUser")) +
                                " "
                            )
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("conference.inviteUserDescription")
                              ) +
                              " "
                          )
                        ]),
                        _c("user-autocomplete", {
                          attrs: {
                            icon: "va va-plus",
                            disabled: _vm.$store.getters["room/isLoading"]
                          },
                          on: { userSelected: _vm.onAddUser }
                        }),
                        _c("va-text-field", {
                          staticClass: "mb-6",
                          attrs: {
                            rules: [_vm.validMail],
                            label: _vm.$t("rooms.inviteByMail"),
                            disabled: _vm.$store.getters["room/isLoading"],
                            "append-icon": "va va-plus"
                          },
                          on: { "click:append": _vm.inviteByMail },
                          model: {
                            value: _vm.inviteMail,
                            callback: function($$v) {
                              _vm.inviteMail = $$v
                            },
                            expression: "inviteMail"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "va-notification",
        {
          attrs: { type: "success" },
          model: {
            value: _vm.successNotification,
            callback: function($$v) {
              _vm.successNotification = $$v
            },
            expression: "successNotification"
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("rooms.copySuccess")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex justify-space-between" },
    [
      _c("div", [_vm._v(_vm._s(_vm.$t("mfa.backupCodes")))]),
      _c("va-button", {
        attrs: {
          size: "s",
          loading: _vm.loading,
          disabled: _vm.loading,
          cta: _vm.$t("mfa.getCodesCta")
        },
        on: { click: _vm.onGetBackupCodes }
      }),
      _c(
        "v-dialog",
        {
          attrs: { "content-class": "fullscreen" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            {},
            [
              _c(
                "v-card-title",
                { staticClass: "d-flex justify-space-between" },
                [
                  _c("h2", { staticClass: "mb-4" }, [
                    _vm._v(_vm._s(_vm.$t("mfa.backupCodes")))
                  ]),
                  _c("va-button", {
                    staticClass: "mt-n4",
                    attrs: {
                      color: "",
                      type: "squareText",
                      icon: "va va-close-circle"
                    },
                    on: {
                      click: function($event) {
                        _vm.dialog = false
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("mfa.backupCodesDescription")) + " "
                  ),
                  _c("div", { staticClass: "my-4 text-right" }, [
                    _c("a", { on: { click: _vm.onResetBackupCodes } }, [
                      _vm._v(_vm._s(_vm.$t("mfa.backupCodesReset")))
                    ])
                  ]),
                  _c("v-divider", { staticClass: "my-4" }),
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c("va-loader", {
                        staticClass: "ma-auto",
                        attrs: {
                          isLoading: _vm.loading,
                          color: "primary",
                          size: "l"
                        }
                      })
                    ],
                    1
                  ),
                  !_vm.loading
                    ? _c(
                        "div",
                        _vm._l(_vm.codes, function(item) {
                          return _c(
                            "div",
                            { key: item, staticClass: "text-center text-xl" },
                            [_vm._v(" " + _vm._s(item) + " ")]
                          )
                        }),
                        0
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }


























































import Vue from "vue";
import CenterContent from "@/components/templates/CenterContent.vue";
import { User } from "@/models/user.interface";
import { Room } from "@/models/room.interface";
import VaAvatar from "@/components/atoms/VaAvatar.vue";
import VaChip from "@/components/atoms/VaChip.vue";
import VaIcon from "@/components/atoms/VaIcon.vue";
import MetaHelper from "@/services/metaHelper";
import roomBackgroundMixin from "@/mixins/roomBackgroundMixin";

export default Vue.extend({
  name: "Home",
  components: {
    CenterContent,
    VaAvatar,
    VaChip,
    VaIcon
  },
  meta() {
    return MetaHelper.buildMeta("home");
  },
  mixins: [roomBackgroundMixin],
  computed: {
    guestEnabled(): boolean {
      return this.$store.getters["app/guestEnabled"];
    },
    user(): User {
      return this.$store.getters["users/user"];
    },
    rooms(): Room[] {
      return this.$store.getters["room/roomList"];
    },
    subscriptionColor(): string {
      const subscription = this.$store.getters["users/user"].subscription
        ?.description;
      switch (subscription) {
        case "Enterprise":
          return "primary";
        case "Pro":
          return "primary lighten-1";
        case "Free":
          return "primary lighten-2";
        default:
          return "primary";
      }
    }
  },
  methods: {
    onLogout() {
      this.$store.dispatch("session/logout");
      this.$router.push(`/presignin`);
    }
  }
});

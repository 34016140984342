var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "model-container" } }, [
    _c("div", { staticClass: "modelController" }, [
      _vm.drawer
        ? _c(
            "div",
            {
              staticClass:
                "secondary-outline rounded pa-3 elevation-8 float-right solidControls "
            },
            [
              _c("v-select", {
                attrs: {
                  label: _vm.$t("solidViewer.selectMaterial"),
                  items: _vm.materials,
                  "item-title": "name",
                  outlined: ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "selection",
                      fn: function(ref) {
                        var props = ref.props
                        var item = ref.item
                        return [_vm._v(" " + _vm._s(item.name) + " ")]
                      }
                    },
                    {
                      key: "item",
                      fn: function(ref) {
                        var props = ref.props
                        var item = ref.item
                        return [_vm._v(" " + _vm._s(item.name) + " ")]
                      }
                    }
                  ],
                  null,
                  false,
                  1102352700
                ),
                model: {
                  value: _vm.selectedMaterial,
                  callback: function($$v) {
                    _vm.selectedMaterial = $$v
                  },
                  expression: "selectedMaterial"
                }
              }),
              _vm.selectedMaterial
                ? _c(
                    "div",
                    [
                      _c(
                        "div",
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("solidViewer.metallic")) + " "
                          ),
                          _c("v-slider", {
                            attrs: { step: 0.1, max: 1, min: 0 },
                            on: {
                              change: function($event) {
                                return _vm.changeMetal(_vm.selectedMaterial)
                              }
                            },
                            model: {
                              value: _vm.selectedMaterial.metallicFactor,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.selectedMaterial,
                                  "metallicFactor",
                                  $$v
                                )
                              },
                              expression: "selectedMaterial.metallicFactor"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("solidViewer.roughness")) + " "
                          ),
                          _c("v-slider", {
                            attrs: { step: 0.1, max: 1, min: 0 },
                            on: {
                              change: function($event) {
                                return _vm.changeRoughness(_vm.selectedMaterial)
                              }
                            },
                            model: {
                              value: _vm.selectedMaterial.roughnessFactor,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.selectedMaterial,
                                  "roughnessFactor",
                                  $$v
                                )
                              },
                              expression: "selectedMaterial.roughnessFactor"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-color-picker", {
                        attrs: { "show-swatches": "" },
                        on: {
                          "update:color": function($event) {
                            return _vm.changeColor(_vm.selectedMaterial)
                          }
                        },
                        model: {
                          value: _vm.selectedMaterial.hexColor,
                          callback: function($$v) {
                            _vm.$set(_vm.selectedMaterial, "hexColor", $$v)
                          },
                          expression: "selectedMaterial.hexColor"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ]),
    _c(
      "div",
      { staticClass: "fullScreenOverlay " },
      [
        _c("model-viewer", {
          ref: "modelViewer",
          staticClass: "foced16-9",
          attrs: {
            alt: "3dModel",
            id: "modelViewer",
            src: _vm.resource,
            "interaction-prompt": "none",
            ar: "",
            "disable-tap": "",
            "disable-pan": "",
            "ar-modes": "webxr scene-viewer quick-look",
            "shadow-intensity": "1",
            "camera-controls": "",
            "touch-action": "pan-x pan-y",
            "camera-orbit": "auto"
          },
          on: {
            mousedown: _vm.onStartRotateModel,
            mousemove: _vm.onRotateModel,
            mouseup: _vm.onStopRotateModel,
            touchstart: _vm.onStartRotateModel,
            touchmove: _vm.onRotateModel,
            touchend: _vm.onStopRotateModel,
            "poster-dismissed": _vm.initModel,
            wheel: _vm.onZoomModel
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from "vue";
import Vuex from "vuex";
import * as app from "./appModule";
import * as session from "./sessionModule";
import * as notifications from "./notificationsModule";
import * as room from "./roomModule";
import * as users from "./usersModule";
import * as chat from "./chatModule";
import * as conference from "./conferenceModule";
import * as videoConference from "./videoConferenceModule";
import * as canvas from "./canvasModule";
import * as files from "./wb/fileModule";
import * as tools from "./wb/toolsModule";
import * as mfa from "./mfaModule";

Vue.use(Vuex);

export interface RootState {
  app: app.AppState;
  session: session.SessionState;
  mfa: mfa.MFAState;
  notifications: notifications.NotificationState;
  room: room.RoomState;
  users: users.UsersState;
  chat: chat.ChatState;
  conference: conference.ConferenceState;
  videoConference: videoConference.VideoConferenceState;
  canvas: canvas.CanvasState;
  wb: {
    files: files.FilesState;
    tools: tools.ToolsState;
  };
}

export default new Vuex.Store({
  modules: {
    notifications,
    session,
    mfa,
    app,
    room,
    users,
    chat,
    conference,
    videoConference,
    canvas,
    wb: {
      namespaced: true,
      modules: {
        files,
        tools
      }
    }
  }
});

import store from "@/store";
import { VideoConferenceOptions } from "@/models/videoConferenceOptions.interface";

declare global {
  interface Window {
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    JitsiMeetExternalAPI: any;
  }
}

export const createOptions = (
  confID: string,
  confContainerID: string,
  name: string,
  email: string,
  micActive: boolean,
  camActive: boolean,
  roomSubject?: string
): unknown => {
  return {
    roomName: confID,
    height: "100%",
    width: "100%",
    parentNode: document.getElementById(confContainerID),
    configOverwrite: {
      startWithAudioMuted: !micActive,
      startWithVideoMuted: !camActive,
      disableInviteFunctions: true,
      subject: roomSubject || "",
      toolbarButtons: [
        "microphone",
        "camera",
        "fullscreen",
        "desktop",
        "hangup"
      ]
    },
    userInfo: {
      displayName: name,
      email: email
    }
    // interfaceConfigOverwrite: {
    //   filmStripOnly: false,
    //   VERTICAL_FILMSTRIP: true,
    //   TILE_VIEW_MAX_COLUMNS: 1,
    //   HIDE_INVITE_MORE_HEADER: false,
    //   DEFAULT_LOCAL_DISPLAY_NAME: 'me',
    //   SHOW_CHROME_EXTENSION_BANNER: false,
    //   VIDEO_LAYOUT_FIT: 'width',
    //   VIDEO_QUALITY_LABEL_DISABLED: true,
    //   DISABLE_PRESENCE_STATUS: true,
    //   DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
    //   DISABLE_FOCUS_INDICATOR: true,
    //   SET_FILMSTRIP_ENABLED: false,
    //   SHOW_JITSI_WATERMARK: false,
    //   DEFAULT_BACKGROUND: "#1a1a1a",
    //   TOOLBAR_ALWAYS_VISIBLE: true,
    // }
  };
};

export async function connect(
  options: VideoConferenceOptions
): Promise<unknown> {
  const videoContainerElement = document.getElementById(options.containerId);
  if (window?.JitsiMeetExternalAPI) {
    const webRtcInstance = new window.JitsiMeetExternalAPI(
      options.domain,
      options.jitsiOptions
    );
    webRtcInstance.addEventListener("videoConferenceJoined", () => {
      store.dispatch("chat/sendLog", "OpenWebConference");
      store.dispatch("conference/setWebConferenceStatus", "true");
    });
    webRtcInstance.addEventListener("videoConferenceLeft", () => {
      store.dispatch("chat/sendLog", "CloseWebConference");
      if (videoContainerElement) {
        videoContainerElement.innerHTML = "";
        store.commit("videoConference/setRunning", false);
      }
      // store.dispatch("meeting/updateConferenceState", false);
    });
    // webRtcInstance.addEventListener(
    //   "participantJoined",
    // (event: { id: string; displayName: string }) => {
    // console.log("participant join "+id);
    //   }
    // );
    // webRtcInstance.addEventListener(
    //   "participantLeft",
    // (event: { id: string }) => {
    // console.log("participant left "+id);
    // }
    // );
    // webRtcInstance.addEventListener(
    //   "audioMuteStatusChanged",
    //   (event: { muted: boolean }) => {
    //     console.log("muted");
    //   }
    // );
    // webRtcInstance.addEventListener(
    //   "videoMuteStatusChanged",
    //   (event: { muted: boolean }) => {
    //     console.log("!muted");
    //   }
    // );
    // webRtcInstance.addEventListener(
    //   "screenSharingStatusChanged",
    //   (event: { on: boolean }) => {
    //     console.log("share");
    //   }
    // );
    // webRtcInstance.addEventListener(
    //   "tileViewChanged",
    //   (event: { enabled: boolean }) => {
    //     console.log("view changed");
    //   }
    // );
    return webRtcInstance;
  }
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function dispose(webRtcInstance: any) {
  webRtcInstance.dispose();
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("va-avatar", {
        staticClass: "mr-3",
        attrs: {
          image: _vm.image,
          text: _vm.text,
          color: _vm.color,
          size: _vm.size
        }
      }),
      _c("span", [_vm._v(_vm._s(_vm.text))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
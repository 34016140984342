var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c("h2", [_vm._v("Va-Avatar")]),
        _c("h3", [_vm._v("Image")]),
        _c("v-divider", { staticClass: "mb-4" }),
        _c(
          "div",
          { staticClass: "d-flex justify-space-between  my-4" },
          [
            _c("va-avatar", { attrs: { image: _vm.images[0] } }),
            _c("va-avatar", { attrs: { image: _vm.images[1] } }),
            _c("va-avatar", { attrs: { image: _vm.images[2] } }),
            _c("va-avatar", { attrs: { image: _vm.images[3] } }),
            _c("va-avatar", { attrs: { image: _vm.images[4] } }),
            _c("va-avatar", {
              attrs: { color: "primary", image: _vm.images[4] }
            }),
            _c("va-avatar", { attrs: { image: _vm.images[5] } })
          ],
          1
        ),
        _c("h3", [_vm._v("Color")]),
        _c("v-divider", { staticClass: "mb-4" }),
        _c(
          "div",
          { staticClass: "d-flex justify-space-between  my-4" },
          [
            _c("va-avatar", { attrs: { text: "primary", color: "primary" } }),
            _c("va-avatar", {
              attrs: { text: "L-1", color: "primary lighten-1" }
            }),
            _c("va-avatar", {
              attrs: { text: "l 2", color: "primary lighten-2" }
            }),
            _c("va-avatar", {
              attrs: { text: "l 3", color: "primary lighten-3" }
            }),
            _c("va-avatar", {
              attrs: { text: "l 4", color: "primary lighten-4" }
            }),
            _c("va-avatar", {
              attrs: { text: "d 1", color: "primary darken-1" }
            }),
            _c("va-avatar", {
              attrs: { text: "d 2", color: "primary darken-2" }
            }),
            _c("va-avatar", {
              attrs: { text: "d 3", color: "primary darken-3" }
            }),
            _c("va-avatar", {
              attrs: { text: "d 4", color: "primary darken-4" }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex justify-space-between  my-4" },
          [
            _c("va-avatar", {
              attrs: { text: "secondary", color: "secondary" }
            }),
            _c("va-avatar", {
              attrs: { text: "l-1", color: "secondary lighten-1" }
            }),
            _c("va-avatar", {
              attrs: { text: "l-2", color: "secondary lighten-2" }
            }),
            _c("va-avatar", {
              attrs: { text: "l-3", color: "secondary lighten-3" }
            }),
            _c("va-avatar", {
              attrs: { text: "l-4", color: "secondary lighten-4" }
            }),
            _c("va-avatar", {
              attrs: { text: "d-1", color: "secondary darken-1" }
            }),
            _c("va-avatar", {
              attrs: { text: "d-2", color: "secondary darken-2" }
            }),
            _c("va-avatar", {
              attrs: { text: "d-3", color: "secondary darken-3" }
            }),
            _c("va-avatar", {
              attrs: { text: "d-4", color: "secondary darken-4" }
            })
          ],
          1
        ),
        _c("h3", [_vm._v("Size & Shape")]),
        _c("v-divider", { staticClass: "mb-4" }),
        _c(
          "div",
          { staticClass: "d-flex justify-space-between  my-4" },
          [
            _c("va-avatar", {
              attrs: { text: "ciao iniziali", color: "primary", size: "s" }
            }),
            _c("va-avatar", {
              attrs: { text: "ciao prova", color: "primary", size: "m" }
            }),
            _c("va-avatar", {
              attrs: { text: "Prova ciao", color: "primary", size: "l" }
            }),
            _c("va-avatar", { attrs: { image: _vm.images[5], size: "s" } }),
            _c("va-avatar", { attrs: { image: _vm.images[5], size: "m" } }),
            _c("va-avatar", { attrs: { image: _vm.images[5], size: "l" } }),
            _c("va-avatar", { attrs: { image: _vm.images[0], size: "s" } }),
            _c("va-avatar", { attrs: { image: _vm.images[0], size: "m" } }),
            _c("va-avatar", { attrs: { image: _vm.images[0], size: "l" } })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex justify-space-between  my-4" },
          [
            _c("va-avatar", {
              attrs: { text: "m m", color: "primary", size: "s", tile: "" }
            }),
            _c("va-avatar", {
              attrs: { text: "m m", color: "primary", size: "m", tile: "" }
            }),
            _c("va-avatar", {
              attrs: { text: "m m", color: "primary", size: "l", tile: "" }
            }),
            _c("va-avatar", {
              attrs: { image: _vm.images[5], size: "s", tile: "" }
            }),
            _c("va-avatar", {
              attrs: { image: _vm.images[5], size: "m", tile: "" }
            }),
            _c("va-avatar", {
              attrs: { image: _vm.images[5], size: "l", tile: "" }
            }),
            _c("va-avatar", {
              attrs: { image: _vm.images[0], size: "s", tile: "" }
            }),
            _c("va-avatar", {
              attrs: { image: _vm.images[0], size: "m", tile: "" }
            }),
            _c("va-avatar", {
              attrs: { image: _vm.images[0], size: "l", tile: "" }
            })
          ],
          1
        ),
        _c("h3", [_vm._v("Text Content")]),
        _c("v-divider", { staticClass: "mb-4" }),
        _c(
          "div",
          { staticClass: "d-flex justify-space-between  my-4" },
          [
            _c("va-avatar", {
              attrs: { text: "1", color: "primary lighten-1" }
            }),
            _c("va-avatar", {
              attrs: { text: "2", color: "primary lighten-2" }
            }),
            _c("va-avatar", {
              attrs: { text: "3", color: "primary lighten-3" }
            }),
            _c("va-avatar", {
              attrs: { text: "4", color: "primary lighten-4" }
            }),
            _c("va-avatar", {
              attrs: { text: "A", color: "primary lighten-1", tile: "" }
            }),
            _c("va-avatar", {
              attrs: { text: "b", color: "primary lighten-2", tile: "" }
            }),
            _c("va-avatar", {
              attrs: { text: "c", color: "primary lighten-3", tile: "" }
            }),
            _c("va-avatar", {
              attrs: { text: "d", color: "primary lighten-4", tile: "" }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex justify-space-between  my-4" },
          [
            _c("va-avatar", {
              attrs: { text: "a z", color: "secondary lighten-1" }
            }),
            _c("va-avatar", {
              attrs: { text: "b x", color: "secondary lighten-2" }
            }),
            _c("va-avatar", {
              attrs: { text: "c y", color: "secondary lighten-3" }
            }),
            _c("va-avatar", {
              attrs: { text: "d t", color: "secondary lighten-4" }
            }),
            _c("va-avatar", {
              attrs: { text: "1 1", color: "secondary lighten-1", tile: "" }
            }),
            _c("va-avatar", {
              attrs: { text: "0 0", color: "secondary lighten-2", tile: "" }
            }),
            _c("va-avatar", {
              attrs: { text: "0 9", color: "secondary lighten-3", tile: "" }
            }),
            _c("va-avatar", {
              attrs: { text: "e 8", color: "secondary lighten-4", tile: "" }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
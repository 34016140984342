



























import Vue from "vue";
import VaIcon from "@/components/atoms/VaIcon.vue";

export default Vue.extend({
  components: {
    VaIcon
  },
  props: {
    sender: {
      type: String,
      required: false
    },
    date: {
      type: Date,
      required: false
    },
    type: {
      type: String,
      required: false,
      validator(value) {
        return ["info", "success", "warning", "error"].includes(value);
      }
    }
  },
  computed: {
    computedType(): { color: string; icon: string } {
      switch (this.type) {
        case "info":
          return {
            color: "primary",
            icon: "va va-info-circle"
          };
        case "success":
          return {
            color: "success",
            icon: "va va-check-circle"
          };
        case "warning":
          return {
            color: "warning",
            icon: "va va-warning-circle"
          };
        case "error":
          return {
            color: "error",
            icon: "va va-warning-circle"
          };
        default:
          return {
            color: "secondary",
            icon: "va va-info-circle"
          };
      }
    },
    formattedTime(): string {
      let date = new Date(this.date);
      return date.toLocaleString();
    }
  }
});

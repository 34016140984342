var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "content-class": "fullscreen" },
      model: {
        value: _vm.dialog.visible,
        callback: function($$v) {
          _vm.$set(_vm.dialog, "visible", $$v)
        },
        expression: "dialog.visible"
      }
    },
    [
      _c(
        "v-card",
        [
          _vm.dialog.title
            ? _c("v-card-title", [_vm._v(" " + _vm._s(_vm.dialog.title) + " ")])
            : _vm._e(),
          _c("v-card-text", { staticClass: "text-center pa-16" }, [
            _vm.dialog.description
              ? _c("div", [_vm._v(_vm._s(_vm.dialog.description))])
              : _vm._e()
          ]),
          _c(
            "v-card-actions",
            { staticClass: "justify-end", attrs: { color: "base" } },
            [
              _c("va-button", {
                attrs: {
                  type: "outline",
                  color: "secondary",
                  cta: _vm.dialog.ko ? _vm.dialog.ko : _vm.$t("shared.dismiss")
                },
                on: { click: _vm.dismiss }
              }),
              (_vm.dialog.callback
              ? _vm.dialog.callback
              : null)
                ? _c("va-button", {
                    attrs: {
                      cta: _vm.dialog.ok
                        ? _vm.dialog.ok
                        : _vm.$t("shared.confirm")
                    },
                    on: { click: _vm.confirm }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
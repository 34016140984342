import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex";
import store, { RootState } from "@/store";
import Konva from "konva";
import { wbCanvasService } from "@/services/wb/wbCanvasService";
import { Stroke } from "@/models/wb/stroke.interface";
import {
  getCurrentPage,
  ShortMeeting
} from "@/models/wb/shortMeeting.interface";
import { Page } from "@/models/wb/page.interface";
import * as conferenceService from "@/services/conferenceSocketService";
import { v4 as uuidv4 } from "uuid";
import { Point } from "@/models/wb/point.interface";
import * as wbZoomService from "@/services/wb/wbZoomService";
import * as wbConversionService from "@/services/wb/wbConversionService";
import { CanvasFile, cleanHexColor } from "@/models/wb/canvasFile.interface";
import { cloneDeep } from "lodash";
import * as wbFileService from "@/services/wb/wbFileService";
import { emptyFileId } from "@/services/configService";

export interface CanvasState {
  mounted: boolean;
  mounting: boolean;
  stage: Konva.Stage | undefined;
  background: Konva.Shape | undefined;
  strokesLayer: Konva.Layer | undefined;
  currentLine: { line: Konva.Line; stroke: Stroke } | undefined;
  shortMeeting?: ShortMeeting;
  baseScale: number;
  currentScale: number;
  currentPage: Page | undefined;
  pagePreviews: string[];
  pages: Page[];
  backgroundLoading: boolean;
}

const generateNewId = (pages: Page[]): string => {
  // -------- HACK --------
  // need to prepend an incremental number to the random uuid because missing on server side ordering
  const incrementalid =
    Math.max(
      ...pages.map(p => {
        return parseInt(p.uuid.split("-")[0]);
      })
    ) + 1;
  const newId = "000" + incrementalid;
  return newId.substr(newId.length - 3) + "-" + uuidv4().slice(4);
};

type CanvasContext = ActionContext<CanvasState, RootState>;

export const namespaced = true;

export const state = (): CanvasState => ({
  mounted: false,
  mounting: false,
  stage: undefined,
  background: undefined,
  strokesLayer: undefined,
  currentLine: undefined,
  shortMeeting: undefined,
  baseScale: 1,
  currentScale: 1,
  currentPage: undefined,
  pagePreviews: [],
  pages: [],
  backgroundLoading: false
});

export const getters: GetterTree<CanvasState, RootState> = {
  backgroundColor: state => {
    return state.currentPage?.background.color;
  },
  backgroundImage: state => {
    return state.currentPage?.background.image !== emptyFileId
      ? state.currentPage?.background.image
      : undefined;
  },
  isBackgroundLoading: state => {
    return state.backgroundLoading;
  },
  mounted: state => {
    return state.mounted;
  },
  shortMeeting: (state): ShortMeeting | undefined => {
    return state.shortMeeting;
  },
  stage: (state): Konva.Stage | undefined => {
    return state.stage;
  },
  page: (state): Page | undefined => {
    return state.currentPage;
  },
  pages: (state): Page[] => {
    // return state.shortMeeting &&
    //   state.shortMeeting.pages?.size > 0 &&
    //   state.shortMeeting.pages.size !== state.pages.length
    //   ? [...state.shortMeeting.pages]
    //   : state.pages;
    return state.pages;
  },
  strokesLayer: (state): Konva.Layer | undefined => {
    return state.strokesLayer;
  },
  currentLine: (state): { line: Konva.Line; stroke: Stroke } | undefined => {
    return state.currentLine;
  },
  baseScale: (state): number => {
    return state.baseScale;
  },
  currentScale: (state): number => {
    return state.currentScale;
  },
  zoomPercentage: (state): number => {
    return Math.round((state.currentScale / state.baseScale) * 100);
  },
  pagePreviews: (state): string[] => {
    return state.pagePreviews;
  }
};

export const mutations: MutationTree<CanvasState> = {
  setPageBackground(
    state: CanvasState,
    payload: {
      color?: string;
      image?: string;
      pageKey: string;
    }
  ) {
    for (const page of state.pages) {
      if (page.uuid == payload.pageKey) {
        page.background = {
          color: payload.color || page.background.color,
          image: payload.image || page.background.image
        };
        state.currentPage = page;
        break;
      }
    }
  },
  setBackgroundShape(state: CanvasState, bg: Konva.Shape) {
    state.background = bg;
  },
  setBackgroundLoading(state: CanvasState, loading: boolean) {
    state.backgroundLoading = loading;
  },
  setMounted(state: CanvasState, mounted: boolean) {
    state.mounted = mounted;
  },
  setStage(state: CanvasState, stage: Konva.Stage) {
    state.stage = stage;
  },
  setStrokeLayer(state: CanvasState, layer: Konva.Layer) {
    state.strokesLayer = layer;
  },
  clearStrokesLayer(state: CanvasState) {
    state.strokesLayer?.destroyChildren();
  },
  updatePage(state: CanvasState, page: Page) {
    if (state.shortMeeting) {
      state.shortMeeting.pages = state.shortMeeting.pages.map(p => {
        return p.uuid == page.uuid ? page : p;
      });
      state.pages = state.shortMeeting.pages;
    }
  },
  setCurrentPage(state: CanvasState, page: Page) {
    state.currentPage = page;
  },
  updateCurrentPageFile(state: CanvasState, newFile: CanvasFile) {
    if (state.currentPage) {
      state.currentPage.files = state.currentPage.files.map(file => {
        if (file.uuid == newFile?.uuid) return newFile;
        return file;
      });
    }
  },
  setPages(state: CanvasState, pages: Page[]) {
    state.pages = pages;
  },
  deletePage(state: CanvasState, pageUuid: string) {
    if (!state.shortMeeting) return;
    state.pages = state.shortMeeting.pages = state.shortMeeting?.pages.filter(
      p => {
        return p.uuid !== pageUuid;
      }
    );
  },
  addStroke(state: CanvasState, data: { stroke: Stroke; line: Konva.Line }) {
    state.strokesLayer?.add(data.line);
    state.currentPage?.strokes.push(data.stroke);
  },
  addNewLine(state: CanvasState, line: Konva.Line) {
    state.strokesLayer?.add(line);
  },
  setCurrentLine(
    state: CanvasState,
    data: { line: Konva.Line; stroke: Stroke } | undefined
  ) {
    state.currentLine = data;
  },
  addPointToCurrentLine(state: CanvasState, point: Point) {
    state.currentLine?.line.points(
      state.currentLine?.line.points().concat([point.x, point.y])
    );
    state.currentLine?.stroke.points?.push(point);
    state.currentLine?.stroke.flattenedPoints?.push(point.x);
    state.currentLine?.stroke.flattenedPoints?.push(point.y);
  },
  addStrokeToCurrentPage(state: CanvasState, stroke: Stroke) {
    state.currentPage?.strokes.push(stroke);
  },
  updateLayerStrokes(state: CanvasState, data: { strokes: Stroke[] }) {
    state.strokesLayer?.find("Line").map(line => line.destroy());
    data.strokes.map(stroke => {
      state.strokesLayer?.add(wbConversionService.Stroke2Line(stroke));
    });
    if (state.currentPage) state.currentPage.strokes = data.strokes;
  },

  updateFile(state: CanvasState, data: { strokes: Stroke[] }) {
    state.strokesLayer?.find("Line").map(line => line.destroy());
    data.strokes.map(stroke => {
      state.strokesLayer?.add(wbConversionService.Stroke2Line(stroke));
    });
    if (state.currentPage) state.currentPage.strokes = data.strokes;
  },
  setShortMeeting(state: CanvasState, meeting: ShortMeeting | undefined) {
    state.shortMeeting = meeting;
  },

  setDraggable(state: CanvasState, draggable: boolean) {
    state.stage?.draggable(draggable);
  },
  setBaseScale(state: CanvasState, scale: number) {
    state.baseScale = scale;
  },
  seCurrentScale(state: CanvasState, scale: number) {
    state.currentScale = scale;
  },
  resetZoom(state: CanvasState) {
    const stage = state.stage;
    if (stage) {
      state.currentScale = state.baseScale;
      wbZoomService.resetZoom(stage);
    }
  },
  zoomIn(state: CanvasState) {
    const stage = state.stage;
    if (stage) {
      wbZoomService.zoomStage(stage, undefined, false);
    }
  },
  zoomOut(state: CanvasState) {
    const stage = state.stage;
    if (stage) {
      wbZoomService.zoomStage(stage, undefined, true);
    }
  },
  setThumbnails(state: CanvasState, thumbnails: string[]) {
    state.pagePreviews = thumbnails;
  }
};

export const actions: ActionTree<CanvasState, RootState> = {
  redraw(context: CanvasContext, payload: { width: number; height: number }) {
    const konvaStage = context.getters["stage"] as Konva.Stage;
    const scale = payload.width / konvaStage.width();
    const konvaScale = konvaStage.scale().x;
    const newScale = konvaScale * scale;
    konvaStage.width(payload.width);
    konvaStage.height(payload.height);
    konvaStage.scale({ x: newScale, y: newScale });
  },
  drawStrokes(
    context: CanvasContext,
    data: {
      page: Page;
      strokesLayer: Konva.Layer;
    }
  ) {
    if (data.page?.strokes) {
      data.page.strokes.map((stroke: Stroke) => {
        const line = wbConversionService.Stroke2Line(stroke);
        context.commit("addStroke", { stroke: stroke, line: line });
        data.strokesLayer?.add(line);
      });
    }
  },

  async updateBackgroundColor(
    context: CanvasContext,
    data: { color: string; sendMessage: false }
  ) {
    const color = cleanHexColor(data.color);
    // if (data.color.length > 7) {
    //   // HACK change malformed 8-digits hex colors from android/pod
    //   color = `#${data.color.slice(3)}`;
    // }
    context.state.background?.fill(color);

    const currentPage = context.state?.currentPage;
    if (currentPage) {
      context.commit("setPageBackground", {
        color: color,
        pageKey: currentPage.uuid
      });
      if (data.sendMessage) {
        await conferenceService.setPageBackground(
          store.getters["conference/socket"],
          {
            pageKey: currentPage.uuid,
            fileId: { color: data.color }
          }
        );
      }
    }
  },
  async removeBackgroundImage(context: CanvasContext) {
    context.dispatch("updateBackgroundImage", {
      imageId: emptyFileId,
      sendMessage: true
    });
  },
  async updateBackgroundImage(
    context: CanvasContext,
    data: { imageId: string; sendMessage: false }
  ) {
    context.commit("setBackgroundLoading", true);
    const currentPage = context.state?.currentPage;
    if (!data.imageId || data.imageId == emptyFileId) {
      if (currentPage) {
        context.commit("setPageBackground", {
          image: data.imageId,
          pageKey: currentPage.uuid
        });
      }
      context.state.background?.fillPriority("color");
    } else {
      context.state.background?.fillPriority("pattern");
      if (
        data.imageId &&
        context.state.stage &&
        context.state.background &&
        context.rootState.conference.authData
      ) {
        const src = await wbFileService.fetchFile(data.imageId);
        wbCanvasService.updateBackgroundImage(
          context.state.stage,
          context.state.background,
          src
        );

        if (currentPage) {
          context.commit("setPageBackground", {
            image: data.imageId,
            pageKey: currentPage.uuid
          });
        }
      }
    }
    if (data.sendMessage && currentPage) {
      await conferenceService.setPageBackgroundFile(
        store.getters["conference/socket"],
        {
          pageKey: currentPage?.uuid,
          fileId: {
            image: data.imageId
          }
        }
      );
    }
    context.commit("setBackgroundLoading", false);
  },

  async mount(
    context: CanvasContext,
    data: {
      containerId: string;
      availableWidth: number;
      availableHeight: number;
    }
  ) {
    const authdata = context.rootState.conference.authData;
    const currentPage = getCurrentPage(context.state.shortMeeting);
    if (!currentPage || !authdata) {
      throw new Error(
        `CanvasModule Mount action: missing shortmeeting or authdata`
      );
    }
    if (!context.state.mounted && !context.state.mounting) {
      context.state.mounting = true;
      const stage = wbCanvasService.CreateStage(
        data.containerId,
        data.availableWidth,
        data.availableHeight,
        currentPage.size?.width || 1,
        currentPage.size?.height || 1,
        true,
        true
      );
      context.commit("setCurrentPage", currentPage);

      const backgroundLayer = wbCanvasService.CreateLayer();
      const strokeLayer = wbCanvasService.CreateLayer();
      const imageLayer = wbCanvasService.CreateLayer();
      const focusLayer = wbCanvasService.CreateLayer();

      await context.dispatch("drawStrokes", {
        page: currentPage,
        strokesLayer: strokeLayer
      });

      await context.dispatch(
        "wb/files/drawFiles",
        {
          page: currentPage,
          imagesLayer: imageLayer
        },
        { root: true }
      );

      const imageTransformer = wbCanvasService.createImageTransformer();
      imageLayer.add(imageTransformer);

      const bg = await wbCanvasService.createBackground(stage);
      backgroundLayer.add(bg);

      stage.add(backgroundLayer);
      stage.add(imageLayer);
      stage.add(strokeLayer);
      stage.add(focusLayer);

      if (context.state.shortMeeting)
        context.commit("setPages", [...context.state.shortMeeting?.pages]);
      context.commit("setStage", stage);
      context.commit("setStrokeLayer", strokeLayer);
      context.commit("wb/files/setImagesLayer", imageLayer, { root: true });
      context.commit("wb/files/setImagesTransformer", imageTransformer, {
        root: true
      });
      context.commit("setMounted", true);

      context.commit("setBackgroundShape", bg);

      const background = cloneDeep(currentPage?.background);
      context.dispatch("updateBackgroundColor", {
        color: background.color
      });
      if (background.image) {
        context.dispatch("updateBackgroundImage", {
          imageId: background.image
        });
      }
      context.state.mounting = false;
    }
  },
  async clearAndPopulateCanvasLayers(context: CanvasContext, page: Page) {
    context.commit("clearStrokesLayer");
    context.commit("setCurrentPage", page);
    context.commit("wb/files/clearImagesLayer", null, { root: true });
    context.commit("wb/files/deselectImage", null, { root: true });
    await context.dispatch("drawStrokes", {
      page: page,
      strokesLayer: context.state.strokesLayer
    });
    await context.dispatch("wb/files/updateImageLayer", page, { root: true });
    context.dispatch("updateBackgroundColor", { color: page.background.color });
    context.dispatch("updateBackgroundImage", {
      imageId: page.background.image
    });

    // context.commit("setPageBackground", {
    //   color: page.background.color,
    //   image: page.background.image,
    //   pageKey: page.uuid
    // });
  },
  async changePage(
    context: CanvasContext,
    data: { pageUuid: string; sendMessage?: boolean }
  ) {
    context.commit("setBackgroundLoading", true);
    context.commit("resetZoom");
    await context.dispatch("wb/files/unfocusImage", null, { root: true });
    // context.commit("setCurrentPage", data.page);

    // const page = data.pageUuid
    //   ? context.state.shortMeeting?.pages.find(p => {
    //       return p.uuid === data.pageUuid;
    //     })
    //   : data.page;
    if (data.sendMessage) {
      //SignalR message
      await conferenceService.selectPage(
        store.getters["conference/socket"],
        data.pageUuid
      );
    }

    const page = context.state.shortMeeting?.pages.find(p => {
      return p.uuid === data.pageUuid;
    });

    if (page) {
      const shortMeeting = await conferenceService.getShortMeeting();
      context.commit("setShortMeeting", shortMeeting);
      await context.dispatch("clearAndPopulateCanvasLayers", page);
    } else {
      await context.dispatch("addLocalPage", { pageUuid: data.pageUuid });
    }

    context.commit("setBackgroundLoading", false);
  },
  async addLocalPage(context: CanvasContext, data: { sendMessage?: boolean }) {
    const newPage = {
      uuid: generateNewId(context.state.pages),
      backgroundFileId: "",
      background: {
        color: "#FFFFFF",
        image: undefined
      },
      files: [],
      meetingId: context.state.shortMeeting?.uuid || "",
      strokes: [],
      size: {
        width: context.state.currentPage?.size?.width || 1920,
        height: context.state.currentPage?.size?.height || 1080
      },
      thumbFileId: "", //Populate this field inside getPagesThumbs method
      thumbnailUrl: ""
    };
    if (context.state.shortMeeting) {
      context.state.shortMeeting.pages.push(newPage);
      context.state.shortMeeting.selectedPage = newPage.uuid;
      context.commit("setPages", context.state.shortMeeting.pages);
      await context.dispatch("clearAndPopulateCanvasLayers", newPage);
      //SignalR message if page was created locally
      if (data.sendMessage)
        conferenceService.selectPage(
          store.getters["conference/socket"],
          newPage.uuid
        );
    }
  },
  async cloneLocalPage(context: CanvasContext, data: { originalId: string }) {
    const newId = generateNewId(context.state.pages);
    conferenceService.clonePage(
      store.getters["conference/socket"],
      newId,
      data.originalId
    );
    context.dispatch("clonePage", {
      originalId: data.originalId,
      clonedId: newId,
      sendMessage: true
    });
  },
  async clonePage(
    context: CanvasContext,
    data: { originalId: string; clonedId: string; sendMessage?: boolean }
  ) {
    let newPage;
    if (context.state.shortMeeting) {
      newPage = cloneDeep(
        context.state.shortMeeting.pages.find(p => {
          return p.uuid === data.originalId;
        })
      );
    }
    if (newPage && context.state.shortMeeting) {
      newPage.uuid = data.clonedId;
      context.state.shortMeeting.pages.push(newPage);
      context.commit("setPages", [...context.state.shortMeeting.pages]);
      if (data.sendMessage) {
        conferenceService.selectPage(
          store.getters["conference/socket"],
          data.clonedId
        );
      }
    } else {
      throw new Error(
        `CanvasModule clonePage action: page with id ${data.originalId} not found`
      );
    }
  },
  deletePage(
    context: CanvasContext,
    data: { pageUuid: string; isLocalAction?: boolean }
  ) {
    if (context.state.pages.length > 1) {
      let previousPageIndex = 0;
      for (const [i, p] of context.state.pages.entries()) {
        if (p.uuid === data.pageUuid) {
          previousPageIndex = i == 0 ? i : i - 1;
        }
      }
      context.commit("deletePage", data.pageUuid);
      if (data.isLocalAction)
        context.dispatch("changePage", {
          pageUuid: context.state.pages[previousPageIndex].uuid,
          sendMessage: true
        });
      //SignalR message. Right messages sequence: Select, Delete
      conferenceService.deletePage(
        store.getters["conference/socket"],
        data.pageUuid
      );
    }
  },
  async unMount(context: CanvasContext) {
    if (context.state.mounted) {
      context.commit("setMounted", false);
      context.commit("setCurrentLine", undefined);
      context.dispatch("wb/tools/setTool", "drag", { root: true });
      await context.dispatch("wb/files/unfocusImage", {}, { root: true });
      context.commit("setStage", undefined);
    }
  },

  ///___neW
  addStroke(
    context: CanvasContext,
    data: { stroke: Stroke; addNewStroke?: boolean; pageUuid: string }
  ) {
    if (data.addNewStroke && data.stroke.mode !== "eraser") {
      const line = wbConversionService.Stroke2Line(data.stroke);
      if (!context.rootState.wb.files.focusOnfile) {
        context.commit("addStroke", { stroke: data.stroke, line: line });
      } else {
        context.commit(
          "wb/files/addStroke",
          { stroke: data.stroke, line: line },
          { root: true }
        );
      }
    }
  },
  addNewLine(
    context: CanvasContext,
    data: { line: Konva.Line; stroke: Stroke }
  ) {
    if (!context.rootState.wb.files.focusOnfile) {
      if (!context.state.currentLine) {
        context.commit("addNewLine", data.line);
      }
    } else {
      context.commit("wb/files/addNewLine", data.line, { root: true });
    }
    context.commit("setCurrentLine", data);
  },
  addPointToLine(context: CanvasContext, point: Point) {
    if (context.state.currentLine) {
      context.commit("addPointToCurrentLine", point);
    }
  },
  endDrawLine(context: CanvasContext) {
    const stroke = context.state.currentLine?.stroke;
    if (stroke?.mode !== "eraser") {
      if (!context.rootState.wb.files.focusOnfile) {
        context.commit("addStrokeToCurrentPage", stroke);
      } else {
        context.commit("wb/files/endDrawLine", stroke, { root: true });
      }
    }
    context.commit("setCurrentLine", undefined);
  }
};

import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex";
import { RootState } from "./index";
import * as RoomService from "@/services/roomService";
import "@/plugins/vueCookies";
import { Room } from "@/models/room.interface";
import router from "@/router/index";
import logger from "@/services/loggerService";
import i18n from "@/plugins/lang";
import { User } from "@/models/user.interface";

export interface RoomState {
  roomList: Room[];
  activeRoom?: Room;
  isLoading: boolean;
  isTransfering: boolean;
}

type SessionContext = ActionContext<RoomState, RootState>;

export const namespaced = true;

export const state = (): RoomState => ({
  roomList: [],
  activeRoom: undefined,
  isLoading: false,
  isTransfering: false
});

export const getters: GetterTree<RoomState, RootState> = {
  isLoading: (state): boolean => {
    return state.isLoading;
  },
  isTransfering: (state): boolean => {
    return state.isLoading;
  },
  roomList: (state): Room[] => {
    return state.roomList;
  },
  activeRoom: (state): Room | undefined => {
    return state.activeRoom;
  }
};

export const mutations: MutationTree<RoomState> = {
  setLoading(state: RoomState, loading: boolean) {
    state.isLoading = loading;
  },
  setTransfering(state: RoomState, loading: boolean) {
    state.isLoading = loading;
  },
  setActiveRoom(state: RoomState, data: Room | undefined) {
    state.activeRoom = data;
  },
  setRoomList(state: RoomState, data: Room[]) {
    state.roomList = data;
  },
  removeRoomFromList(state: RoomState, roomToRemove: Room) {
    state.roomList = state.roomList.filter(room => {
      return room.destinationId != roomToRemove.destinationId;
    });
  },
  addRoomToList(state: RoomState, roomToAdd: Room) {
    state.roomList.push(roomToAdd);
  },
  updateRoomInList(state: RoomState, roomToEdit: Room) {
    state.roomList = state.roomList.map(room => {
      if (room.destinationId === roomToEdit.destinationId) {
        return roomToEdit;
      }
      return room;
    });
  }
};

export const actions: ActionTree<RoomState, RootState> = {
  async fetchRoom(
    context: SessionContext,
    data: { roomId: string; single: boolean }
  ) {
    if (context.state?.activeRoom?.destinationId === data.roomId) {
      logger.log(`Room ${data.roomId} already fetched`);
    } else {
      context.commit("setLoading", true);
      try {
        const room = await RoomService.getRoom(
          data.roomId,
          data.single || false
        );
        context.commit("setActiveRoom", room);
        context.dispatch("chat/fetchMessages", room.destinationId, {
          root: true
        });
        context.dispatch("chat/fetchLogs", room.destinationId, {
          root: true
        });
      } catch (err) {
        logger.error(err);
      } finally {
        context.commit("setLoading", false);
      }
    }
  },
  clearActiveRoom(context: SessionContext) {
    context.commit("setActiveRoom", undefined);
    context.dispatch("chat/stop", undefined, {
      root: true
    });
  },
  async fetchRooms(context: SessionContext) {
    context.commit("setLoading", true);
    try {
      const rooms = await RoomService.getRoomList();
      context.commit("setRoomList", rooms);
    } catch (err) {
      logger.error(err);
    } finally {
      context.commit("setLoading", false);
    }
  },
  async leaveRoom(context: SessionContext, room: Room) {
    context.commit("setLoading", true);
    try {
      await RoomService.leaveRoom(room);
      context.commit("removeRoomFromList", room);
    } catch (err) {
      logger.error(err);
    } finally {
      context.commit("setLoading", false);
    }
  },
  async editRoom(context: SessionContext, room: Room) {
    context.commit("setLoading", true);
    try {
      context.commit("updateRoomInList", room);
      await RoomService.editRoom(room);
    } catch (err) {
      logger.error(err);
    } finally {
      context.commit("setLoading", false);
    }
  },
  async updateRoomAvatar(
    context: SessionContext,
    data: { file: File; room: Room }
  ) {
    context.commit("setLoading", true);
    try {
      await RoomService.uploadAvatar(data.file, data.room);
      //force avatar reload
      data.room.avatar = `${data.room.avatar}?date=${new Date().getTime()}`;
      context.commit("updateRoomInList", data.room);
    } catch (err) {
      logger.error(err);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("rooms.uploadAvatarError"),
          type: "error"
        },
        { root: true }
      );
    } finally {
      context.commit("setLoading", false);
    }
  },

  async createRoom(context: SessionContext, room: Room) {
    try {
      context.commit("setLoading", true);
      const res = await RoomService.createRoom(room);
      context.commit("addRoomToList", res);
      router.push(`/room/g/${res.destinationId}/session`);
    } catch (err) {
      logger.error(err);
    } finally {
      context.commit("setLoading", false);
    }
  },
  async addUser(context: SessionContext, data: { room: Room; user: User }) {
    try {
      data.room.users?.push(data.user);
      context.commit("setLoading", true);
      const res = await RoomService.updateRoom(data.room);
      if (res) {
        context.commit("setActiveRoom", res);
      }
    } catch (err) {
      logger.error(err);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("rooms.updateError"),
          type: "error"
        },
        { root: true }
      );
    } finally {
      context.commit("setLoading", false);
    }
  },
  async removeUser(
    context: SessionContext,
    data: { room: Room; userId: string }
  ) {
    try {
      context.commit("setLoading", true);
      const res = await RoomService.removeUser(data.room.groupId, data.userId);
      if (res) {
        data.room.users = data.room.users?.filter(user => {
          return user.userId != data.userId;
        });
        context.commit("updateRoomInList", data.room);
        context.commit("setActiveRoom", data.room);
      }
    } catch (err) {
      logger.error(err);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async inviteByMail(
    context: SessionContext,
    data: { room: Room; email: string }
  ) {
    try {
      context.commit("setLoading", true);
      const res = await RoomService.inviteByMail(data.room.groupId, data.email);
      if (res) {
        context.commit(
          "notifications/displayNotification",
          {
            message: i18n.t("rooms.invitationSent"),
            type: "success"
          },
          { root: true }
        );
      }
    } catch (err) {
      logger.error(err);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("rooms.updateError"),
          type: "error"
        },
        { root: true }
      );
    } finally {
      context.commit("setLoading", false);
    }
  }
};



































































































import Vue from "vue";
import VaButton from "@/components/atoms/VaButton.vue";
import { User } from "@/models/user.interface";
import { GuestRoom, Room } from "@/models/room.interface";
import VueResizable from "vue-resizable";
import { ConferenceAuthData } from "@/models/conferenceAuthData.interface";
import VaLoader from "@/components/atoms/VaLoader.vue";
import tenantService from "@/services/tenantService";
import iconcolorMixin from "@/mixins/iconcolorMixin";
import store from "@/store";

export default Vue.extend({
  components: {
    VaLoader,
    VaButton,
    VueResizable
  },
  mixins: [iconcolorMixin],
  data: () => ({
    fullscreen: false
    // minimized: true
  }),
  computed: {
    logo() {
      return tenantService.requireAsset("logo-square.svg");
    },
    user(): User {
      return this.$store.getters["users/user"];
    },
    room(): Room {
      return this.$store.getters["room/activeRoom"];
    },
    guestRoom(): GuestRoom {
      return this.$store.getters["conference/guestRoom"];
    },
    loading(): Room {
      return this.$store.getters["videoConference/isLoading"];
    },
    videoConfStarted(): Room {
      return this.$store.getters["videoConference/isRunning"];
    },
    authData(): ConferenceAuthData {
      return this.$store.getters["conference/authData"];
    },
    shouldWaitForHost(): boolean {
      const guestRoom = this.$store.getters["conference/guestRoom"];
      const room = this.$store.getters["room/activeRoom"];
      return !(room || (guestRoom && guestRoom.webConferenceActive));
    },
    pinnedCamera(): boolean {
      return store.getters["videoConference/isPinned"];
    },
    minimized(): boolean {
      return store.getters["videoConference/isMinimized"];
    },
    dockIcon(): string {
      return store.getters["videoConference/isPinned"]
        ? "va va-sidebar "
        : "va va-sidebar-1";
    }
  },
  methods: {
    toggleConferenceBox() {
      // if (!this.videoConfStarted) {
      //   // this.minimized = !this.minimized;
      this.$store.commit("videoConference/setMinimized", !this.minimized);
      // } else {
      //   this.$store.commit("videoConference/setMinimized", false);
      //   // this.minimized = false;
      // }
    },
    startConference() {
      this.$store.dispatch("videoConference/init", {
        containerId: "video-conference-container",
        conferenceId:
          this.room?.roomInfo?.webConferenceId || this.guestRoom?.meetingId,
        userNickName:
          this.user?.userNickname || this.authData?.user?.userNickname || "",
        userEmail: this.user?.email || "",
        roomSubject: this.room?.name || "Conference Room"
      });
    },
    toggleFullScreen() {
      /* eslint-disable */
      // @typescript-eslint/no-explicit-any
      const fs = this.$refs.videoconference as any;
      if (
        // @ts-ignore
        !document.fullscreenElement &&
        // @ts-ignore
        !document.mozFullScreenElement &&
        // @ts-ignore
        !document.webkitFullscreenElement &&
        // @ts-ignore
        !document.msFullscreenElement
      ) {
        this.fullscreen = true;
        if (fs.requestFullscreen) {
          fs.requestFullscreen();
        } else if (fs.msRequestFullscreen) {
          fs.msRequestFullscreen();
        } else if (fs.mozRequestFullScreen) {
          fs.mozRequestFullScreen();
        } else if (fs.webkitRequestFullscreen) {
          // @ts-ignore
          fs.webkitRequestFullscreen();
        }
      } else {
        this.fullscreen = false;
        if (document.exitFullscreen) {
          document.exitFullscreen();
          // @ts-ignore
        } else if (document.msExitFullscreen) {
          // @ts-ignore
          document.msExitFullscreen();
          // @ts-ignore
        } else if (document.mozCancelFullScreen) {
          // @ts-ignore
          document.mozCancelFullScreen();
          // @ts-ignore
        } else if (document.webkitExitFullscreen) {
          // @ts-ignore
          document.webkitExitFullscreen();
        }
      }
      /* eslint-enable */
    }
  }
});

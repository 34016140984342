















import Vue from "vue";
import { OauthProvider } from "@/models/oauth.interface.ts";
// import VaButton from "@/components/atoms/VaButton.vue";

export default Vue.extend({
  name: "GoogleSignIn",
  components: {},
  computed: {
    providerType(): number {
      return OauthProvider.Google;
    }
  },
  mounted() {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    document.head.appendChild(script);
    script.onload = () => {
      window.google?.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        callback: this.onSuccess,
        ux_mode: "popup",
        auto_select: false
      });
      window.google?.accounts.id.renderButton(this.$refs.signinBtn, {
        text: "signin", // or 'signup_with' | 'continue_with' | 'signin'
        size: "large", // or 'small' | 'medium'
        width: "148px", // max width 400
        type: "standard",
        theme: "outline", // or 'filled_black' |  'filled_blue'
        logo_alignment: "center" // or 'center'
      });
    };
  },
  methods: {
    // login() {
    //   window.google?.accounts.id.prompt();
    // },
    // eslint-disable-next-line no-undef
    async onSuccess(googleUser: google.accounts.id.CredentialResponse) {
      this.$store.dispatch("session/federateLogin", {
        oauthProviderType: this.providerType,
        callbackUrl: `${window.location.origin}/`,
        authCode: googleUser.credential
      });
    }
  }
});

import axios from "axios";
import * as configService from "@/services/configService";
import { CanvasFile } from "@/models/wb/canvasFile.interface";
import { buildRequestConfig } from "@/utils/ApiHelpers";

//Fetch pdf thumbnail from dedicated service
async function generatePreview(file: CanvasFile): Promise<string> {
  const fileId = file.type === "document" ? file.pdfFileId : file.fileId;
  const resp = await axios.get(
    `${configService.pdfServerBaseUrl()}/api/Service/GetPdfPageAsImage/${fileId}/${
      file.currentPage
    }/${file.size.width}/${file.size.height}`,
    buildRequestConfig("application/octet-stream", "blob")
  );

  const blob = new Blob([resp.data]);
  return URL.createObjectURL(blob);
  // store.dispatch("wb/files/addCachedFile", { uuid: file.fileId, url });
}

export async function GetPdfElementDataUrl(file: CanvasFile): Promise<string> {
  return generatePreview(file);
}

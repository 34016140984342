
























































import Vue from "vue";
import CenterContent from "@/components/templates/CenterContent.vue";
import VaIcon from "@/components/atoms/VaIcon.vue";
import { Room } from "@/models/room.interface";
import VaBadge from "@/components/molecules/VaBadge.vue";
import MetaHelper from "@/services/metaHelper";
import tenantService from "@/services/tenantService";
import store from "@/store";
import roomBackgroundMixin from "@/mixins/roomBackgroundMixin";
import VaButton from "@/components/atoms/VaButton.vue";

export default Vue.extend({
  name: "Rooms",
  components: {
    VaButton,
    CenterContent,
    VaIcon,
    VaBadge
    // UserAutocomplete
  },
  mixins: [roomBackgroundMixin],
  meta() {
    return MetaHelper.buildMeta("rooms");
  },
  data() {
    return {
      roomDialog: false,
      selectedRoom: undefined as Room | undefined
    };
  },
  computed: {
    bgLight() {
      return tenantService.requireBackground("room-waiting.jpg");
    },
    bgDark() {
      return tenantService.requireBackground("room-waiting-dark.jpg");
    },
    rooms(): Room[] {
      return this.$store.getters["room/roomList"];
    },
    scrollerHeight(): number {
      const rooms = this.$store.getters["room/roomList"];
      return rooms.length < 5 ? rooms.length * 75 : 300;
    },
    canCreateRoom(): boolean {
      return this.$store.getters["users/canCreateRoom"];
    }
  },
  methods: {
    goToRoom(room: Room) {
      this.$router.push(
        `/room/${room.isGroup ? "g" : "s"}/${room.destinationId}/session`
      );
    },
    editRoom(room: Room) {
      this.$router.push(
        `/room/${room.isGroup ? "g" : "s"}/${room.destinationId}`
      );
    }
    // onStartChat(user: User) {
    //   this.$router.push(`/room/s/${user.userId}`);
    // }
  },
  mounted() {
    store.dispatch("conference/stop");
  }
});

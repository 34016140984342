





















import Vue from "vue";

export default Vue.extend({
  props: {
    rules: {
      required: false
    },
    name: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    appendIcon: {
      type: String,
      required: false
    },
    prependIcon: {
      type: String,
      required: false
    },
    password: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: String,
      required: false
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    mask: {
      type: String,
      required: false
    }
  },
  data: () => ({
    myValue: ""
  }),
  mounted() {
    this.myValue = this.value;
  },
  watch: {
    value: function(newVal) {
      this.myValue = newVal;
    }
  }
});

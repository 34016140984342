var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-snackbar",
        {
          attrs: {
            bottom: "",
            timeout: _vm.timeout,
            color: _vm.computedType.color,
            outlined: "",
            right: ""
          },
          on: { input: _vm.dismiss },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function(ref) {
                var attrs = ref.attrs
                return [
                  _vm.dismissable
                    ? _c(
                        "va-button",
                        _vm._b(
                          {
                            attrs: {
                              icon: "va va-close-circle",
                              type: "icon",
                              color: _vm.$store.getters["app/darkTheme"]
                                ? "secondary lighten-1"
                                : "secondary darken-3"
                            },
                            on: { click: _vm.dismiss }
                          },
                          "va-button",
                          attrs,
                          false
                        )
                      )
                    : _vm._e()
                ]
              }
            }
          ]),
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _vm.type
            ? _c("va-icon", {
                staticClass: "mr-3",
                attrs: {
                  icon: _vm.computedType.icon,
                  color: _vm.computedType.color
                }
              })
            : _vm._e(),
          _vm._t("default")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
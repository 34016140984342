var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: {
            "offset-y": "",
            left: "",
            "nudge-bottom": 14,
            "close-on-content-click": false
          },
          on: { input: _vm.onClose },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "div",
                                  _vm._g({}, on),
                                  [
                                    _c("va-button", {
                                      attrs: {
                                        icon: "va va-globe",
                                        color: _vm.iconColor,
                                        type: "squareText"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.openOptions = !_vm.openOptions
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("rooms.insertWeb")))])]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.openOptions,
            callback: function($$v) {
              _vm.openOptions = $$v
            },
            expression: "openOptions"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(_vm._s(_vm.$t("conference.insertUrl")))
              ]),
              _c(
                "v-card-text",
                {},
                [
                  _c("div", { staticClass: "mb-6" }, [
                    _vm._v(_vm._s(_vm.$t("conference.iframeDisclaimer")))
                  ]),
                  _c(
                    "v-form",
                    {
                      ref: "confirmForm",
                      attrs: {
                        "lazy-validation": "",
                        onSubmit: "return false;"
                      },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c("va-text-field", {
                        attrs: {
                          rules: [_vm.url],
                          label: _vm.$t("conference.insertUrl"),
                          "append-icon": "va va-plus"
                        },
                        on: { "click:append": _vm.createUrlElement },
                        model: {
                          value: _vm.newUrl,
                          callback: function($$v) {
                            _vm.newUrl = $$v
                          },
                          expression: "newUrl"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "center-content",
    {
      attrs: { "light-bg": _vm.bgLight, "dark-bg": _vm.bgDark },
      scopedSlots: _vm._u([
        {
          key: "headerContent",
          fn: function() {
            return [
              _c("div", { staticClass: "d-flex align-center pa-6" }, [
                _c(
                  "div",
                  {
                    staticClass: "d-flex cursor-pointer text-uppercase",
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/")
                      }
                    }
                  },
                  [
                    _c("va-icon", {
                      attrs: { icon: "va va-direction-left mr-4", size: "s" }
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("rooms.back")) + " ")
                  ],
                  1
                )
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "pa-6" },
        [
          _vm.user
            ? _c(
                "div",
                { staticClass: "d-flex justify-space-between mb-4" },
                [
                  _c("div", [
                    _c("h2", { staticClass: "mb-2" }, [
                      _vm._v(_vm._s(_vm.$t("profile.title")))
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.user.email))])
                  ]),
                  _vm.user.avatar
                    ? _c("avatar-upload", {
                        attrs: { image: _vm.user.avatar },
                        on: { fileChanged: _vm.onAvatarChanged }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.user
            ? _c(
                "v-form",
                {
                  ref: "joinForm",
                  attrs: { "lazy-validation": "", onSubmit: "return false;" },
                  on: { submit: _vm.onUpdateUser },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("va-text-field", {
                        staticClass: "mb-4 mr-4",
                        attrs: {
                          rules: [_vm.required],
                          label: _vm.$t("profile.name"),
                          name: "name",
                          dense: ""
                        },
                        model: {
                          value: _vm.user.firstName,
                          callback: function($$v) {
                            _vm.$set(_vm.user, "firstName", $$v)
                          },
                          expression: "user.firstName"
                        }
                      }),
                      _c("va-text-field", {
                        staticClass: "mb-4",
                        attrs: {
                          rules: [_vm.required],
                          label: _vm.$t("profile.lastName"),
                          name: "lastname",
                          dense: ""
                        },
                        model: {
                          value: _vm.user.lastName,
                          callback: function($$v) {
                            _vm.$set(_vm.user, "lastName", $$v)
                          },
                          expression: "user.lastName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _c("va-text-field", {
                        staticClass: "mr-4",
                        attrs: {
                          rules: [_vm.required],
                          label: _vm.$t("profile.city"),
                          name: "city",
                          dense: ""
                        },
                        model: {
                          value: _vm.user.city,
                          callback: function($$v) {
                            _vm.$set(_vm.user, "city", $$v)
                          },
                          expression: "user.city"
                        }
                      }),
                      _c("va-button", {
                        attrs: {
                          disabled: !_vm.valid || _vm.isLoading,
                          submit: "",
                          cta: _vm.$t("profile.update")
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _vm.mfaEnabled
            ? _c("mfa-settings", { staticClass: "mt-6" })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
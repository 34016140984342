export interface MagoLinkData {
  expires: Date;
  token: string;
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function deserialize(input: any): MagoLinkData {
  return {
    expires: new Date(input.expirationUtc),
    token: input.token || ""
  };
}

export function serialize(input: MagoLinkData): unknown {
  return {
    expirationUtc: input.expires.getTime(),
    token: input.token
  };
}

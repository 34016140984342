




































import Vue from "vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import VaTextArea from "@/components/atoms/VaTextArea.vue";
import validationMixin from "@/mixins/validationMixin";

export default Vue.extend({
  components: {
    VaTextField,
    VaTextArea
  },
  mixins: [validationMixin]
});



















































import Vue from "vue";
import i18n from "@/plugins/lang";
import VaButton from "@/components/atoms/VaButton.vue";
import { MfaProvider, MfaSetup } from "@/models/mfaProvider";
import {
  confirmMFAActivationCode,
  disableMFAProvider,
  sendMFACode,
  verifyMFACode
} from "@/services/authService";
import VaTextField from "@/components/atoms/VaTextField.vue";
import BackupCodesProvider from "@/components/organisms/auth/BackupCodesProvider.vue";
import MfaQrcodeDialog from "@/components/organisms/auth/MFAQrCodeDialog.vue";
import validationMixin from "@/mixins/validationMixin";

export default Vue.extend({
  name: "MfaSettings",
  components: {
    MfaQrcodeDialog,
    BackupCodesProvider,
    VaTextField,
    VaButton
  },
  mixins: [validationMixin],
  beforeMount() {
    this.$store.dispatch("mfa/fetchAvailableProviders");
    this.$store.dispatch("mfa/fetchEnabledProviders");
  },
  data() {
    return {
      dialog: false,
      setup: {} as MfaSetup,
      activeProvider: undefined as MfaProvider | undefined,
      mfaToken: "",
      emailVerificationCode: ""
    };
  },
  computed: {
    isLoading(): boolean {
      return this.$store.getters["mfa/isLoading"];
    },
    providers(): MfaProvider[] {
      return this.$store.getters["mfa/providers"];
    },
    enabledProviders(): MfaProvider[] {
      return this.$store.getters["mfa/enabledProviders"];
    }
  },
  methods: {
    isEnabled(provider: MfaProvider) {
      return this.enabledProviders.some((p: MfaProvider) => {
        return p.type === provider.type;
      });
    },
    resetActivation(): void {
      this.setup = {} as MfaSetup;
      this.dialog = false;
      this.activeProvider = undefined;
      this.mfaToken = "";
    },

    async enableProvider(item: MfaProvider) {
      this.$store.commit("mfa/setLoading", true);
      try {
        this.mfaToken = (await sendMFACode(item)) as string;
        this.activeProvider = item;
        if (item.type === 3) {
          this.setup = item.setup;
          this.dialog = true;
        }
      } catch (e) {
        this.$store.commit("notifications/displayNotification", {
          message: e,
          type: "error"
        });
      } finally {
        this.$store.commit("mfa/setLoading", false);
      }
    },
    async disableProvider(item: MfaProvider) {
      this.$store.commit("mfa/setLoading", true);
      try {
        await disableMFAProvider(item);
        this.$store.dispatch("mfa/fetchEnabledProviders");
        this.$store.commit("notifications/displayNotification", {
          message: i18n.t("mfa.activationSuccess"),
          type: "success"
        });
      } catch (e) {
        this.$store.commit("notifications/displayNotification", {
          message: e,
          type: "error"
        });
      } finally {
        this.$store.commit("mfa/setLoading", false);
      }
    },
    async enableMailProvider() {
      try {
        if (this.mfaToken) {
          const confirmed = await verifyMFACode(
            this.emailVerificationCode,
            this.mfaToken
          );
          if (confirmed) {
            this.$store.commit("notifications/displayConfirmDialog", {
              visible: true,
              title: i18n.t("mfa.confirmActivationTitle"),
              description: `${i18n.t("mfa.confirmEmailDescription")}`,
              callback: this.confirmActivation
            });
          }
        }
      } catch (e) {
        this.$store.commit("notifications/displayNotification", {
          message: i18n.t("mfa.verifyError"),
          type: "error"
        });
        this.resetActivation();
      }
    },

    async confirmActivation(): Promise<void> {
      if (this.mfaToken) {
        try {
          await confirmMFAActivationCode(this.mfaToken);
          this.$store.dispatch("mfa/fetchEnabledProviders");
          this.$store.commit("notifications/displayNotification", {
            message: i18n.t("mfa.codeSent"),
            type: "success"
          });
        } catch (e) {
          this.$store.commit("notifications/displayNotification", {
            message: i18n.t("mfa.activationError"),
            type: "error"
          });
        } finally {
          this.resetActivation();
        }
      }
    }
  }
});

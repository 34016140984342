var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-hover", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var hover = ref.hover
              return [
                _c(
                  "v-card",
                  {
                    staticClass: "cursor-pointer",
                    attrs: { elevation: "0", width: "84", height: "84" },
                    on: { click: _vm.onUpdateAvatar }
                  },
                  [
                    _c("va-avatar", {
                      attrs: { image: _vm.avatar, size: "l" }
                    }),
                    hover
                      ? _c(
                          "v-overlay",
                          {
                            staticClass: "text-center",
                            attrs: { absolute: "" }
                          },
                          [
                            _c("va-icon", {
                              attrs: { icon: "va va-add-file" }
                            }),
                            _c("div", [_vm._v("Upload Image")])
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("input", {
        ref: "uploader",
        staticClass: "d-none",
        attrs: { type: "file", accept: "image/*" },
        on: { change: _vm.updateFile }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
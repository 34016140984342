















import Vue from "vue";
import { ChatMessage } from "@/models/chatMessage.interface";
import VaLogMessage from "@/components/molecules/VaLogMessage.vue";

export default Vue.extend({
  components: {
    VaLogMessage
  },
  computed: {
    messages(): ChatMessage[] {
      return this.$store.getters["chat/logs"];
    },
    pageHeight(): number {
      return document.body.scrollHeight;
    }
  },
  updated() {
    this.scrollBottom();
  },
  mounted() {
    this.scrollBottom();
  },
  watch: {
    messages() {
      this.scrollBottom();
    }
  },
  methods: {
    scrollBottom() {
      this.$vuetify.goTo(this.$refs.logs_bottom as Vue, {
        container: this.$refs.logs_wrapper as Vue
      });
    }
  }
});

export interface ProviderSetup {
  issuer: string;
  account: string;
  encodedSecret: string;
  deepLink: string;
}

export interface MfaProvider {
  setup: ProviderSetup;
  name: string;
  type: number;
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function MfaProviderDeserializer(input: any): MfaProvider {
  return {
    setup: input.providerSetup || undefined,
    name: input.name,
    type: input.providerType
  };
}

export interface MfaSetup {
  issuer?: string;
  account?: string;
  encodedSecret: string;
  deepLink: string;
}

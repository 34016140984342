







































import Vue from "vue";
import VaLogMessage from "@/components/molecules/VaLogMessage.vue";
import VaIcon from "@/components/atoms/VaIcon.vue";
import VaButton from "@/components/atoms/VaButton.vue";

export default Vue.extend({
  components: { VaLogMessage, VaButton, VaIcon },
  data: () => ({}),
  props: {
    email: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.$store.commit("session/setNotConfirmedError", false);
    this.$store.commit("session/setSignupSuccess", false);
  },
  computed: {},
  methods: {}
});

import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex";
import { RootState } from "./index";
import * as AuthService from "@/services/authService";
import {
  AuthData,
  LegacyConfirmData,
  ResetPasswordData,
  SignUpData
} from "@/models/authData.interface";
import Vue from "vue";
import "@/plugins/vueCookies";
import router from "@/router/index";
import logger from "@/services/loggerService";
import i18n from "@/plugins/lang";
import { clone } from "lodash";
import { OauthProvider } from "@/models/oauth.interface";

export interface SessionState {
  authData: AuthData | undefined;
  mfaEnabled: boolean;
  mfaToken: string;
  isLoading: boolean;
  signupSuccess: boolean;
  signupPending: boolean;
  signupError: boolean;
  notConfirmedError: boolean;
}

type SessionContext = ActionContext<SessionState, RootState>;

export const namespaced = true;

export const state = (): SessionState => ({
  authData: Vue.$cookies.get("auth-data") || undefined,
  mfaEnabled: false,
  mfaToken: "",
  isLoading: false,
  signupSuccess: false,
  signupPending: true,
  signupError: false,
  notConfirmedError: false
});

export const getters: GetterTree<SessionState, RootState> = {
  isLoading: (state): boolean => {
    return state.isLoading;
  },
  authData: (state): AuthData | undefined => {
    return state.authData;
  },
  mfaToken: (state): string => {
    return state.mfaToken;
  },
  mfaEnabled: (state): boolean => {
    return state.mfaEnabled;
  },
  isAuthenticated: (state): boolean => {
    return (
      state.authData != undefined &&
      new Date(state.authData.refreshExpires) > new Date()
    );
  },
  signupSuccess: state => {
    return state.signupSuccess;
  },
  signupPending: state => {
    return state.signupPending;
  },
  signupError: state => {
    return state.signupError;
  },
  notConfirmedError: state => {
    return state.notConfirmedError;
  }
};

export const mutations: MutationTree<SessionState> = {
  setLoading(state: SessionState, loading: boolean) {
    state.isLoading = loading;
  },
  setMfaToken(state: SessionState, token: string) {
    state.mfaToken = token;
    state.mfaEnabled = true;
  },
  setAuthdata(state: SessionState, data: AuthData | undefined) {
    state.authData = data;
  },
  setSignupSuccess(state: SessionState, success: boolean) {
    state.signupSuccess = success;
  },
  setSignupPending(state: SessionState, pending: boolean) {
    state.signupPending = pending;
  },
  setSignupError(state: SessionState, error: boolean) {
    state.signupError = error;
  },
  setNotConfirmedError(state: SessionState, error: boolean) {
    state.notConfirmedError = error;
  }
};

export const actions: ActionTree<SessionState, RootState> = {
  async forgotPassword(context: SessionContext, email: string) {
    context.commit("setLoading", true);
    try {
      await AuthService.forgotPassword(email);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("forgotPassword.success"),
          type: "success"
        },
        { root: true }
      );
    } catch (e) {
      if (e?.response?.data?.errorCode == "U410") {
        context.commit("setNotConfirmedError", true);
      }
      logger.error(e);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("forgotPassword.error"),
          type: "error"
        },
        { root: true }
      );
    } finally {
      context.commit("setLoading", false);
    }
  },
  async resetPassword(context: SessionContext, data: ResetPasswordData) {
    context.commit("setSignupSuccess", false);
    context.commit("setLoading", true);
    try {
      await AuthService.resetPassword(data);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("resetPassword.success"),
          type: "success"
        },
        { root: true }
      );
      router.push("/signin");
    } catch (e) {
      logger.error(e);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("resetPassword.error"),
          type: "error"
        },
        { root: true }
      );
    } finally {
      context.commit("setLoading", false);
    }
  },
  async login(
    context: SessionContext,
    data: { username: string; password: string }
  ) {
    context.commit("setLoading", true);
    try {
      const loginResult = await AuthService.login(data.username, data.password);
      if ((loginResult as AuthData).token) {
        const authData = loginResult as AuthData;
        context.commit("setAuthdata", authData);
        const user = await AuthService.getUser();
        context.commit("users/setUser", user, { root: true });
        let chatServerUrl = user.settingsInfo?.chatServerUrl || "";
        //clean trailing slashes
        if (chatServerUrl.endsWith("/"))
          chatServerUrl = chatServerUrl.slice(0, -1);
        authData.chatServerUrl = chatServerUrl;
        context.commit("setAuthdata", authData);
        Vue.$cookies.set("auth-data", authData, authData.refreshExpires);
        router.push("/");
      } else {
        const mfaToken = (loginResult as { mfaToken: string }).mfaToken;
        context.commit("setMfaToken", mfaToken);
      }
    } catch (err) {
      if (err?.response?.data?.errorCode == "U410") {
        context.commit("setNotConfirmedError", true);
      }
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("signin.loginError"),
          type: "error"
        },
        { root: true }
      );
    } finally {
      context.commit("setLoading", false);
    }
  },
  async federateLogin(
    context: SessionContext,
    data: {
      oauthProviderType: OauthProvider;
      callbackUrl: string;
      authCode: string;
    }
  ) {
    context.commit("setLoading", true);
    try {
      const loginResult = await AuthService.federateLogin(
        data.oauthProviderType,
        data.callbackUrl,
        data.authCode
      );
      if ((loginResult as AuthData).token) {
        const authData = loginResult as AuthData;
        context.commit("setAuthdata", authData);
        const user = await AuthService.getUser();
        context.commit("users/setUser", user, { root: true });
        let chatServerUrl = user.settingsInfo?.chatServerUrl || "";
        //clean trailing slashes
        if (chatServerUrl.endsWith("/"))
          chatServerUrl = chatServerUrl.slice(0, -1);
        authData.chatServerUrl = chatServerUrl;
        context.commit("setAuthdata", authData);
        Vue.$cookies.set("auth-data", authData, authData.refreshExpires);
        router.push("/");
      } else {
        const mfaToken = (loginResult as { mfaToken: string }).mfaToken;
        context.commit("setMfaToken", mfaToken);
      }
    } catch (err) {
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("signin.loginError"),
          type: "error"
        },
        { root: true }
      );
    } finally {
      context.commit("setLoading", false);
    }
  },
  async mfaLogin(
    context: SessionContext,
    data: { token: string; code: string }
  ) {
    context.commit("setLoading", true);
    try {
      const authData = await AuthService.mfaLogin(data.token, data.code);
      context.commit("setAuthdata", authData);
      const user = await AuthService.getUser();
      context.commit("users/setUser", user, { root: true });
      let chatServerUrl = user.settingsInfo?.chatServerUrl || "";
      //clean trailing slashes
      if (chatServerUrl.endsWith("/"))
        chatServerUrl = chatServerUrl.slice(0, -1);
      authData.chatServerUrl = chatServerUrl;
      context.commit("setAuthdata", authData);
      context.commit("setMfaToken", "");
      Vue.$cookies.set("auth-data", authData, authData.refreshExpires);
      router.push("/");
    } catch (err) {
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("signin.loginError"),
          type: "error"
        },
        { root: true }
      );
    } finally {
      context.commit("setLoading", false);
    }
  },
  async recoverLogin(context: SessionContext) {
    context.commit("setLoading", true);
    if (!context.state?.authData) {
      logger.warn(new Error(`unable to recover user`));
      context.dispatch("logout");
    } else {
      const user = await AuthService.getUser();
      if (!user) {
        logger.error(new Error(`unable to recover user`));
        context.dispatch("logout");
      }
      context.commit("users/setUser", user, { root: true });
    }
    context.commit("setLoading", false);
  },

  async refreshAuth(context: SessionContext): Promise<void> {
    const oldData = clone(context.state.authData);
    if (!oldData) {
      await context.dispatch("logout");
    } else {
      const newData = await AuthService.refreshToken(oldData);
      context.commit("setAuthdata", newData);
      Vue.$cookies.set("auth-data", newData, newData.refreshExpires);
    }
  },

  logout(context: SessionContext) {
    context.commit("setLoading", true);
    context.commit("setAuthdata", undefined);
    Vue.$cookies.remove("auth-data");
    context.commit("room/setRoomList", [], { root: true });
    context.commit("users/setUser", undefined, { root: true });
    context.commit("setLoading", false);
  },

  async signUp(context: SessionContext, data: SignUpData) {
    context.commit("setLoading", true);
    try {
      await AuthService.signUp(data);
      context.commit("setSignupSuccess", true);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("signUp.success"),
          type: "success"
        },
        { root: true }
      );
    } catch (err) {
      const msg =
        err?.response?.data?.errorCode == "U409"
          ? i18n.t("signUp.addressNotAvailable")
          : err?.response?.data?.message || i18n.t("signUp.error");
      context.commit(
        "notifications/displayNotification",
        {
          // message: i18n.t("signUp.error"),
          message: msg,
          type: "error"
        },
        { root: true }
      );
    } finally {
      context.commit("setLoading", false);
    }
  },

  async inviteSignUp(context: SessionContext, data: SignUpData) {
    context.commit("setLoading", true);
    try {
      await AuthService.inviteSignUp(data);
      context.commit("setSignupSuccess", true);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("signUp.success"),
          type: "success"
        },
        { root: true }
      );
      router.push("/signin");
    } catch (err) {
      context.commit(
        "notifications/displayNotification",
        {
          message: err?.response?.data?.message || i18n.t("signUp.error"),
          type: "error"
        },
        { root: true }
      );
    } finally {
      context.commit("setLoading", false);
    }
  },

  async confirmEmail(
    context: SessionContext,
    data: { email: string; token: string }
  ) {
    context.commit("setLoading", true);
    context.commit("setSignupError", false);
    try {
      await AuthService.confirmEmail(data.email, data.token);
      context.commit("setSignupPending", false);
      // context.commit("setSignupSuccess", true);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("confirm.mailConfirmed"),
          type: "success"
        },
        { root: true }
      );
    } catch (e) {
      if (e?.response?.data?.errorCode == "U410") {
        context.commit("setNotConfirmedError", true);
      }
      context.commit("setSignupError", true);
      logger.error(e);
      context.commit(
        "notifications/displayNotification",
        {
          message:
            e?.response?.data?.message || i18n.t("confirm.mailConfirmError"),
          type: "error"
        },
        { root: true }
      );
    } finally {
      context.commit("setLoading", false);
    }
  },

  async confirmResend(context: SessionContext, email: string) {
    context.commit("setLoading", true);
    context.commit("setSignupError", false);
    context.commit("setSignupSuccess", false);
    try {
      await AuthService.confirmResend(email);
      context.commit("setSignupPending", false);
      context.commit("setSignupSuccess", true);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("confirm.resendSuccess"),
          type: "success"
        },
        { root: true }
      );
    } catch (e) {
      context.commit("setSignupError", true);
      logger.error(e);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("confirm.mailConfirmError"),
          type: "error"
        },
        { root: true }
      );
    } finally {
      context.commit("setLoading", false);
    }
  },

  //LEGACY ACTIONS ----------------------------------------------------------------
  async confirmEmailLegacy(
    context: SessionContext,
    data: { email: string; token: string }
  ) {
    context.commit("setLoading", true);
    context.commit("setSignupError", false);
    try {
      await AuthService.confirmEmailLegacy(data.email, data.token);
      context.commit("setSignupPending", false);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("confirm.mailConfirmed"),
          type: "success"
        },
        { root: true }
      );
    } catch (e) {
      context.commit("setSignupError", true);
      logger.error(e);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("confirm.mailConfirmError"),
          type: "error"
        },
        { root: true }
      );
    } finally {
      context.commit("setLoading", false);
    }
  },

  async confirmInvitationLegacyNoInfo(
    context: SessionContext,
    data: { email: string; token: string }
  ) {
    context.commit("setLoading", true);
    context.commit("setSignupError", false);
    try {
      await AuthService.confirmInvitationLegacyNoInfo(data.email, data.token);
      context.commit("setSignupPending", false);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("confirm.mailConfirmed"),
          type: "success"
        },
        { root: true }
      );
      router.push("/signin");
    } catch (e) {
      context.commit("setSignupError", true);
      logger.error(e);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("confirm.mailConfirmError"),
          type: "error"
        },
        { root: true }
      );
    } finally {
      context.commit("setLoading", false);
    }
  },
  async confirmInvitationLegacyFull(
    context: SessionContext,
    data: LegacyConfirmData
  ) {
    context.commit("setLoading", true);
    context.commit("setSignupError", false);
    try {
      await AuthService.confirmInvitationLegacyFull(data);
      context.commit("setSignupPending", false);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("confirm.mailConfirmed"),
          type: "success"
        },
        { root: true }
      );
      router.push("/signin");
    } catch (e) {
      context.commit("setSignupError", true);
      logger.error(e);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("confirm.mailConfirmError"),
          type: "error"
        },
        { root: true }
      );
    } finally {
      context.commit("setLoading", false);
    }
  },

  async resetPasswordLegacy(context: SessionContext, data: ResetPasswordData) {
    context.commit("setSignupSuccess", false);
    context.commit("setLoading", true);
    try {
      await AuthService.resetPasswordLegacy(data);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("resetPassword.success"),
          type: "success"
        },
        { root: true }
      );
      router.push("/signin");
    } catch (e) {
      logger.error(e);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("resetPassword.error"),
          type: "error"
        },
        { root: true }
      );
    } finally {
      context.commit("setLoading", false);
    }
  }
};

export interface Point {
  x: number;
  y: number;
  pressure: number;
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function PointDeserializer(input: any): Point {
  return {
    x: input.X || 0,
    y: input.Y || 0,
    pressure: input.PressureFactor || 0
  };
}

export function SocketPointsSerializer(point: Point): string {
  return `${point.x},${point.y},${point.pressure}`;
}

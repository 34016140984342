var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "ms-btn rounded border-a federated-button",
      on: { click: _vm.handleSignIn }
    },
    [
      _c("img", {
        staticClass: "ms-icon",
        attrs: { src: require("@/assets/img/ms-pictogram.svg") }
      }),
      _vm._v(" " + _vm._s(_vm.$t("signin.cta")) + " ")
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "federated-button rounded border-a d-flex overflow-hidden"
      },
      [
        _c("div", {
          attrs: {
            id: "appleid-signin",
            "data-color": "white",
            "data-border": "false",
            "data-type": "sign-in",
            "data-mode": "center-align",
            "data-size": "38",
            "data-logo-size": "medium"
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapperCentered" },
    [
      _c("div", { staticClass: "secondary-box", attrs: { id: "wb-topbar" } }, [
        _c(
          "div",
          { staticClass: "d-flex border-b pa-4 align-center" },
          [
            _c("va-avatar", {
              staticClass: "mr-4",
              attrs: {
                image: _vm.room && _vm.room.avatar ? _vm.room.avatar : _vm.logo,
                size: "m"
              }
            }),
            _c(
              "div",
              {
                staticClass: "d-flex align-center mr-2 ",
                attrs: { id: "roomName" }
              },
              [
                _vm.room
                  ? _c("div", { staticClass: "font-weight-bold" }, [
                      _vm._v(_vm._s(_vm.room.name))
                    ])
                  : _c("div", [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.$t("conference.id")) + ":")
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.authData ? _vm.authData.meetingId : "") +
                          " "
                      ),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.$t("conference.pin")) + ":")
                      ]),
                      _vm._v(
                        " " + _vm._s(_vm.authData ? _vm.authData.pin : "") + " "
                      )
                    ])
              ]
            ),
            _c("v-divider", {
              staticClass: "mx-2 my-1",
              attrs: { vertical: "" }
            }),
            _vm.room ? _c("popup-chat", { staticClass: "mr-2" }) : _vm._e(),
            _c("videoconference", { staticClass: "mr-2" }),
            _vm.isWriter ? _c("share-room", {}) : _vm._e(),
            _c("whiteboard-page-selector", {
              staticClass: "mx-auto",
              attrs: { id: "page-tool" }
            }),
            _c("conference-users"),
            _vm.isWriter ? _c("invite-users") : _vm._e(),
            _c("v-divider", {
              staticClass: "mx-2 my-1",
              attrs: { vertical: "" }
            }),
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "div",
                          _vm._g({}, on),
                          [
                            _c(
                              "va-button",
                              {
                                attrs: {
                                  id: "leave-room-btn",
                                  color: _vm.iconColor,
                                  type: "squareText"
                                },
                                on: { click: _vm.leaveConference }
                              },
                              [_c("v-icon", [_vm._v("va va-close-circle")])],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("rooms.exit")))])]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex px-4 py-1 border-b align-center" },
          [
            _vm.isWriter
              ? _c("file-upload-btn", { attrs: { id: "upload-tool" } })
              : _vm._e(),
            _vm.isWriter
              ? _c("create-textbox-btn", {
                  attrs: { id: "textbox-create-tool" }
                })
              : _vm._e(),
            _vm.isWriter
              ? _c("create-post-it-btn", {
                  attrs: { id: "postit-create-tool" }
                })
              : _vm._e(),
            _vm.isWriter
              ? _c("create-url-btn", { attrs: { id: "url-create-tool" } })
              : _vm._e(),
            _c("zoom-controller", {
              staticClass: "ml-auto",
              attrs: { id: "zoom-tool" }
            }),
            _c("v-divider", {
              staticClass: "mx-2 my-1",
              attrs: { vertical: "" }
            }),
            _vm.isWriter
              ? _c("background-tool", { attrs: { id: "background-tool" } })
              : _vm._e(),
            _c("light-switcher", { attrs: { hideBorder: "" } })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "floating-toolbar pl-4" },
        [_c("toolbar-left")],
        1
      ),
      _c("div", { staticClass: "d-flex" }, [
        _c("div", { staticClass: "flex-grow-1" }, [
          _c(
            "div",
            {
              class: "" + (_vm.pinnedCamera ? "pinnedCamera" : ""),
              attrs: { id: "wb-container" }
            },
            [_c("Whiteboard")],
            1
          )
        ]),
        _vm.pinnedCamera
          ? _c("div", { attrs: { id: "pinned-camera-spacer" } })
          : _vm._e()
      ]),
      _c("confirm-dialog")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
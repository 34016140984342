var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoading
    ? _c("v-progress-circular", {
        attrs: {
          indeterminate: "",
          size: _vm.pixelSize,
          width: 2,
          color: _vm.color
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import "@/plugins/vueCookies";
import axios from "axios";
import * as configService from "@/services/configService";
import {
  ChatMessage,
  ChatMessageDeserializer
} from "@/models/chatMessage.interface";
import {
  Room,
  RoomDeserializer,
  RoomInfoDeserializer
} from "@/models/room.interface";

export async function getRoom(roomId: string, single: boolean): Promise<Room> {
  let room;
  if (!single) {
    const resp = await axios.get(
      `${configService.getRoomDetailUrl()}/${roomId}`
    );
    room = RoomDeserializer(resp.data);
  } else {
    //HACK: fetch all rooms and keep only the single selected
    const resp = await axios.get(configService.getRoomListUrl());
    room = RoomDeserializer(
      /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
      resp.data.find((room: any) => {
        return room.DestinationId === roomId || room.GroupId === roomId;
      })
    );
  }
  const respInfo = await axios.get(
    `${configService.getRoomInfoUrl()}/${roomId}?isGroup=${!single}`
  );
  if (respInfo.data) {
    room.roomInfo = RoomInfoDeserializer(respInfo.data);
  }
  return room;
}

export async function getRoomMessages(
  roomId: string,
  length = 50
): Promise<ChatMessage[]> {
  const resp = await axios.get(
    `${configService.getRoomMessagesUrl()}?startMessageId=null&roomId=${roomId}&length=${length}&messageTypes=500`
  );
  return resp.data.Messages.map((rawMsg: unknown) => {
    return ChatMessageDeserializer(rawMsg);
  });
}

export async function getRoomLogs(
  roomId: string,
  length = 50
): Promise<ChatMessage[]> {
  const resp = await axios.get(
    `${configService.getRoomMessagesUrl()}?startMessageId=null&roomId=${roomId}&length=${length}&messageTypes=110,120,130,140,150`
  );
  return resp.data.Messages.map((rawMsg: unknown) => {
    return ChatMessageDeserializer(rawMsg);
  });
}

export async function getRoomList(): Promise<Room[]> {
  const resp = await axios.get(configService.getRoomListUrl());
  return resp.data.map((rawRoom: unknown) => {
    return RoomDeserializer(rawRoom);
  });
}

export async function createRoom(room: Room): Promise<Room> {
  //todo: use serializer
  const users = room.users?.length
    ? room.users?.map(user => {
        return user.userId;
      })
    : [];

  const resp = await axios.post(configService.getRoomDetailUrl(), {
    Name: room.name,
    UserIds: users
  });
  return RoomDeserializer(resp.data);
}

export async function editRoom(room: Room): Promise<Room> {
  //todo: use serializer
  const resp = await axios.put(configService.getRoomDetailUrl(), {
    GroupId: room.groupId,
    Name: room.name,
    Description: room.description
  });
  return RoomDeserializer(resp.data);
}

export async function uploadAvatar(data: File, room: Room): Promise<string> {
  if (data.type == "image/jpeg") {
    //change ".jpeg" files in ".jpg"
    const blob = data.slice(0, data.size, "image/jpeg");
    data = new File([blob], "avatar.jpg", { type: "image/jpeg" });
  }
  const formData = new FormData();
  formData.append("attachment", data);
  const resp = await axios.post(
    `${configService.setRoomAvatar()}/${room.groupId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return resp.data;
}

export async function leaveRoom(room: Room): Promise<boolean> {
  let resp;
  if (room.isGroup) {
    resp = await axios.post(
      `${configService.getLeaveRoomUrl()}/${room.destinationId}`,
      {}
    );
  } else {
    resp = await axios.post(
      `${configService.getLeaveSingleRoomUrl()}/${room.destinationId}`,
      {}
    );
  }
  return resp.data;
}

export async function updateRoom(room: Room): Promise<Room> {
  const resp = await axios.put(`${configService.getRoomUpdateUrl()}`, {
    groupId: room.groupId,
    name: room.name,
    description: room.description,
    groupStyle: room.groupStyle,
    userIds: room.users?.map(u => u.userId)
  });
  return RoomDeserializer(resp.data);
}
export async function inviteByMail(
  roomId: string,
  email: string
): Promise<boolean> {
  return !!(await axios.post(`${configService.getInviteByMailUrl()}`, {
    roomId: roomId,
    emails: [email]
  }));
}

export async function removeUser(
  roomId: string,
  userId: string
): Promise<boolean> {
  return !!(await axios.post(
    `${configService.getRoomRemoveUserUrl()}/${roomId}/${userId}`
  ));
}

import { render, staticRenderFns } from "./PopupPoll.vue?vue&type=template&id=07641146&scoped=true&"
import script from "./PopupPoll.vue?vue&type=script&lang=ts&"
export * from "./PopupPoll.vue?vue&type=script&lang=ts&"
import style0 from "./PopupPoll.vue?vue&type=style&index=0&id=07641146&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07641146",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBadge,VBtn,VCheckbox,VIcon,VRadio,VRadioGroup,VStepper,VStepperContent,VStepperHeader,VStepperItems,VStepperStep,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('07641146')) {
      api.createRecord('07641146', component.options)
    } else {
      api.reload('07641146', component.options)
    }
    module.hot.accept("./PopupPoll.vue?vue&type=template&id=07641146&scoped=true&", function () {
      api.rerender('07641146', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/organisms/PopupPoll.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("auth-content", [
    _c(
      "div",
      { staticClass: "px-6 py-8 text-center" },
      [
        !_vm.token
          ? _c(
              "div",
              [
                _c("h2", [_vm._v(_vm._s(_vm.$t("confirm.title")))]),
                _c("div", { staticClass: "mt-2 mb-8" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("confirm.description")) +
                      ": " +
                      _vm._s(_vm.$route.query.email) +
                      " "
                  )
                ]),
                _c("va-loader", {
                  staticClass: "mb-4",
                  attrs: {
                    isLoading: _vm.pending && !_vm.error,
                    color: "primary"
                  }
                }),
                _vm.error
                  ? _c("va-log-message", { attrs: { type: "error" } }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("confirm.mailConfirmError")) + " "
                      )
                    ])
                  : _vm._e(),
                !_vm.pending
                  ? _c("va-log-message", { attrs: { type: "info" } }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("resetPassword.criteria")) + " "
                      )
                    ])
                  : _vm._e(),
                _c(
                  "v-form",
                  {
                    ref: "confirmForm",
                    attrs: { "lazy-validation": "", onSubmit: "return false;" },
                    on: { submit: _vm.onSubmit },
                    model: {
                      value: _vm.valid,
                      callback: function($$v) {
                        _vm.valid = $$v
                      },
                      expression: "valid"
                    }
                  },
                  [
                    _c("va-text-field", {
                      staticClass: "mb-8",
                      attrs: {
                        rules: [_vm.required, _vm.strongPass],
                        disabled: _vm.pending,
                        name: "password",
                        label: _vm.$t("forgotPassword.password"),
                        password: ""
                      },
                      model: {
                        value: _vm.pass,
                        callback: function($$v) {
                          _vm.pass = $$v
                        },
                        expression: "pass"
                      }
                    }),
                    _c("va-text-field", {
                      staticClass: "mb-1",
                      attrs: {
                        rules: [_vm.mustMatch(_vm.pass, _vm.repeatPass)],
                        disabled: _vm.pending,
                        name: "password",
                        label: _vm.$t("forgotPassword.repeatPassword"),
                        password: ""
                      },
                      model: {
                        value: _vm.repeatPass,
                        callback: function($$v) {
                          _vm.repeatPass = $$v
                        },
                        expression: "repeatPass"
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "text-right" },
                      [
                        _c("router-link", { attrs: { to: "/presignin" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("confirm.backToSignIn")) + " "
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        !_vm.success
                          ? _c("va-button", {
                              staticClass: "flex-grow-1 mt-6",
                              attrs: {
                                disabled: !_vm.valid || _vm.pending,
                                submit: "",
                                loading:
                                  _vm.$store.getters["session/isLoading"],
                                cta: _vm.$t("confirm.cta")
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _c("mfa-login")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
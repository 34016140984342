







































import Vue from "vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import MetaHelper from "@/services/metaHelper";
import validationMixin from "@/mixins/validationMixin";
import UserNonConfirmedError from "@/components/organisms/UserNonConfirmedError.vue";
import CenterContent from "@/components/templates/CenterContent.vue";
import roomBackgroundMixin from "@/mixins/roomBackgroundMixin";

export default Vue.extend({
  name: "SignIn",
  components: {
    UserNonConfirmedError,
    VaButton,
    CenterContent,
    VaTextField
  },
  mixins: [validationMixin, roomBackgroundMixin],
  meta() {
    return MetaHelper.buildMeta("forgotPass");
  },
  data: () => ({
    email: "",
    valid: true
  }),
  computed: {},
  methods: {
    onSubmit() {
      if (
        (this.$refs.forgotForm as Vue & { validate: () => boolean }).validate()
      ) {
        this.$store.dispatch("session/forgotPassword", this.email);
      }
    }
  }
});

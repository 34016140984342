var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c("h2", [_vm._v("VA-Button")]),
        _c("h3", [_vm._v("Type & Icon")]),
        _c("v-divider", { staticClass: "mb-4" }),
        _c(
          "div",
          { staticClass: "d-flex justify-space-between  my-4" },
          [
            _c("va-button", { attrs: { cta: "bottone" } }),
            _c("va-button", { attrs: { cta: "bottone", rounded: true } }),
            _c("va-button", { attrs: { cta: "bottone", icon: "va va-globe" } }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                icon: "va va-globe",
                "icon-position": "append"
              }
            }),
            _c("va-button", { attrs: { icon: "va va-globe" } }),
            _c("va-button", { attrs: { cta: "bottone", disabled: true } }),
            _c("va-button", { attrs: { cta: "bottone", loading: true } })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex justify-space-between  my-4" },
          [
            _c("va-button", { attrs: { cta: "bottone", type: "text" } }),
            _c("va-button", {
              attrs: { cta: "bottone", type: "text", rounded: true }
            }),
            _c("va-button", {
              attrs: { cta: "bottone", icon: "va va-globe", type: "text" }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                icon: "va va-globe",
                "icon-position": "append",
                type: "text"
              }
            }),
            _c("va-button", { attrs: { icon: "va va-globe", type: "text" } }),
            _c("va-button", {
              attrs: { cta: "bottone", type: "text", disabled: true }
            }),
            _c("va-button", {
              attrs: { cta: "bottone", type: "text", loading: true }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex justify-space-between  my-4" },
          [
            _c("va-button", { attrs: { cta: "bottone", type: "outline" } }),
            _c("va-button", {
              attrs: { cta: "bottone", type: "outline", rounded: true }
            }),
            _c("va-button", {
              attrs: { cta: "bottone", icon: "va va-globe", type: "outline" }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                icon: "va va-globe",
                "icon-position": "append",
                type: "outline"
              }
            }),
            _c("va-button", {
              attrs: { icon: "va va-globe", type: "outline" }
            }),
            _c("va-button", {
              attrs: { cta: "bottone", type: "outline", disabled: true }
            }),
            _c("va-button", {
              attrs: { cta: "bottone", type: "outline", loading: true }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex justify-space-between  my-4" },
          [
            _c("va-button", { attrs: { cta: "bottone", type: "shadow" } }),
            _c("va-button", {
              attrs: { cta: "bottone", type: "shadow", rounded: true }
            }),
            _c("va-button", {
              attrs: { cta: "bottone", icon: "va va-globe", type: "shadow" }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                icon: "va va-globe",
                "icon-position": "append",
                type: "shadow"
              }
            }),
            _c("va-button", { attrs: { icon: "va va-globe", type: "shadow" } }),
            _c("va-button", {
              attrs: { cta: "bottone", type: "shadow", disabled: true }
            }),
            _c("va-button", {
              attrs: { cta: "bottone", type: "shadow", loading: true }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex justify-space-between  my-4" },
          [
            _c("va-button", { attrs: { cta: "b", type: "icon" } }),
            _c("va-button", {
              attrs: { cta: "b", type: "icon", rounded: true }
            }),
            _c("va-button", {
              attrs: { cta: "b", icon: "va va-globe", type: "icon" }
            }),
            _c("va-button", {
              attrs: {
                cta: "b",
                icon: "va va-globe",
                "icon-position": "append",
                type: "icon"
              }
            }),
            _c("va-button", { attrs: { icon: "va va-globe", type: "icon" } }),
            _c("va-button", {
              attrs: { cta: "b", type: "icon", disabled: true }
            }),
            _c("va-button", {
              attrs: { cta: "b", type: "icon", loading: true }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex justify-space-between  my-4" },
          [
            _c("va-button", { attrs: { cta: "b", type: "circle" } }),
            _c("va-button", {
              attrs: { cta: "b", type: "circle", rounded: true }
            }),
            _c("va-button", {
              attrs: { cta: "b", icon: "va va-globe", type: "circle" }
            }),
            _c("va-button", {
              attrs: {
                cta: "b",
                icon: "va va-globe",
                "icon-position": "append",
                type: "circle"
              }
            }),
            _c("va-button", { attrs: { icon: "va va-globe", type: "circle" } }),
            _c("va-button", {
              attrs: { cta: "b", type: "circle", disabled: true }
            }),
            _c("va-button", {
              attrs: { cta: "b", type: "circle", loading: true }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex justify-space-between  my-4" },
          [
            _c("va-button", { attrs: { cta: "b", type: "square" } }),
            _c("va-button", {
              attrs: { cta: "b", type: "square", rounded: true }
            }),
            _c("va-button", {
              attrs: { cta: "b", icon: "va va-globe", type: "square" }
            }),
            _c("va-button", {
              attrs: {
                cta: "b",
                icon: "va va-globe",
                "icon-position": "append",
                type: "square"
              }
            }),
            _c("va-button", { attrs: { icon: "va va-globe", type: "square" } }),
            _c("va-button", {
              attrs: { cta: "b", type: "square", disabled: true }
            }),
            _c("va-button", {
              attrs: { cta: "b", type: "square", loading: true }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex justify-space-between  my-4" },
          [
            _c("va-button", { attrs: { cta: "b", type: "squareText" } }),
            _c("va-button", {
              attrs: { cta: "b", type: "squareText", rounded: true }
            }),
            _c("va-button", {
              attrs: { cta: "b", icon: "va va-globe", type: "squareText" }
            }),
            _c("va-button", {
              attrs: {
                cta: "b",
                icon: "va va-globe",
                "icon-position": "append",
                type: "squareText"
              }
            }),
            _c("va-button", {
              attrs: { icon: "va va-globe", type: "squareText" }
            }),
            _c("va-button", {
              attrs: { cta: "b", type: "squareText", disabled: true }
            }),
            _c("va-button", {
              attrs: { cta: "b", type: "squareText", loading: true }
            })
          ],
          1
        ),
        _c("h3", [_vm._v("Color")]),
        _c("v-divider", { staticClass: "mb-4" }),
        _c(
          "div",
          { staticClass: "d-flex justify-space-between  my-4" },
          [
            _c("va-button", { attrs: { cta: "bottone", color: "primary" } }),
            _c("va-button", {
              attrs: { cta: "bottone", color: "primary lighten-1" }
            }),
            _c("va-button", {
              attrs: { cta: "bottone", color: "primary lighten-2" }
            }),
            _c("va-button", {
              attrs: { cta: "bottone", color: "primary lighten-3" }
            }),
            _c("va-button", {
              attrs: { cta: "bottone", color: "primary lighten-4" }
            }),
            _c("va-button", {
              attrs: { cta: "bottone", color: "primary darken-1" }
            }),
            _c("va-button", {
              attrs: { cta: "bottone", color: "primary darken-2" }
            }),
            _c("va-button", {
              attrs: { cta: "bottone", color: "primary darken-3" }
            }),
            _c("va-button", {
              attrs: { cta: "bottone", color: "primary darken-4" }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex justify-space-between  my-4" },
          [
            _c("va-button", {
              attrs: { cta: "bottone", color: "primary", type: "outline" }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "primary lighten-1",
                type: "outline"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "primary lighten-2",
                type: "outline"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "primary lighten-3",
                type: "outline"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "primary lighten-4",
                type: "outline"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "primary darken-1",
                type: "outline"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "primary darken-2",
                type: "outline"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "primary darken-3",
                type: "outline"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "primary darken-4",
                type: "outline"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex justify-space-between  my-4" },
          [
            _c("va-button", {
              attrs: { cta: "bottone", color: "primary", type: "outline" }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "primary lighten-1",
                type: "text"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "primary lighten-2",
                type: "text"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "primary lighten-3",
                type: "text"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "primary lighten-4",
                type: "text"
              }
            }),
            _c("va-button", {
              attrs: { cta: "bottone", color: "primary darken-1", type: "text" }
            }),
            _c("va-button", {
              attrs: { cta: "bottone", color: "primary darken-2", type: "text" }
            }),
            _c("va-button", {
              attrs: { cta: "bottone", color: "primary darken-3", type: "text" }
            }),
            _c("va-button", {
              attrs: { cta: "bottone", color: "primary darken-4", type: "text" }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex justify-space-between  my-4" },
          [
            _c("va-button", { attrs: { cta: "bottone", color: "secondary" } }),
            _c("va-button", {
              attrs: { cta: "bottone", color: "secondary lighten-1" }
            }),
            _c("va-button", {
              attrs: { cta: "bottone", color: "secondary lighten-2" }
            }),
            _c("va-button", {
              attrs: { cta: "bottone", color: "secondary lighten-3" }
            }),
            _c("va-button", {
              attrs: { cta: "bottone", color: "secondary lighten-4" }
            }),
            _c("va-button", {
              attrs: { cta: "bottone", color: "secondary darken-1" }
            }),
            _c("va-button", {
              attrs: { cta: "bottone", color: "secondary darken-2" }
            }),
            _c("va-button", {
              attrs: { cta: "bottone", color: "secondary darken-3" }
            }),
            _c("va-button", {
              attrs: { cta: "bottone", color: "secondary darken-4" }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex justify-space-between  my-4" },
          [
            _c("va-button", {
              attrs: { cta: "bottone", color: "secondary", type: "outline" }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "secondary lighten-1",
                type: "outline"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "secondary lighten-2",
                type: "outline"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "secondary lighten-3",
                type: "outline"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "secondary lighten-4",
                type: "outline"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "secondary darken-1",
                type: "outline"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "secondary darken-2",
                type: "outline"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "secondary darken-3",
                type: "outline"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "secondary darken-4",
                type: "outline"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex justify-space-between  my-4" },
          [
            _c("va-button", {
              attrs: { cta: "bottone", color: "secondary", type: "text" }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "secondary lighten-1",
                type: "text"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "secondary lighten-2",
                type: "text"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "secondary lighten-3",
                type: "text"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "secondary lighten-4",
                type: "text"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "secondary darken-1",
                type: "text"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "secondary darken-2",
                type: "text"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "secondary darken-3",
                type: "text"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                color: "secondary darken-4",
                type: "text"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex justify-space-between  my-4" },
          [
            _c("va-button", {
              attrs: { icon: "va va-globe", color: "secondary", type: "square" }
            }),
            _c("va-button", {
              attrs: {
                icon: "va va-globe",
                color: "secondary lighten-1",
                type: "square"
              }
            }),
            _c("va-button", {
              attrs: {
                icon: "va va-globe",
                color: "secondary lighten-2",
                type: "square"
              }
            }),
            _c("va-button", {
              attrs: {
                icon: "va va-globe",
                color: "secondary lighten-3",
                type: "square"
              }
            }),
            _c("va-button", {
              attrs: {
                icon: "va va-globe",
                color: "secondary lighten-4",
                type: "square"
              }
            }),
            _c("va-button", {
              attrs: {
                icon: "va va-globe",
                color: "secondary darken-1",
                type: "square"
              }
            }),
            _c("va-button", {
              attrs: {
                icon: "va va-globe",
                color: "secondary darken-2",
                type: "square"
              }
            }),
            _c("va-button", {
              attrs: {
                icon: "va va-globe",
                color: "secondary darken-3",
                type: "square"
              }
            }),
            _c("va-button", {
              attrs: {
                icon: "va va-globe",
                color: "secondary darken-4",
                type: "square"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex justify-space-between  my-4" },
          [
            _c("va-button", {
              attrs: { icon: "va va-globe", color: "primary", type: "square" }
            }),
            _c("va-button", {
              attrs: {
                icon: "va va-globe",
                color: "primary lighten-1",
                type: "square"
              }
            }),
            _c("va-button", {
              attrs: {
                icon: "va va-globe",
                color: "primary lighten-2",
                type: "square"
              }
            }),
            _c("va-button", {
              attrs: {
                icon: "va va-globe",
                color: "primary lighten-3",
                type: "square"
              }
            }),
            _c("va-button", {
              attrs: {
                icon: "va va-globe",
                color: "primary lighten-4",
                type: "square"
              }
            }),
            _c("va-button", {
              attrs: {
                icon: "va va-globe",
                color: "primary darken-1",
                type: "square"
              }
            }),
            _c("va-button", {
              attrs: {
                icon: "va va-globe",
                color: "primary darken-2",
                type: "square"
              }
            }),
            _c("va-button", {
              attrs: {
                icon: "va va-globe",
                color: "primary darken-3",
                type: "square"
              }
            }),
            _c("va-button", {
              attrs: {
                icon: "va va-globe",
                color: "primary darken-4",
                type: "square"
              }
            })
          ],
          1
        ),
        _c("h3", [_vm._v("Size")]),
        _c("v-divider", { staticClass: "mb-4" }),
        _c(
          "div",
          { staticClass: "d-flex justify-space-between  my-4" },
          [
            _c("va-button", { attrs: { cta: "bottone", size: "xs" } }),
            _c("va-button", { attrs: { cta: "bottone", size: "s" } }),
            _c("va-button", { attrs: { cta: "bottone", size: "m" } }),
            _c("va-button", { attrs: { cta: "bottone", size: "l" } }),
            _c("va-button", { attrs: { cta: "bottone", size: "xl" } })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex justify-space-between  my-4" },
          [
            _c("va-button", {
              attrs: {
                cta: "bottone",
                type: "outline",
                rounded: true,
                size: "xs"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                type: "outline",
                rounded: true,
                size: "s"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                type: "outline",
                rounded: true,
                size: "m"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                type: "outline",
                rounded: true,
                size: "l"
              }
            }),
            _c("va-button", {
              attrs: {
                cta: "bottone",
                type: "outline",
                rounded: true,
                size: "xl"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex justify-space-between  my-4" },
          [
            _c("va-button", { attrs: { icon: "va va-globe", size: "xs" } }),
            _c("va-button", { attrs: { icon: "va va-globe", size: "s" } }),
            _c("va-button", { attrs: { icon: "va va-globe", size: "m" } }),
            _c("va-button", { attrs: { icon: "va va-globe", size: "l" } }),
            _c("va-button", { attrs: { icon: "va va-globe", size: "xl" } })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "center-content",
    { attrs: { "light-bg": _vm.bgLight, "dark-bg": _vm.bgDark } },
    [
      _c(
        "div",
        { staticClass: "px-6 py-8 text-center" },
        [
          _c("h2", [_vm._v(_vm._s(_vm.$t("resetPassword.title")))]),
          _c("div", { staticClass: "mt-2 mb-8" }, [
            _vm._v(_vm._s(_vm.$t("resetPassword.description")))
          ]),
          _c(
            "v-form",
            {
              ref: "resetForm",
              attrs: { "lazy-validation": "", onSubmit: "return false;" },
              on: { submit: _vm.onReset },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c("va-text-field", {
                staticClass: "mb-8",
                attrs: {
                  rules: [_vm.required, _vm.strongPass],
                  name: "password",
                  label: _vm.$t("forgotPassword.password"),
                  password: ""
                },
                model: {
                  value: _vm.pass,
                  callback: function($$v) {
                    _vm.pass = $$v
                  },
                  expression: "pass"
                }
              }),
              _c("va-text-field", {
                staticClass: "mb-1",
                attrs: {
                  rules: [_vm.mustMatch(_vm.pass, _vm.repeatPass)],
                  name: "password",
                  label: _vm.$t("forgotPassword.repeatPassword"),
                  password: ""
                },
                model: {
                  value: _vm.repeatPass,
                  callback: function($$v) {
                    _vm.repeatPass = $$v
                  },
                  expression: "repeatPass"
                }
              }),
              _c(
                "div",
                { staticClass: "text-right" },
                [
                  _c("router-link", { attrs: { to: "/presignin" } }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("forgotPassword.backToSignIn")) + " "
                    )
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("va-button", {
                    staticClass: "flex-grow-1 mt-6",
                    attrs: {
                      disabled: !_vm.valid,
                      submit: "",
                      loading: _vm.$store.getters["session/isLoading"],
                      cta: _vm.$t("resetPassword.submit")
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
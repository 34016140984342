



























































































import Vue from "vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import MetaHelper from "@/services/metaHelper";
import MfaLogin from "@/components/organisms/auth/MfaLogin.vue";
import validationMixin from "@/mixins/validationMixin";
import VaLogMessage from "@/components/molecules/VaLogMessage.vue";
import VaLoader from "@/components/atoms/VaLoader.vue";
import CenterContent from "@/components/templates/CenterContent.vue";
import roomBackgroundMixin from "@/mixins/roomBackgroundMixin";

export default Vue.extend({
  name: "InviteSignup",
  components: {
    CenterContent,
    VaLoader,
    VaLogMessage,
    MfaLogin,
    VaButton,
    VaTextField
  },
  mixins: [validationMixin, roomBackgroundMixin],
  meta() {
    return MetaHelper.buildMeta("signUp");
  },
  data: () => ({
    firstname: "",
    lastname: "",
    email: "",
    pass: "",
    repeatPass: "",
    valid: true
  }),
  computed: {
    pending(): boolean {
      return this.$store.getters["session/signupPending"];
    },
    error(): boolean {
      return this.$store.getters["session/signupError"];
    },
    success(): boolean {
      return this.$store.getters["session/signupSuccess"];
    },
    token(): string {
      return this.$store.getters["session/mfaToken"];
    },
    requireInfo(): boolean {
      return this.$route.query.requireInfo == "1";
    }
  },
  mounted() {
    this.$store.commit("session/setSignupPending", false);
    this.$data.email = this.$route.query.email;
    if (!this.requireInfo) {
      this.onConfirmNoInfo();
    }
  },
  methods: {
    async onConfirmNoInfo(): Promise<void> {
      if (this.$route.query.email && this.$route.query.token) {
        await this.$store.dispatch("session/inviteSignUp", {
          email: this.$route.query.email,
          metadata: {
            email: this.email,
            token: this.$route.query.token as string
          }
        });
      } else {
        this.$store.commit("session/setSignupError", true);
      }
    },
    async onConfirmFull(): Promise<void> {
      if (this.$route.query.email && this.$route.query.token) {
        await this.$store.dispatch("session/inviteSignUp", {
          email: this.email,
          metadata: {
            firstName: this.firstname,
            lastName: this.lastname,
            email: this.email,
            username: this.email,
            password: this.pass,
            token: this.$route.query.token as string
          }
        });
      } else {
        this.$store.commit("session/setSignupError", true);
      }
    }
  }
});

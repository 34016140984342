var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("auth-content", [
    _c(
      "div",
      { staticClass: "px-6 py-8 text-center" },
      [
        !_vm.token
          ? _c(
              "div",
              [
                _c("h2", [_vm._v(_vm._s(_vm.$t("confirm.invitationTitle")))]),
                _c("va-loader", {
                  staticClass: "mb-4",
                  attrs: {
                    isLoading: _vm.pending && !_vm.error,
                    color: "primary"
                  }
                }),
                _vm.error
                  ? _c("va-log-message", { attrs: { type: "error" } }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("confirm.mailConfirmError")) + " "
                      )
                    ])
                  : _vm._e(),
                _vm.$route.query.requireInfo
                  ? _c(
                      "div",
                      [
                        _c("div", { staticClass: "mt-2 mb-8" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("confirm.description")) +
                              ": " +
                              _vm._s(_vm.$route.query.email) +
                              " "
                          )
                        ]),
                        _c(
                          "v-form",
                          {
                            ref: "confirmForm",
                            attrs: {
                              "lazy-validation": "",
                              onSubmit: "return false;"
                            },
                            on: { submit: _vm.onConfirmFull },
                            model: {
                              value: _vm.valid,
                              callback: function($$v) {
                                _vm.valid = $$v
                              },
                              expression: "valid"
                            }
                          },
                          [
                            _c("va-text-field", {
                              staticClass: "mb-8",
                              attrs: {
                                rules: [_vm.required],
                                disabled: _vm.pending,
                                name: "firstname",
                                label: _vm.$t("profile.name")
                              },
                              model: {
                                value: _vm.firstname,
                                callback: function($$v) {
                                  _vm.firstname = $$v
                                },
                                expression: "firstname"
                              }
                            }),
                            _c("va-text-field", {
                              staticClass: "mb-8",
                              attrs: {
                                rules: [_vm.required],
                                disabled: _vm.pending,
                                name: "lastname",
                                label: _vm.$t("profile.lastName")
                              },
                              model: {
                                value: _vm.lastname,
                                callback: function($$v) {
                                  _vm.lastname = $$v
                                },
                                expression: "lastname"
                              }
                            }),
                            _c("va-text-field", {
                              staticClass: "mb-8",
                              attrs: {
                                rules: [_vm.required, _vm.validMail],
                                disabled: _vm.pending,
                                name: "email",
                                label: _vm.$t("signin.email")
                              },
                              model: {
                                value: _vm.email,
                                callback: function($$v) {
                                  _vm.email = $$v
                                },
                                expression: "email"
                              }
                            }),
                            _c("va-text-field", {
                              staticClass: "mb-8",
                              attrs: {
                                rules: [_vm.required, _vm.strongPass],
                                disabled: _vm.pending,
                                name: "password",
                                label: _vm.$t("forgotPassword.password"),
                                password: ""
                              },
                              model: {
                                value: _vm.pass,
                                callback: function($$v) {
                                  _vm.pass = $$v
                                },
                                expression: "pass"
                              }
                            }),
                            _c("va-text-field", {
                              staticClass: "mb-1",
                              attrs: {
                                rules: [
                                  _vm.mustMatch(_vm.pass, _vm.repeatPass)
                                ],
                                disabled: _vm.pending,
                                name: "password",
                                label: _vm.$t("forgotPassword.repeatPassword"),
                                password: ""
                              },
                              model: {
                                value: _vm.repeatPass,
                                callback: function($$v) {
                                  _vm.repeatPass = $$v
                                },
                                expression: "repeatPass"
                              }
                            }),
                            _c(
                              "div",
                              { staticClass: "text-right" },
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: "/presignin" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("confirm.backToSignIn")) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex" },
                              [
                                !_vm.success
                                  ? _c("va-button", {
                                      staticClass: "flex-grow-1 mt-6",
                                      attrs: {
                                        disabled: !_vm.valid || _vm.pending,
                                        submit: "",
                                        loading:
                                          _vm.$store.getters[
                                            "session/isLoading"
                                          ],
                                        cta: _vm.$t("confirm.cta")
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _c("mfa-login")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { CanvasFile } from "@/models/wb/canvasFile.interface";
import { Size } from "@/models/wb/size.interface";

//create an SVG image from text
const fontSize = 100;
const padding = 20;

async function generatePreview(file: CanvasFile): Promise<string> {
  const text = file.details?.text || "";
  const size = calcPreviewSize(text);
  //convent different text lines separates by "\n" in ad array of tspan elements
  const lines = text.split("\n");

  const tspan = lines.map((line, i) => {
    //need to fix svg last line padding
    const leftFix = i == lines.length - 1 ? fontSize / 5 : 0;

    //encode special characters to html entities
    const unicodeStr = line.replace(/[\u00A0-\u2666<>&]/g, function(v) {
      return "&#" + v.charCodeAt(0) + ";";
    });

    return `<tspan
      x="${(size.width + padding) / 2 - leftFix}"
      y="${(i + 1) * fontSize + padding / 4}" >
  ${unicodeStr}
</tspan>`;
  });

  const svg = `
<svg width="${size.width}px" height="${size.height}px"
  viewBox="0 0 ${size.width} ${size.height}"
  style="background-color:${file.details?.background}"
  xmlns="http://www.w3.org/2000/svg" encoding="UTF-8">
    <title>text-box</title>
     <text font-family="Arial"
     font-size="${fontSize}px"
     text-anchor="middle"
     fill="${file.details?.foreground}">
     ${tspan.join("")}
    </text>
</svg>`;
  return "data:image/svg+xml;base64," + btoa(svg);
}

export function calcPreviewSize(text: string): Size {
  const rows = text.split("\n");
  return {
    width:
      Math.max(
        ...rows.map(t => {
          return t.length;
        })
      ) *
        (fontSize * 0.8) +
      padding * 2,
    height: rows.length * fontSize + padding
  };
}

export function cleanUrlText(text: string): string {
  if (text.includes("[InternetShortcut]")) {
    //sometimes text contains wrong url format that need to be cleaned
    // [InternetShortcut] URL=https://nl.wikipedia.org
    text = text.split("URL=")[1];
  }
  return text;
}

export async function GetTextElementDataUrl(file: CanvasFile): Promise<string> {
  return await generatePreview(file);
}

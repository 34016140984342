








import Vue from "vue";
import { HTMLInputEvent } from "@/models/htmlInputEvent.interface";
import * as wbFileService from "@/services/wb/wbFileService";
import iconcolorMixin from "@/mixins/iconcolorMixin";
import AppleSignIn from "@/components/organisms/auth/AppleSignIn.vue";
import MicosoftSignIn from "@/components/organisms/auth/MicrosoftSignIn.vue";
import GoogleSignIn from "@/components/organisms/auth/GoogleSignIn.vue";

export default Vue.extend({
  name: "FederateLogin",
  components: { AppleSignIn, MicosoftSignIn, GoogleSignIn },
  mixins: [iconcolorMixin],
  data: () => ({
    openOptions: false,
    backgroundColor: "#FFFFFF"
  }),
  methods: {
    async setBackgroundColor(color: string) {
      this.backgroundColor = color;
      this.openOptions = false;
      this.$store.dispatch("canvas/updateBackgroundColor", {
        color: color,
        sendMessage: true
      });
    },
    onRemoveFile() {
      this.$store.dispatch("canvas/removeBackgroundImage");
    },
    onUploadFile() {
      const element = this.$refs.uploader as HTMLElement;
      element.click();
    },
    async handleUpload(event: HTMLInputEvent) {
      if (event?.target?.files?.length) {
        this.$store.commit("canvas/setBackgroundLoading", true);
        this.openOptions = false;
        const file = event.target.files[0];
        const fileId = await wbFileService.uploadBackgroundFile(file);
        this.$store.dispatch("canvas/updateBackgroundImage", {
          imageId: fileId,
          sendMessage: true
        });
        if (this.$refs.uploader) {
          (this.$refs.uploader as HTMLInputElement).value = "";
        }
      }
    }
  },
  computed: {
    appleEnabled() {
      return (
        !process.env.VUE_APP_OAUTH_APPLE_DISABLED ||
        process.env.VUE_APP_OAUTH_APPLE_DISABLED === "false"
      );
    },
    googleEnabled() {
      return (
        !process.env.VUE_APP_OAUTH_GOOGLE_DISABLED ||
        process.env.VUE_APP_OAUTH_GOOGLE_DISABLED === "false"
      );
    },
    msEnabled() {
      return (
        !process.env.VUE_APP_OAUTH_MS_DISABLED ||
        process.env.VUE_APP_OAUTH_MS_DISABLED === "false"
      );
    }
  }
});

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "center-content",
    { attrs: { "light-bg": _vm.bgLight, "dark-bg": _vm.bgDark } },
    [
      _c("div", { staticClass: "pa-6" }, [
        _c("div", { staticClass: "d-flex justify-space-between" }, [
          _c("div", { staticClass: "text-left" }, [
            _c(
              "a",
              {
                staticClass: "error--text font-weight-bold text-uppercase",
                on: { click: _vm.onLogout }
              },
              [_vm._v(" " + _vm._s(_vm.$t("home.logout")) + " ")]
            )
          ]),
          _c(
            "div",
            {
              staticClass: "cursor-pointer",
              on: {
                click: function($event) {
                  return _vm.$router.push("/profile")
                }
              }
            },
            [_c("va-icon", { attrs: { icon: "va va-user" } })],
            1
          )
        ]),
        _vm.user
          ? _c(
              "div",
              { staticClass: "text-center  mt-n12 mb-8" },
              [
                _c("va-avatar", {
                  staticClass: "mt-n10 elevation-3",
                  attrs: {
                    image: _vm.user.avatar,
                    text: _vm.user.firstName + " " + _vm.user.lastName,
                    size: "l"
                  }
                }),
                _c("h2", { staticClass: "my-6" }, [
                  _vm._v(
                    _vm._s(_vm.user.firstName) + " " + _vm._s(_vm.user.lastName)
                  )
                ]),
                _c("va-chip", {
                  attrs: {
                    text: _vm.user.subscription.description,
                    color: _vm.subscriptionColor
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass:
              "border-ra border-a background-dark d-flex pa-4 mb-6 cursor-pointer",
            on: {
              click: function($event) {
                return _vm.$router.push("/room")
              }
            }
          },
          [
            _c("div", { staticClass: "flex-grow-1" }, [
              _c("div", { staticClass: "font-weight-bold" }, [
                _vm._v(_vm._s(_vm.$t("home.myRooms")))
              ]),
              _vm.rooms.length
                ? _c("div", { staticClass: "font-size-s" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.rooms.length) +
                        " " +
                        _vm._s(_vm.$t("home.rooms")) +
                        " "
                    )
                  ])
                : _vm._e()
            ]),
            _c("va-icon", { attrs: { icon: "va va-direction-right" } })
          ],
          1
        ),
        _vm.guestEnabled
          ? _c(
              "div",
              {
                staticClass:
                  "border-ra border-a background-dark d-flex cursor-pointer pa-4",
                on: {
                  click: function($event) {
                    return _vm.$router.push("/join")
                  }
                }
              },
              [
                _c("div", { staticClass: "flex-grow-1" }, [
                  _c("h3", { staticClass: "font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.$t("join.title")))
                  ])
                ]),
                _c("va-icon", { attrs: { icon: "va va-direction-right" } })
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
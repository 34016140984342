import "@/plugins/vueCookies";
import axios from "axios";
import * as configService from "@/services/configService";
import { User, UserDeserializer } from "@/models/user.interface";

export async function findUsers(query: string, max = 100): Promise<User[]> {
  const resp = await axios.get(
    `${configService.getFindUsersUrl()}?filter=${query}&maxUsers=${max}`
  );
  return resp.data.map((rawUser: unknown) => {
    return UserDeserializer(rawUser);
  });
}

export async function updateUser(user: User): Promise<User> {
  const data = {
    UserId: user.userId,
    Email: user.email,
    VisibleForSearch: user.visibleForSearch,
    FirstName: user.firstName,
    LastName: user.lastName,
    City: user.city
  };
  await axios.post(configService.setUserProfile(), data);
  return user;
}

export async function uploadAvatar(data: File): Promise<string> {
  if (data.type == "image/jpeg") {
    //change ".jpeg" files in ".jpg"
    const blob = data.slice(0, data.size, "image/jpeg");
    data = new File([blob], "avatar.jpg", { type: "image/jpeg" });
  }
  const formData = new FormData();
  formData.append("attachment", data);

  const resp = await axios.post(configService.setUserAvatar(), formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return resp.data;
}

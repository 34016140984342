




























import Vue from "vue";
import VaAvatar from "@/components/atoms/VaAvatar.vue";

export default Vue.extend({
  components: {
    VaAvatar
  },
  props: {
    sender: {
      type: String,
      required: true
    },
    date: {
      type: Date,
      required: false
    },
    image: {
      type: String,
      required: false
    },
    isOwner: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    formattedTime(): string {
      let date = new Date(this.date);
      return date
        .toLocaleTimeString()
        .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
    }
  }
});

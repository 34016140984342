










import Vue from "vue";
import Notifications from "@/components/organisms/Notifications.vue";
import ConfirmDialog from "@/components/organisms/ConfirmDialog.vue";
import MetaHelper from "@/services/metaHelper";

export default Vue.extend({
  name: "MagoApp",
  components: {
    Notifications,
    ConfirmDialog
  },
  meta() {
    return MetaHelper.buildMeta();
  }
});

export interface AuthData {
  expires: Date;
  refreshExpires: Date;
  token: string;
  refreshToken: string;
  legacyServerUrl: string;
  chatServerUrl: string;
}

export interface ResetPasswordData {
  token: string;
  password: string;
  email: string;
}

export interface SignUpData {
  email: string;
  metadata: {
    firstName?: string;
    lastName?: string;
    email?: string;
    username?: string;
    token?: string;
  };
}

export interface LegacyConfirmData {
  token: string;
  password: string;
  email: string;
  firstname: string;
  lastname: string;
  nickname: string;
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function AuthDataDeserializer(input: any): AuthData {
  let chatServerUrl = input.MeetingServerUrl || "";
  //clean trailing slashes
  if (chatServerUrl.endsWith("/")) chatServerUrl = chatServerUrl.slice(0, -1);
  return {
    expires: new Date(input.expirationUtc || ""),
    refreshExpires: new Date(input.refreshTokenExpirationUtc || ""),
    token: input.token || "",
    refreshToken: input.refreshToken || "",
    legacyServerUrl: input.legacyServerUrl || "",
    chatServerUrl: chatServerUrl
  };
}

import Vue from "vue";
import tenantService from "@/services/tenantService";

export default Vue.extend({
  computed: {
    bgLight() {
      return tenantService.requireBackground("room-waiting.jpg");
    },
    bgDark() {
      return tenantService.requireBackground("room-waiting-dark.jpg");
    }
  }
});

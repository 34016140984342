var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex justify-center align-center mb-4" },
    [
      _vm.appleEnabled ? _c("apple-sign-in") : _vm._e(),
      _vm.googleEnabled ? _c("google-sign-in") : _vm._e(),
      _vm.msEnabled ? _c("micosoft-sign-in") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }











































































































import Vue from "vue";
import VaButton from "@/components/atoms/VaButton.vue";
import iconcolorMixin from "@/mixins/iconcolorMixin";
import { Poll } from "@/models/poll/poll.interface";
import VaTextArea from "@/components/atoms/VaTextArea.vue";

export default Vue.extend({
  components: {
    VaTextArea,
    VaButton
  },
  mixins: [iconcolorMixin],
  data: () => ({
    displayPoll: false,
    stepCounter: 1
  }),
  computed: {
    isActivePoll(): boolean {
      return this.$store.getters["wb/polls/activePoll"] !== undefined;
    },
    poll(): Poll {
      return this.$store.getters["wb/polls/activePoll"];
    }
  },
  methods: {
    onPrev() {
      this.stepCounter = this.stepCounter - 1;
    },
    onNext() {
      this.$store.dispatch(
        "wb/polls/sendAnswer",
        this.poll.items[this.stepCounter - 1]
      );
      this.stepCounter = this.stepCounter + 1;
    },
    onFinish() {
      this.$store.dispatch(
        "wb/polls/sendAnswer",
        this.poll.items[this.stepCounter - 1]
      );
      this.$store.commit("wb/polls/setActivePoll", undefined);
      this.stepCounter = 1;
      this.displayPoll = false;
    }
  }
});

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.square
    ? _c(
        "v-icon",
        {
          attrs: {
            color: _vm.color,
            large: _vm.size === "l",
            "x-large": _vm.size === "xl",
            small: _vm.size === "s",
            "x-small": _vm.size === "xs"
          }
        },
        [_vm._v(" " + _vm._s(_vm.icon) + " ")]
      )
    : _c(
        "div",
        { class: ["icon-square", _vm.colorClass, _vm.sizeClass] },
        [
          _c(
            "v-icon",
            {
              attrs: {
                color: _vm.computedColor,
                large: _vm.size === "l",
                "x-large": _vm.size === "xl",
                small: _vm.size === "s",
                "x-small": _vm.size === "xs"
              }
            },
            [_vm._v(" " + _vm._s(_vm.icon) + " ")]
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
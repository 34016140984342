var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "rounded text-center font-size-s py-0 cursor-pointer d-flex align-center"
    },
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    _vm._g({}, on),
                    [
                      _c("va-button", {
                        attrs: {
                          icon: "va va-zoom-in",
                          color: _vm.iconColor,
                          type: "squareText"
                        },
                        on: {
                          click: function($event) {
                            return _vm.$store.commit("canvas/zoomIn")
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("rooms.zoomIn")))])]
      ),
      _c(
        "div",
        {
          on: {
            click: function($event) {
              return _vm.$store.commit("canvas/resetZoom")
            }
          }
        },
        [
          _vm._v(
            " " + _vm._s(_vm.$store.getters["canvas/zoomPercentage"]) + "% "
          )
        ]
      ),
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    _vm._g({}, on),
                    [
                      _c("va-button", {
                        attrs: {
                          icon: "va va-zoom-out",
                          color: _vm.iconColor,
                          type: "squareText"
                        },
                        on: {
                          click: function($event) {
                            return _vm.$store.commit("canvas/zoomOut")
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("rooms.zoomOut")))])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "federated-button rounded border-a overflow-hidden google-btn"
    },
    [_c("div", { ref: "signinBtn", staticClass: "ma-n1" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
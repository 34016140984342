



























import Vue from "vue";
import { availableHexcolors } from "@/services/configService";
import VaButton from "@/components/atoms/VaButton.vue";
import iconcolorMixin from "@/mixins/iconcolorMixin";

export default Vue.extend({
  name: "HexColorSelector",
  components: { VaButton },
  props: {
    text: {
      type: String,
      required: false,
      default: "Test"
    },
    icon: {
      type: String,
      required: false,
      default: ""
    },
    buttonClass: {
      type: String,
      required: false,
      default: ""
    },
    color: {
      type: String,
      required: false,
      default: ""
    },
    menuClass: {
      type: String,
      required: false,
      default: ""
    }
  },
  data: () => ({
    open: false,
    availableColors: availableHexcolors
  }),
  mixins: [iconcolorMixin],
  computed: {
    // buttonColor() {
    //   return this.color ? this.color : this.iconColor;
    // },
    btnClass() {
      return this.buttonClass;
    }
  },
  methods: {
    onSelectColor(selectedColor: string) {
      this.$emit("selectColor", selectedColor);
    }
  }
});
